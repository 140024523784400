import BaseModel from './BaseModel';

export default class StockModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.total = data.total;
    this.committedStock = data.committedStock;
    this.securityStock = data.securityStock;
    this.openSale = data.openSale;
  }
}
