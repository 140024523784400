import BaseModel from '../BaseModel';

class DiscountModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.ordering = data.ordering;
    this.percentage = data.percentage;
  }
}

export default DiscountModel;
