import BaseAPI, { URLS } from './BaseAPI';

class KitAPI {
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * Request que lista Kits
   * @param {Object} params São os parametros da busca, por exemplo pagina e items por página;
   */
  static async list(params = {}) {
    try {
      const response = await BaseAPI.get(URLS.KIT, params);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar kits' };
    } catch (e) {
      return this._exception(e, 'Falha ao listar kits');
    }
  }

  /**
   * Request que lista Kits pelo nome
   * @param {Object} params São os parametros da busca, por exemplo pagina e items por página;
   */
  static async listByName(params = {}) {
    try {
      const response = await BaseAPI.get(`${URLS.KIT}`, params);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar kits' };
    } catch (e) {
      return this._exception(e, 'Falha ao listar kits');
    }
  }

  /**
   * Request que cria novo kit.
   * @param  {Object} kit - Objeto do kit que será criado
   */
  static async save(kit) {
    try {
      const response = await BaseAPI.post(URLS.KIT, kit);
      if (response.status === 201) return response;
      return { error: 'Erro inesperado ao cadastrar o kit' };
    } catch (e) {
      return this._exception(e, 'Falha ao cadastrar o kit');
    }
  }

  /**
   * Atualizada campos de um kit.
   * @param  {Object} params
   */
  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(URLS.KIT, data);
      if (response.status === 204) return response;
      return { error: 'Erro inesperado ao atualizar o kit' };
    } catch (e) {
      return this._exception(e, 'Erro ao atualizar o kit');
    }
  }

  /**
   * Request que busca um kit pelo uuid
   * @param {String} kitUuid uuid do kit
   */
  static async findByUUID(kitUuid) {
    try {
      const response = await BaseAPI.get(`${URLS.KIT}/uuid/${kitUuid}`);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao busca o kit' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar o kit');
    }
  }

  // Deletar produtos no kit
  static async handleRemoveProductKit(uuidKit, uuidProduct) {
    try {
      const url = `/manager/products/kit/uuid/${uuidKit}/product/${uuidProduct}`;
      const response = await BaseAPI.delete(url);
      if (response.status === 200) return response.data;
      return { error: 'Erro ao remover produto do kit' };
    } catch (e) {
      return this._exception(e, 'Erro ao remover produto no kit.');
    }
  }

  // Deletar produto alternativo dentro do kit
  static async handleRemoveProductAlternativeKit(uuidProductKit, uuidAlternativeProduct) {
    try {
      const url = `/manager/products/product-kit/uuid/${uuidProductKit}/product-alternative/${uuidAlternativeProduct}`;
      const response = await BaseAPI.delete(url);
      if (response.status === 204) return response.data;
      return { error: 'Erro ao tentar remover produto alternativo do kit' };
    } catch (e) {
      return this._exception(e, 'Erro ao tentar remover produto alternativo do kit.');
    }
  }

  /**
   * Request que lista o preço do kit de acordo com os produtos
   * @param {Object} params São os parametros da busca, por exemplo pagina e items por página;
   */
  static async calculateProductsPriceInKit(params = {}) {
    try {
      const response = await BaseAPI.get(`${URLS.PRODUCTS}/kit/price`, params);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar preço do kit' };
    } catch (e) {
      return this._exception(e, 'Falha ao listar preço do kit');
    }
  }

  /**
   * Desativa um kit.
   * @param  {Object} params
   */
  static async disableKit(uuid) {
    try {
      const response = await BaseAPI.put(`/manager/products/kit/uuid/${uuid}/disable`);
      if (response.status === 204) return response;
      return { error: 'Erro inesperado ao desativar o kit' };
    } catch (e) {
      return this._exception(e, 'Erro ao desativar o kit');
    }
  }

  // Deletar desconto no kit
  static async handleRemoveDiscountKit(uuidKit, uuidDiscount) {
    try {
      const url = `/manager/products/kit/uuid/${uuidKit}/discount/${uuidDiscount}`;
      const response = await BaseAPI.delete(url);
      if (response.status === 204) return response.data;
      return { error: 'Erro ao remover disconto' };
    } catch (e) {
      return this._exception(e, 'Erro ao remover disconto no kit.');
    }
  }

  /**
   * Adiciona categoria(s) a um kit.
   * @param  {string} kitUUID - identificador do kit
   * @param  {array} data - array que contém o uuid das categories
   */
   static async addKitCategories(kitUUID, data) {
    try {
      const response = await BaseAPI.put(`${URLS.KIT}/${kitUUID}/sportbayCategories`, data);
      if (response.status === 200) return response;
      return { error: 'Erro inesperado ao adicionar as categorias no kit' };
    } catch (e) {
      return this._exception(e, 'Erro inesperado ao adicionar as categorias no kit');
    }
  }

  /**
   * Request que exclui kits de uma categoria.
   * @param  {Object} uuid - Uuid da categoria
   * @param  {Array} kits - Objeto kit que será invulado.
   */
   static async removeKitsCategory(uuid, kits) {
    try {
      const url = `${URLS.CATEGORIES}/${uuid}/products/kit`;
      const arrayKits = Array.isArray(kits) ? kits : [kits];
      const response = await BaseAPI.delete(url, arrayKits);
      if (response.status === 200) return response;
      return { error: `Falha ao desvincular categoria e kit` }
    } catch (e) {
      return this._exception(e, 'Falha ao desvincular categoria e kit')
    }
  }

  /** Retorna categories de um kit  */
  /**
   * @param  {string} uuid - Uuid do kit que quer as categorias
   */
   static async getCategories(kitUuid) {
    try {
      const url = `${URLS.KIT}/${kitUuid}/categories`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
      return {
        error: `${response.status === 404
          ? 'Kit não encontrado'
          : 'Kit não está em nenhuma categoria'
        }`,
      };
    } catch (e) {
      return this._exception(e, 'Kit sem categorias');
    }
  }
}

export default KitAPI;
