import PropertyClassModel from '../PropertyClassModel';

class LuminiProductPropertyCustomModel {
  constructor(data = {}) {
    this.propertyClass = new PropertyClassModel(data.propertyClass);
    this.displayName = data.displayName;
    this.active = data.active;
    this.created = data.created;
    this.deleted = data.deleted;
    this.modified = data.modified;
    this.uuid = data.uuid;
  }
}

export default LuminiProductPropertyCustomModel;
