import BaseModel from './BaseModel';

class CustomerTypeModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.name = data.name;
  }
}

export default CustomerTypeModel;
