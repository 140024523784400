import { extendObservable } from 'mobx';
import BaseModel from './BaseModel';

class FileModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this._name = data.name;
    this._size = data.size;
    this._preview = data.preview;
    this.path = data.path;
    this.metaTags = data.metaTags;
    this.file = data.file;
    this.localModified = data.localModified || false;
    // this.alias = data.alias;
    // this.associatedVariations = data.associatedVariations || [];
    // this['@class'] = 'br.com.stoom.sportbay.model.SportbayFile';
    extendObservable(this, {
      percentCompleted: 0,
    });
  }

  /**Atualiza valores do model de acordo com o file */
  updateFile(file) {
    this.localModified = true;
    this.file = file;
    this.path = file && file.path;
    this._name = file && file.name;
    this._size = file && file.size;
    this._preview = file && file.preview;
    // this.alias = file.alias;
    // this['@class'] = file.class;
    // this.associatedVariations = file && file.associatedVariations;
  }

  get preview() {
    return this._preview ? this._preview : this.path;
  }

  get name() {
    return this._name || (this.file ? this.file.name : '');
  }

  get size() {
    return this._size || (this.file ? this.file.size : '');
  }

  getFormData() {
    const metaData = JSON.stringify({ metaTags: this.metaTags });
    const form = new FormData();
    form.append('file', this.file);
    if (!this.uuid)
      form.append(
        'metadata',
        new Blob([metaData], { type: 'application/json' }),
        this.name
      );
    return form;
  }
}

export default FileModel;
