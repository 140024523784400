import BaseAPI, { URLS } from './BaseAPI';

class ComboAPI {
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  static async getPartProduct(query) {
    try {
      const response = await BaseAPI.get(
        `${URLS.PRODUCTS}/parts?name=${query}`
      );
      if (response.status === 200) return response.data;
      return { error: 'Erro ao buscar produto para o combo' };
    } catch (e) {
      return this._exception(e, 'Falha ao produto para o combo');
    }
  }
}

export default ComboAPI;
