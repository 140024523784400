import { extendObservable } from 'mobx';
import BaseModel from '../BaseModel';
import FileModel from '../FileModel';
import PromotionTypeEnum from './enums/PromotionTypeEnum';
import SportbayCategoryModel from './SportbayCategoryModel';
import SportbayProductModel from './SportbayProductModel';
import MerchantModel from "~/models/MerchantModel";

class CouponModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.merchant = new MerchantModel(data.merchant);
    this.promotionType = data.promotionType;
    this['@class'] = 'br.com.stoom.sportbay.model.SportbayPromotion';
    this.infiniteCampaign = data.infiniteCampaign;
    this.comment = data.comment;
    this.cpf = data.cpf;
    this.couponFromOther = data.couponFromOther;
    this.startValidity = data.startValidity;
    this.absoluteDiscount = data.absoluteDiscount;
    this.specialDiscount = data.specialDiscount;
    this.percentualDiscount = data.percentualDiscount;
    this.description = data.description;
    this.exclusiveApp = data.exclusiveApp;
    this.exclusiveSubscribers = data.exclusiveSubscribers;
    this.freeShipping = data.freeShipping;
    this.freeshippingExpress = data.freeshippingExpress;
    this.gift = data.gift;
    this.category = data.category && new SportbayCategoryModel(data.category);
    this.identifier = data.identifier;
    this.image = data.image ? new FileModel(data.image) : undefined;
    this.image2 = data.image2 ? new FileModel(data.image2) : undefined;
    this.isPublic = data.isPublic;
    this.isSingleUse = data.isSingleUse

    this.linkedProduct =
      data.linkedProduct && new SportbayProductModel(data.linkedProduct);
    this.information = data.information;
    this.information2 = data.information2;
    this.information3 = data.information3;
    this.information4 = data.information4;
    this.information5 = data.information5;
    this.information6 = data.information6;
    this.information7 = data.information7;
    this.hiddenInformation = data.hiddenInformation;
    this.investment = data.investment;
    this.minimumApplication = data.minimumApplication;
    this.minimumGift = data.minimumGift;

    this.multipleGifts = data.multipleGifts;
    this.name = data.name;
    this.endNotification = data.endNotification;
    this.originOrderNumber = data.originOrderNumber;
    this.resendOrderNumber = data.resendOrderNumber;
    this.note = data.note;
    this.exclusiveOffer = data.exclusiveOffer;
    this.orderNumber = data.orderNumber;
    this.firstPurchase = data.firstPurchase;
    this.timePromotion = data.timePromotion;
    this.quantityCoupons = data.quantityCoupons;
    this.maximumAmount = data.maximumAmount;
    this.remainingUse = data.remainingUse;
    this.used = data.used;
    this.inUse = data.inUse;
    this.toUse = data.toUse;

    this.type = data.type;
    this.paymentType = data.paymentType;
    this.registrationValidity = data.registrationValidity;
    this.finalZipCode = data.finalZipCode;
    this.initialZipCode = data.initialZipCode;

    this.startValidityTime = data.startValidityTime;
    this.endValidityTime = data.endValidityTime;

    this.participatingMerchants = data.participatingMerchants;
    this.seller = data.seller;
    extendObservable(this, {
      endValidity: data.endValidity,
      model: data.model,
      products: data.products,
      isAllProducts: data.isAllProducts,
      active: data.active ?? false,
    });
  }
}

export default CouponModel;
