import BaseModel from '../BaseModel';

export default class SportbayOrderStatusModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.statusName = data.statusName;
    this['@class'] = data['@class'];
  }

  /**Retorna description de acordo com o statusName(definido no back) */
  get description() {
    return Object.freeze({
      ANALYSIS: 'Processando Pagamento', // em analise
      CART: 'Carrinho', // CARRINHO
      SHIPPING_ANALYSIS: 'Em análise (Frete)', //analise eurobreak 2
      STOCK_SEPARATION: 'Não foi possível enviar ao ERP',
      CHECKOUT: 'Orçamento com cálculo de frete liberado', //checkoout
      NEW: 'Novo', //NOVO
      PAID: 'Pagamento Confirmado', //PAGO
      RETURNED: 'Pedido devolvido', //PAGO
      CHARGEBACK: 'Chargeback', //PAGO
      BILLED: 'Pedido Faturado', // FATURADO
      SEPARATED: 'Separado', // SEPARADO
      SENT: 'Pedido em Trânsito', //ENVIADO
      DELIVERED: 'Pedido Entregue',
      PARTIALLY_RETURNED: 'Estorno parcial',
      CANCELED: 'Cancelado', //CANCELADO
      EXTERNAL_ID: 'Número ERP', //Numero ERP
      ID_ORDER: 'Número do pedido pai', // Numero pedidp pai
      ID_ORDER_MERCHANT: 'Número do pedido', //Numero pedido
      ID_PRE_ORDER: 'Orçamento', //Orcamento
      EXTRAVIO: 'Extraviado'
    })[this.statusName];
  }

  // Todo pedido que tiver no status acima do NEW
  get isNew() {
    switch (this.statusName) {
      case ORDER_STATUS.ANALYSIS: return false;
      case ORDER_STATUS.CART: return false;
      case ORDER_STATUS.SHIPPING_ANALYSIS: return false;
      case ORDER_STATUS.CHECKOUT: return false;
      case ORDER_STATUS.NEW: return true;
      case ORDER_STATUS.PAID: return true;
      case ORDER_STATUS.BILLED: return true;
      case ORDER_STATUS.SEPARATED: return true;
      case ORDER_STATUS.SENT: return true;
      case ORDER_STATUS.CANCELED: return true;
      case ORDER_STATUS.DELIVERED: return true;
      case ORDER_STATUS.EXTERNAL_ID: return true;
      case ORDER_STATUS.ID_ORDER: return true;
      case ORDER_STATUS.ID_ORDER_MERCHANT: return true;
      case ORDER_STATUS.ID_PRE_ORDER: return true;
      default:
        return false;
    }
  }
}

/**Status de pedidos */
export const ORDER_STATUS = Object.freeze({
  ANALYSIS: 'ANALYSIS', // em analise
  CART: 'CART', // CARRINHO
  SHIPPING_ANALYSIS: 'SHIPPING_ANALYSIS', //analise eurobreak 2
  CHECKOUT: 'CHECKOUT', //checkoout
  NEW: 'NEW', //NOVO
  PAID: 'PAID', //PAGO
  CHARGEBACK: 'CHARGEBACK', //
  RETURNED: 'RETURNED', //DEVOLVIDO
  SEPARATED: 'SEPARATED', // SEPARADO
  BILLED: 'BILLED', // FATURADO
  SENT: 'SENT', //ENVIADO
  DELIVERED: 'DELIVERED',
  PARTIALLY_RETURNED: 'PARTIALLY_RETURNED',
  CANCELED: 'CANCELED', //CANCELADO
  EXTERNAL_ID: 'externalId', //Numero ERP
  ID_ORDER: 'order_idOrder', //Numero pedido Pai
  ID_ORDER_MERCHANT: 'idOrderMerchant', //Numero pedido
  ID_PRE_ORDER: 'ID_PRE_ORDER', //Orcamento
  EXTRAVIO: 'EXTRAVIO'
});
