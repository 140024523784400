import { extendObservable, decorate, action } from "mobx";

import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import ComboAPI from '../services/ComboAPI';
import { sortListBy } from '~/helpers/utils/Functions';

const initValues = {
  loading: false,
  property: undefined,
  clazz: undefined,
  group: undefined,
  itemsCombo: [],
  optionsPartProduct: [],
}

class ComboStore {
  totalPages = 0;
  page = 0;
  size = 20;
  loading = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, { ...initValues });
    this.toastHelper = new ToastHelper();
  }

  /**Pesquisa Parte/Produto utilizando query */
  async searchPartProduct(query) {
    this.loading = true;
    const response = await ComboAPI.getPartProduct(query);

    if(response.error) this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);

    this.loading = false;
    this.searchProductsResponse = response;
    return response;
  }

  /**Transforma array em opções para o async select */
  transformAsyncSelectOptions(data) {
    if (data.length > 0) {
      const optionsPartProduct = [];
      data.map((data) => {
        optionsPartProduct.push({
          label: data.name,
          value: data.uuid,
        })
      })
      this.optionsPartProduct = sortListBy(optionsPartProduct, 'label');
    } else {
      this.optionsPartProduct = data;
    }
  }
}



export default ComboStore;
