import BaseAPI, { URLS } from "./BaseAPI";

class ApplicationAPI {

    /**Método util para retorno de erro */
    static _exception(e, message) {
        console.log(e);
        return { error: message }
    }

    /**
     * @param  {string} uuid - Uuid da aplicação à ser procurada
     */
    static async get(uuid) {
        try {
            const response = await BaseAPI.get(`${URLS.APPLICATION}/${uuid}`);
            if (response.status === 200) return response.data;
            const error = response.status === 404 ? "Aplicação não encontrada" : "Erro inexperado ao buscar Aplicação";
            return { error }
        } catch (e) {
            return this._exception(e, 'Erro ao buscar aplicação');
        }
    }



    /**
     * @param  {string} uuid - Uuid da aplicação à ser deletada
     */
    static async delete(uuid) {
        try {
            const response = await BaseAPI.delete(`${URLS.APPLICATION}/${uuid}`);
            if (response.status === 200) return response.data;
            const error = response.status === 404 ? "Aplicação não encontrada" : "Erro inexperado ao buscar Aplicação";
            return { error }
        } catch (e) {
            return this._exception(e, 'Erro ao deletar Aplicação');
        }
    }

    /**
     * @param  {string} uuid - Uuid da aplicação à ser atualizada
     */
    static async update(uuid, data) {
        try {
            const response = await BaseAPI.put(`${URLS.APPLICATION}/${uuid}`, data);
            if (response.status === 200) return response.data;
            const error = response.status === 404 ? "Aplicação não encontrada" : "Erro inexperado ao atualizar Aplicação";
            return { error }
        } catch (e) {
            return this._exception(e, 'Erro ao atualizar dados da indústria');
        }
    }

    /**
     * @param  {object} data - dados do novo aplicação que será salvo
     */
    static async save(data) {
        try {
            const response = await BaseAPI.post(URLS.APPLICATION, data);
            if (response.status === 201) return response;
            return { error: 'Erro inexperado ao cadastrar aplicação' }
        } catch (e) {
            return this._exception(e, 'Falha ao cadastrar aplicação');
        }
    }

    /**Busca lista de applications */
    static async getList(params) {
        try {
            const response = await BaseAPI.get(URLS.APPLICATION, params);
            if (response.status === 200) return response.data;
            return { error: 'Erro inexperado ao buscar lista de aplicação' }
        } catch(e) {
            return this._exception(e, "Falha ao buscar lista de aplicação");
        }
    }

}
export default ApplicationAPI;
