import React from 'react';

const MainContainer = (props) => {
    const { children, role = "main" } = props;
    return (
        <main role={role}>
            {children}
        </main>)
};

export default MainContainer;