import BaseModel from './BaseModel';
import ContentDataModel from './ComponentContentModel';
import FaqModel from './FaqModel';


class ComponentLPModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.ordering = data.ordering;
    this.tag = data.tag;
    this.title = data.title;
    this.description = data.description;
    this.content = data.content ? data.content : [];
    this.faq = data.faq ? data.faq.map((faq) => new FaqModel(faq)) : [];
  }
}

export default ComponentLPModel;