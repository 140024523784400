import BaseAPI, { URLS } from './BaseAPI';

class PropertiesAPI {
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * Cria nova propriedade
   * @param  {String} classUuid - uuid da propriedade;
   * @param  {String} groupUuid - uuid do grupo;
   * @param  {Object} value - nome da variação
   */
  static async createProperty(classUuid, groupUuid, property) {
    try {
      const url = `${URLS.CLASSES}/${classUuid}/groups/${groupUuid}/values`;
      const response = await BaseAPI.post(url, property);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao cadastrar propriedade' };
    } catch (e) {
      return this._exception(e, 'Falha ao cadastrar classe de produto');
    }
  }

  /** Adiciona/Atualiza a opção ao atributo */
  static async saveAttribute(attribute) {
    try {
      const response = await BaseAPI.post(URLS.PROPERTY, attribute);
      if (response.status === 201) return response;
      return { error: 'Erro inesperado ao atualizar o atributo' };
    } catch (e) {
      return this._exception(e, 'Falha ao atualizar o atributo');
    }
  }

  /**
   * Cria nova propriedade
   * @param  {String} classUuid - uuid da propriedade;
   * @param  {Object} params - parametros que serão atualizados;
   * @path  manager/properties/classes/{uuid}
   */
  static async updatePropertyClass(classUuid, params) {
    try {
      const url = `${URLS.CLASSES}/${classUuid}`;
      const response = await BaseAPI.put(url, params);
      if (response.status === 200) return response.data;
      return { error: 'Erro ao gravar informações.' };
    } catch (e) {
      return this._exception(e, 'Falha ao atualizar dados da propriedade!');
    }
  }

  /**
   * Request classe de propriedade.
   * @param  {Object} propertyClass -
   */
  static async createClassProperty(propertyClass) {
    try {
      const response = await BaseAPI.post(URLS.CLASSES, propertyClass);
      if (response.status === 201) return response.data;
      return { error: 'Erro inesperado ao cadastrar o atributo' };
    } catch (e) {
      return this._exception(e, 'Falha ao cadastrar o atributo');
    }
  }

  /**
   * @description Request que busca propertyClass
   * @param {stirng} uuid valor que deseja buscar
   */
  static async getPropertyClass(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.CLASSES}/${uuid}`);
      if (response.status === 200) return response.data;
      return { error: 'Erro ao buscar propriedade' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar Propriedade');
    }
  }

  /**
   * @description Request para delete de group
   * @param {string} classUuid valor que deseja buscar
   * * @param {string} groupUuid valor que deseja buscar
   * @path manager/property/classes/{uuid}
   */
  static async deleteClass(classUuid) {
    try {
      const response = await BaseAPI.delete(
        `manager/property/classes/${classUuid}`
      );
      if (response.status === 204) return response.data;
      return { error: 'Erro ao deletar o atributo' };
    } catch (e) {
      return this._exception(e, 'Falha ao deletar o atributo');
    }
  }

  /**
   * @description Request para delete de group
   * @param {string} classUuid valor que deseja buscar
   * * @param {string} groupUuid valor que deseja buscar
   * @path /property/{uuid}
   */
  static async deleteProperty(propertyUuid) {
    try {
      const response = await BaseAPI.delete(`manager/property/${propertyUuid}`);
      if (response.status === 204) return response.data;
      return { error: 'Erro ao deletar variação' };
    } catch (e) {
      return this._exception(e, 'Falha ao deletar variação');
    }
  }

  /**
   * Request que busca todas as propriedades
   * @param {Object} params São os parametros da busca, por exemplo pagina e items por página;
   * @path "/properties/classes/{uuid}/groups/{group_uuid}/values
   */
  static async getPropertyValues(classUuid, groupUuid) {
    try {
      const response = await BaseAPI.get(
        `${URLS.CLASSES}/${classUuid}/groups/${groupUuid}/values`
      );
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar lista de variações' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar lista de variações');
    }
  }

  /**
   * Request que lista Produtos
   * @param {Object} params São os parametros da busca, por exemplo pagina e items por página;
   */
  static async listClasses(params = {}) {
    try {
      const response = await BaseAPI.get(URLS.CLASSES, params);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar tipos de propriedade' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar tipos de propriedade');
    }
  }

  static async getAllOptions(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.CLASSES}/${uuid}/values`);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar as opções do atributo' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar as opções do atributo');
    }
  }

  /**
 * Request que lista todas os atributos que podem ser selecionados por um produto
 */
  static async listAllClasses() {
    try {
      const response = await BaseAPI.get(`${URLS.CLASSES}/all`);
      if ((response.status = 200)) return response.data;
      return { error: 'Erro inesperado ao buscar os atributos' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar os atributos');
    }
  }

  /**
 * @description Request que busca propertyClasses agrupadas por variações de um produto modelo
 * @param {stirng} uuid identificador do modelo
 */
  static async getAllPropertyClassParent(productParentUuid) {
    try {
      const response = await BaseAPI.get(
        `${URLS.CLASSES}/parent/${productParentUuid}`
      );
      if (response.status === 200) return response.data;
      return { error: 'Erro ao buscar atributos agrupados por variações' };
    } catch (e) {
      return this._exception(
        e,
        'Erro ao buscar atributos agrupados por variações'
      );
    }
  }
}

export default PropertiesAPI;
