import MerchantModel from '../MerchantModel';

const merchantSportbay = 1;

class SportbayMerchantModel extends MerchantModel {
  constructor(data = {}) {
    super(data);
    this['@class'] = 'br.com.stoom.sportbay.model.SportbayMerchant';
    this.idMerchant = data.idMerchant;
    this.uuid = data.uuid;
    this.isSeller = data.idMerchant != merchantSportbay;
    this.faresMdr = data.faresMdr;
    this.faresFee = data.faresFee;
    this.active = data.active || false;
    this.ownDistributionCenter = data.ownDistributionCenter || false;
    this.zipCodeRangeOrCourier = data.zipCodeRangeOrCourier || false;
    this.zipCode = data.zipCode;
    this.street = data.street;
    this.number = data.number;
    this.block = data.block;
    this.city = data.city;
    this.state = data.state;
    this.country = data.country;
    this.courierKey = data.courierKey;
  }
}

export default SportbayMerchantModel;
