import {action, decorate, extendObservable} from 'mobx';
import ToastHelper, {STATUS_HELPER} from '~/helpers/ToastHelper';
import {categoryTypes} from '~/helpers/utils/Selects';
import FileModel from '~/models/FileModel';
import SportbayCategoryModel from '~/models/sportbay/SportbayCategoryModel';
import SportbayCategoryDTOModel from '~/models/sportbay/SportbayCategoryDTOModel';
import SportbayProductModel from '~/models/sportbay/SportbayProductModel';
import CategoryAPI from '../services/CategoryAPI';
import UploadAPI from '../services/UploadAPI';
import CategoriesEnum from '~/models/sportbay/enums/CategoriesEnum';
// import SportbayFeatureModel from '~/models/sportbay/SportbayFeatureModel';

/**Valores inicias de variaveis observadas */
const initValues = {
  category: undefined,
  categories: [],
  showCaseCategories: [],
  collectionCategories: [],
  loading: false,
  features: [],
  featureList: [],
  associate: [],
  categoryUuid: null,
  parentCategories: [],
  parentSelected: [],
  LoadingUpdateElastic: false,
  errorUpdateProducts : '',
  successUpdateProducts:''
};

class CategoryStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, initValues);
    this.toastHelper = new ToastHelper();
  }

  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Reset values */
  reset() {
    this.category = undefined;
    this.categories = [];
  }

  /**Atualiza propriedades da categoria */
  updateProp(prop, value) {
    switch (prop) {
      case 'products':
        const products = value.map((v) => new SportbayProductModel({uuid: v.value, name: v.label}));
        this.category.products = products;
        this.category = new SportbayCategoryModel(this.category);
        break;
      default:
        this.category[prop] = value;
        this.getListSelect();
        break;
    }
  }

  /**Cria nova categoria com dados padrão */
  emptyCategory() {
    const type = categoryTypes[0].value;
    const merchant = this.rootStore.usersStore.userMerchant;
    this.category = new SportbayCategoryModel({type, merchant});
  }

  /**Salva nova categoria. */
  async save() {
    this.loading = true;
    const list = [].concat(this.category.files);
    this.category.files = [];
    const merchant = await this.rootStore.merchantStore.getDefaultMerchant();
    this.category.merchant = merchant;
    let data = new SportbayCategoryModel(this.category);
    data = JSON.stringify(data);

    const response = await CategoryAPI.save(data);
    this.loading = false;
    if (response.error) {
      if ((response.error.includes('Ordem da categoria existente!')) && response.status === 500) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, 'Ordenação já existente');
      } else {
        this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      }
    } else {
      this.emptyCategory();
      this.sendNewFiles(list, response.uuid);
      await this.getTree();
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Categoria incluida com sucesso');
    }
    return response;
  }

  /**Atualiza categoria categoria. */
  async update() {
    this.loading = true;
    const category = new SportbayCategoryDTOModel(this.category);
    delete category.files;
    const data = JSON.stringify(category);

    const response = await CategoryAPI.update(this.category.uuid, data);
    this.loading = false;
    if (response.error) {
      if ((response.error.includes('Ordem da categoria existente!')) && response.status === 500) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, 'Ordenação já existente');
      } else {
        this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      }
    } else {
      await this.getTree();
      this.handleFiles(this.category.files);
      this.category = new SportbayCategoryModel(response);
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Dados atualizados');
    }
    return response;
  }

  /**Busca categorias em arvores*/

  /*async getTree() {
    this.loading = true;
    const response = await CategoryAPI.getTree();
    if (!response.error) {
      this.categories = response.map((cat) => new SportbayCategoryModel(cat));
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return [];
  }*/

  /**Busca categorias em arvores*/
  async getTree() {
    this.loading = true;
    const response = await CategoryAPI.getTree();
    if (!response.error) {
      this.categories = response.map((cat) => new SportbayCategoryModel(cat));

      this.showCaseCategories = this.categories.filter(
        (cat) => cat.type === CategoriesEnum.VITRINE
      );
      this.collectionCategories = this.categories.filter(
        (cat) => cat.type === CategoriesEnum.COLECAO
      );
      this.loading = false;
      return {
        all: this.categories,
        showcase: this.showCaseCategories,
        collection: this.collectionCategories
      }; // Retornando as categorias para uso imediato
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
  }

  /**Busca categoria por uuid */
  async get(uuid) {
    this.loading = true;
    const response = await CategoryAPI.get(uuid);
    if (!response.error) {
      this.category = new SportbayCategoryModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Deleta categoria */
  async delete(uuid) {
    this.loading = true;
    const response = await CategoryAPI.deleteCategory(uuid);
    if (!response.error) this.toastHelper.notify('info', 'Categoria removida');
    else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  /**Envia arquivo de proposta que foi anexo */
  async handleFiles(files) {
    this.loading = true;

    const newFiles = files.filter((file) => !file.uuid);
    const updateds = files.filter((file) => file.uuid && file.file);
    const deleteds = files.filter((file) => file.uuid && !file.path);

    newFiles.length > 0
    && (await this.sendNewFiles(newFiles, this.category.uuid));
    updateds.length > 0 && (await this.updateFiles(updateds));
    deleteds.length > 0 && (await this.deleteFiles(deleteds));

    this.loading = false;
    return true;
  }

  /**Funcão útil paradá feedbeack sobre envio de arquivos. */
  async filesService(promise, message) {
    const response = await Promise.all(promise);
    const errors = response.filter((r) => r && r.error !== undefined);
    if (errors && errors.length > 0) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, message);
    }
    if (response.error) this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Atualiza imagem da categoria */
  async deleteFiles(files) {
    const promise = files.map(async (file) => {
      await UploadAPI.removeFile(file.uuid);
    });
    return await this.filesService(promise, 'Falha o deletar imagens');
  }

  /**Atualiza imagem da categoria */
  async updateFiles(files) {
    const promise = files.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      await UploadAPI.updateFile(file);
    });
    const response = await this.filesService(promise, 'Falha ao atualizar imagens');
    if (!response.error) {
      setTimeout(() => {
        files.forEach((file) => this.notificationStore.removeItemUpload(file));
      }, 3300);
    }
    return true;
  }

  /**Envia imagem da categoria. */
  async sendNewFiles(files, categoryUuid) {
    const promise = files.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      await UploadAPI.uploadCategory(categoryUuid, file);
    });
    await this.filesService(promise, 'ao incluir imagens');
    setTimeout(() => {
      files.forEach((file) => this.notificationStore.removeItemUpload(file));
    }, 3300);
    return true;
  }

  handleFileCategory(dropedFiles) {
    const {category} = this;
    this._handleImages(dropedFiles, 'category', category.fileCategory);
  }

  handleIconCategory(dropedFiles) {
    const {category} = this;
    this._handleImages(dropedFiles, 'icon-category', category.iconCategory);
  }

  handleIconCategoryMobile(dropedFiles) {
    const {category} = this;
    this._handleImages(dropedFiles, 'icon-category-mobile', category.iconCategoryMobile);
  }

  /**Adiciona imagem ao produto */
  _handleImages(dropedFiles, tag, fileItem) {
    /**Altera arquivo. */
    const dropedFile = dropedFiles.length > 0 ? dropedFiles[0] : undefined;
    if (!fileItem && dropedFile) {
      const newFileItem = new FileModel({file: dropedFile, ...dropedFile, metaTags: [tag]});
      this.category.files.push(newFileItem);
    } else fileItem.updateFile(dropedFile); //FileItem existe, Atualiza valores do arquivo recebido
  }

  /**Retorna lista de categorias para uso no select */
  getListSelect(excludes = {}) {
    if (!excludes) return;
    const localFilter = (category) => {
      const hasChildren = category.childrenCategory.length > 0;
      if (hasChildren) {
        category.childrenCategory = category.childrenCategory.filter(
          localFilter
        );
      }
      return !excludes.some((cat) => cat.uuid === category.uuid);
    };
    if (Array.isArray(excludes)) {
      return this.categories.filter(localFilter);
    }
    return this.categories.filter((cat) => cat.uuid !== excludes.uuid);
  }

  /**Adiciona Propriedade na categoria */
  addPropertieSelected(propertie) {
    const category = this.associate;
    category.push({uuid: propertie.uuid, feature: propertie.className});
    this.category.features.push(propertie);
    this.associate = category;
  }

  async saveConnectComparative(uuidCategory) {
    this.loading = true;
    const data = {
      features: this.category.features,
      categoryUUID: uuidCategory
    };

    const response = await CategoryAPI.saveComparative(data);
    if (!response.error) this.toastHelper.notify('info', 'Comparativo adicionado');
    else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Atualiza propriedade do tipoe valoriação */
  updateTypeComparativeProp(prop) {
    this.categoryUuid = prop;
  }

  /**Remove a propriedade selecionada */
  removePropertieSelected(element) {
    this.loading = true;
    const categoryProperties = this.category.categoryProperties.filter(
      (e) => e.uuid !== element.uuid
    );
    this.category.categoryProperties = categoryProperties;
    this.loading = false;
  }

  /**Remove a propriedade selecionada */
  async removeComparativeSelected(element) {
    if (element.categoryFeatureUUID) {
      this.loading = true;
      const response = await CategoryAPI.removeComparativeSelected(element.categoryFeatureUUID);
      if (!response.error) {
        await this.getCategoriesFeatures(this.categoryUuid);
        this.toastHelper.notify('info', 'Comparativo removido');
      } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
    } else {
      const associate = this.associate.filter((e) => {
        if (e.uuid) {
          return e.uuid !== element.uuid;
        }
        return e;
      });
      const features = this.category.features.filter((e) => e.uuid !== element.uuid);

      this.category.features = features;
      this.associate = associate;
      this.toastHelper.notify('info', 'Comparativo removido');
    }
  }

  // traz todas as categorias
  async getCategoriesFeatures(uuid) {
    this.categoryUuid = uuid;
    this.loading = true;
    const response = await CategoryAPI.getCategoriesFeatures(uuid);
    if (!response.error) {
      this.associate = response;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return [];
  }

  async updateProductsCategoryElastic(slug) {
    this.LoadingUpdateElastic = true
    const response = await CategoryAPI.updateProductsCategory(slug);
    if (!response.error) {
      this.successUpdateProducts = response.data
      this.LoadingUpdateElastic = false
      return response
    } else {
      this.errorUpdateProducts = response.error
      this.LoadingUpdateElastic = false
      return response
    }
  }
}

decorate(CategoryStore, {
  get: action,
  getTree: action,
  save: action,
  update: action,
  delete: action,
  handleFiles: action
});

export default CategoryStore;
