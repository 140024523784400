import { action, decorate, extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import { removeItemList } from "~/helpers/utils/Functions";
import SportBayComparativeModel from "~/models/sportbay/SportBayComparativeModel";
import ComparativeAPI from "../services/ComparativeAPI";
import CategoryAPI from '../services/CategoryAPI';
import { comparativeTypes } from "~/helpers/utils/Selects";


/**Valores inicias de variaveis observadas */
const initValues = {
  loading: false,
  name: undefined,
  type: undefined,
  compare: undefined,
  comparative: {},
  comparatives: [],
  categories: [],
  comparativeItem: [],
}

class ComparativeStore {
  totalPages = 0;
  page = 0;
  size = 10;
  sort = 'name';

  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, { ...initValues });
    this.toastHelper = new ToastHelper();
  }


  reset() {
    this.comparative = new SportBayComparativeModel();
    this.totalPages = 0;
    this.page = 0;
    this.size = 20;
    this.compare = undefined;
    this.sort = 'name';
  }

  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Retorna o merchant do usuário atual */
  get merchant() {
    //return this.rootStore.usersStore.user.merchant;
    this.rootStore.customerTypeStore.getDefaultCustomerType()
  }

  /**Salva novo atributo no sistema */
  async createComparative() {
    if (!this.comparativeItem.name) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'Nome é obrigatório');
      return false;
    }
    if (!this.comparativeItem.type) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'Tipo é obrigatório');
      return false;
    }
    this.loading = true;
    const classAttribute = {
      name: this.comparativeItem.name,
      type: this.comparativeItem.type,
    }
    const data = JSON.stringify(classAttribute);
    let response = await ComparativeAPI.createComparative(data);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Comparativo criado com sucesso');
      this.loading = false;
      return response;
    } else {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'Não foi possivel cadastrar o item'
      );
      this.loading = false;
      return false;
    }
  }

  /**Salva novo atributo no sistema */
  async updateComparative(uuid) {
    if (!this.comparativeItem.name) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'Nome é obrigatório');
      return false;
    }
    if (!this.comparativeItem.type) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'Tipo é obrigatório');
      return false;
    }
    this.loading = true;
    const classAttribute = {
      name: this.comparativeItem.name,
      type: this.comparativeItem.type,
    }
    const data = JSON.stringify(classAttribute);
    let response = await ComparativeAPI.updateComparative(uuid, data);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Comparativo editado com sucesso');
      this.loading = false;
      return response;
    } else {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'Não foi possivel editar o item'
      );
      this.loading = false;
      return false;
    }
  }

  /**Atualiza propriedades da categoria */
  updateProp(prop, value) {
    const comparative = this.comparativeItem
      ? this.comparativeItem
      : new SportBayComparativeModel();
    comparative[prop] = value;
    this.comparativeItem = new SportBayComparativeModel(comparative);
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 20, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = sort ? sort : this.sort;
  }

  /**Busca todos os kit */
  async getList() {
    this.loading = true;
    let params = {sort: this.sort, page: this.page, size: this.size}
    const response = await ComparativeAPI.list(params);

    if (!response.error) {
      this.comparatives = await response.content.map(
        (item) => new SportBayComparativeModel(item)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Delete class */
  async deleteComparative(uuid) {
    this.loading = true;
    const response = await ComparativeAPI.deleteComparative(uuid);
    if (!response.error) {
      const cls = this.comparatives.find(cls => cls.uuid === uuid);
      removeItemList(this.comparatives, cls);
      this.toastHelper.notify(STATUS_HELPER.INFO, "Comparativo deletado com sucesso!");
    }
    else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**
   * Busca comparative por uuid
   * @param  {int} uuidValue
   */
  async getComparativeByUuid(uuidValue) {
    this.loading = true;
    this.comparativeItem = await ComparativeAPI.getComparativeByUuid(uuidValue);
    this.loading = false;
    return this.comparativeItem;
  }


  /**Cria nova categoria com dados padrão */
  emptyCategory() {
    const type = comparativeTypes[0].value;
    this.comparativeItem = new SportBayComparativeModel({ type });
  }


  /**Retorna lista de class para o select */
  getComparativeSelect() {
    return this.comparatives ? this.comparatives.map(cls => ({ value: cls.uuid, label: cls.name })) : [];
  }

  // traz todas as categorias
  async getCategories() {
    this.loading = true;
    let params = {sort: this.sort, page: this.page, size: this.size}
    const response = await CategoryAPI.getTree(params);
    this.loading = false;
    if (!response.error) {
      this.categories = response;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return [];
  }

  /**Retorna lista para uso no select */
  getListCategorySelect(categories = this.categories) {
    return categories.map((ctg) => ({
      value: ctg.uuid,
      label: ctg.categoryName,
    }));
  }
}

export default ComparativeStore;

decorate(ComparativeStore, {
  get: action,
  getClass: action,
  getGroupsForSupplier: action
})
