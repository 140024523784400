import BaseModel from './BaseModel';

class MerchantModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.name = data.name;
    this.nickname = data.nickname;
    this.entity = data.entity;
    this.document = data.document;
    this.email = data.email;
    this.description = data.description;
    this.shortDescription = data.shortDescription;
    this.domain = data.domain;
    this.mobile = data.mobile;
    this.descontoBoleto = data.descontoBoleto;
    this.descontoPix = data.descontoPix;
    this.imageLogo = data.imageLogo;
    this.imageCapa = data.imageCapa;
    this.nameView = data.nameView;
    this.slug = data.slug;
    this.sellerId = data.sellerId;
    this.openingHours = data.openingHours;
    this.faresMdr = data.faresMdr;
    this.faresFee = data.faresFee;
  }
}

export default MerchantModel;
