import { extendObservable } from 'mobx';

import AddressModel from '../AddressModel';
import EuroOrderModel from '../euro/EuroOrderModel';
import PreOrderItemModel from './PreOrderItemModel';
import LuminiCustomerModel from './LuminiCustomerModel';
import BaseModel from '../BaseModel';

class PreOrderModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.chassi = data.chassi;
    this.idPreOrder = data.idPreOrder || 0;
    this.automaker = data.automaker;
    this.created = data.created;
    this.model = data.model;
    this.year = data.year;
    this.customer = new LuminiCustomerModel(data.customer);
    this.order = data.order && new EuroOrderModel(data.order);
    extendObservable(this, {
      shippingAddress: new AddressModel(data.shippingAddress),
      preOrderItems: data.preOrderItems
        ? data.preOrderItems.map((pO) => new PreOrderItemModel(pO))
        : [],
    });
  }

  get clientName() {
    return this.customer.fullName;
  }

  get isValid() {
    if (!this.automaker) return false;
    if (!this.chassi) return false;
    if (!this.model) return false;
    if (!this.year) return false;
    if (!this.shippingAddress) return false;
    if (this.preOrderItems.length === 0) return false;
    return true;
  }

  /**Adiciona endereço de entrega */
  addShippingAddress(address) {
    if (address instanceof AddressModel && address.isValid) {
      this.shippingAddress = address;
      return true;
    }
    return false;
  }

  /**Adiciona preOrder */
  addItem(item) {
    if (item instanceof PreOrderItemModel) {
      this.preOrderItems = [...this.preOrderItems, item];
      return true;
    }
    return false;
  }

  /**Remove preOrder */
  removeItem(item) {
    if (item instanceof PreOrderItemModel) {
      const currentList = [...this.preOrderItems];
      currentList.splice(currentList.indexOf(item), 1);
      this.preOrderItems = [...currentList];
      return true;
    }
    return false;
  }
}

export default PreOrderModel;
