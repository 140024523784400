import { extendObservable } from 'mobx';
import BaseModel from '../BaseModel';

class SportbayShippingCompany extends BaseModel {
  constructor(data = {}) {
    super(data);
    // this['@class'] = 'br.com.stoom.eurobrake.model.EuroShippingService';
    this.code = data.code;
    this.name = data.name;
    this.lockerId = data.lockerId;
    this.cnpj = data.cnpj;
    this.stateRegistration = data.stateRegistration;
    this.municipalRegistration = data.municipalRegistration;
    this.exempt = data.exempt;
    this.site = data.site;
    this.contactName = data.contactName;
    this.contactPhone = data.contactPhone;
    this.taxPercentage = data.taxPercentage;
    this.email = data.email;
    this.phone1 = data.phone1;
    this.branchPhone1 = data.branchPhone1;
    this.phone2 = data.phone2;
    this.branchPhone2 = data.branchPhone2;
    this.fax = data.fax;
    this.doesNotPrintBatchSchedule = data.doesNotPrintBatchSchedule;
    this.deliveryType = data.deliveryType;
    this.marketplace = data.marketplace;
    this.shipFromStore = data.shipFromStore;
    this.longDistance = data.longDistance;
    this.minumumDeliveryTime = data.minumumDeliveryTime;
    this.maximumDeliveryTime = data.maximumDeliveryTime;
    this.merchant = data.merchant;
    this.idAddress = data.idAddress;
    this.street = data.street;
    this.number = data.number;
    this.block = data.block;
    this.zipCode = data.zipCode;
    this.city = data.city;
    this.state = data.state;
    this.addressComplement = data.addressComplement;
    this.country = data.country;
    this.refAddress = data.refAddress;
    this.mainAddress = data.mainAddress;

    extendObservable(this, {
      active: data.active || false,
      ownShippingCompany: data.ownShippingCompany || false,
    });
  }
}

export default SportbayShippingCompany;
