import OrderItemModel from "../OrderItemModel";
import PreOrderItemModel from "../lumini/PreOrderItemModel";

class SportbayOrderItemModel extends OrderItemModel{
    constructor(data = {}) {
        super(data);
        this['@class'] = 'br.com.stoom.sportbay.model.SportbayOrderItem';
        this.amountReturned = data.amountReturned;
        this.preOrderItem = data.preOrderItem && new PreOrderItemModel(data.preOrderItem);
    }

    getValuesToList() {
        return {
            file: this.file ? this.file.path : "",
            name: this.product.name,
            observation: this.observation,
            amount: this.amount,
        }
    }
}

export default SportbayOrderItemModel;
