
import BaseModel from '../BaseModel';

class EuroShippingClass extends BaseModel {
    constructor( data = {} ) {
        super(data);
        this["@class"] = "br.com.stoom.eurobrake.model.EuroShippingClass";
        this.name = data.name
    }
}

export default EuroShippingClass;