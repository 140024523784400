import BaseModel from './BaseModel';
import BannerFileModel from './BannerFileModel';

/**Modelo banner LP */
class BannerLPModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.tag = data.tag;
    this.link = data.link;
    this.title = data.title;
    this.images = data.images
        ? data.images.map((file) => new BannerFileModel(file))
        : [];
    this.mobile = data.mobile
        ? data.mobile.map((file) => new BannerFileModel(file))
        : [];
  }
}

export default BannerLPModel;
