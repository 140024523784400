import {extendObservable} from 'mobx';
import ToastHelper, {STATUS_HELPER} from '~/helpers/ToastHelper';
import {MerchantEnum} from '~/helpers/utils/Enums';
import MerchantModel from '~/models/sportbay/SportbayMerchantModel';
import MerchantAPI from '../services/MerchantAPI';
import AuctionAPI from "~/services/AuctionAPI";

const initValues = {
  loading: false,
  merchant: undefined,
  merchants: [],
  filter_name: '',
  filter_status: undefined,
  filter_IdSeller: '',
  filter_cnpj:"",
  inactiveSellerLoading:false,
  activeSellerLoading: false,
};

/** Store que manipula dados de merchants */
class MerchantStore {
  totalPages = 0;
  page = 0;
  size = 500;
  sort = 'name';
  filter_name = "";
  filter_nameView = "";
  filter_status = undefined;
  filter_IdSeller = '';
  filter_cnpj = "";
  inactiveSellerLoading=false;
  activeSellerLoading= false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {...initValues});
    this.getList();
  }

  reset() {
    this.totalPages = 0;
    this.page = 0;
    this.kits = [];
    this.size = 500;
    this.sort = 'name';
    this.filter_name = "";
    this.filter_nameView = "";
    this.filter_status = undefined;
    this.filter_IdSeller = '';
    this.filter_cnpj = "";
  }
  setFilterNameView(data){
    this.filter_nameView = data;
  }

  setFilterName(data) {
    this.filter_name = data;
  }
  setFilterIdSeller(data) {
    this.filter_IdSeller = data;
  }
  setFilterCnpj(data){
    this.filter_cnpj = data
  }

  setFilterStatus(data) {
    this.filter_status = data;
  }

  async searchBynameView(inputValue, size = 15) {
    this.page = 0;
    this.sort = 'name';
    this.filter_nameView = inputValue;
    this.size = size;

    return await this.getListByType(inputValue);
  }
  async searchByname(inputValue, size = 15) {
    this.page = 0;
    this.sort = 'name';
    this.filter_name = inputValue;
    this.size = size;

    return await this.getListByType(inputValue);
  }
  async searchByIdSeller(inputValue, size = 15) {
    this.page = 0;
    this.sort = 'name';
    this.filter_IdSeller = inputValue;
    this.size = size;

    return await this.getListByType(inputValue);
  }
  async searchByCnpj(inputValue, size = 15) {
    this.page = 0;
    this.sort = 'name';
    this.filter_cnpj = inputValue;
    this.size = size;

    return await this.getListByType(inputValue);
  }

  async searchByStatus(inputValue, size = 15) {
    this.page = 0;
    this.sort = 'name';
    this.filter_status = inputValue;
    this.size = size;

    return await this.getListByType(inputValue);
  }


  /** Cria um merchant vazio */
  createEmptyMerchant() {
    this.merchant = undefined;
    this.merchant = new MerchantModel(this.merchant);
  }

  /** Atualiza uma propriedade do merchant */
  updateProp(prop, value) {
    const {merchant} = this;
    merchant[prop] = value;
    if (prop === 'entity') {
      merchant.document = '';
    }
    this.merchant = new MerchantModel(merchant);
  }

  getDefaultMerchant() {
    if (this.merchants) {
      return this.merchants.filter(
        (f) => f.name === MerchantEnum.SPORTBAY_MERCHANT
      )[0];
    }

    return null;
  }

  async deleteMerchant(uuid) {
    this.loading = true;

    const response = await MerchantAPI.deleteMerchant(uuid);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Merchant inativado com sucesso'
      );

      await this.getList();
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loading = false;

    return response;
  }

  formatCNPJ(cnpj) {
    let formaterCnpj = cnpj
    if (typeof formaterCnpj === 'number') {
      formaterCnpj = cnpj.toString();
    }

    if (typeof formaterCnpj !== 'string' || cnpj.length !== 14) {
      return "erro no CNPJ.";
    }

    return (
      cnpj.substr(0, 2) + '.' +
      cnpj.substr(2, 3) + '.' +
      cnpj.substr(5, 3) + '/' +
      cnpj.substr(8, 4) + '-' +
      cnpj.substr(12, 2)
    );
  }
   removeNonNumeric(cnpj){
    return cnpj.replace(/\D/g, '');
  }


  clearFilters = () => {
    this.filter_name = '';
    this.filter_status = undefined;
    this.filter_IdSeller = '';
    this.filter_cnpj = "";

  }

  /** Retorna lista de merchants no padrão utilizado pelo select component */
  getListSelect() {
    const merchants = this.merchants.map((mMerchant) => ({
      value: mMerchant.uuid,
      label: mMerchant.name + ' - ' + this.formatCNPJ(mMerchant.document),
      document: mMerchant.document,
      data: mMerchant
    }));
    return merchants;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 15, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = sort ? sort : 'name';

    if(this.filter_name !== "" || this.filter_status !== undefined){
      await this.getListByType();
    }else{
      await this.getList()
      return true;
    }
  }


  async getListByType() {
    this.loading = true;
    let search = '';
    
    // if(this.filter_nameView)
    //   search += `nameView:*${this.filter_nameView}*,`
    // else {search = `nameView,`}
    // if (this.filter_name)
    //   search = `name:*${this.filter_name}*,`;
    // else {search = `name,`}
    // if (this.filter_IdSeller)
    //   search = `sellerId:${this.filter_IdSeller},${search}`;
    // else {search = `${search}sellerId,`}
    // if (this.filter_status !== undefined)
    //   search = `active:${this.filter_status},${search}`;
    // else {search = `${search}active,`}
    // if (this.filter_cnpj)
    //   search = `document:${this.removeNonNumeric(this.filter_cnpj)},${search}`;
    // else {search = `${search}document,`}
    
    if (this.filter_nameView)
      search += `nameView:*${this.filter_nameView}*,`;
    else 
      search += `nameView,`;
  
    if (this.filter_name)
      search += `name:*${this.filter_name}*,`;
    else 
      search += `name,`;
  
    if (this.filter_IdSeller)
      search += `sellerId:${this.filter_IdSeller},`;
    else 
      search += `sellerId,`;
  
    if (this.filter_status !== undefined)
      search += `active:${this.filter_status},`;
    else 
      search += `active,`;
  
    if (this.filter_cnpj)
      search += `document:${this.removeNonNumeric(this.filter_cnpj)},`;
    else 
      search += `document,`;

    const response = await MerchantAPI.listByType(search,this.page,this.size,this.sort);
    if (!response.error) {
      if (response.content && response.content.length > 0) {
        this.totalPages = response.totalPages;
        this.page = response.number;
        this.merchants = response.content.map(
          (mMerchant) => new MerchantModel(mMerchant)
        );
        this.loading = false;
        return this.merchants;

      } else {
        this.toastHelper.notify(
          STATUS_HELPER.ERROR,
          "Nenhum encontrado.",
          2000
        )
        this.totalPages = response.totalPages;
        this.page = response.number;
        this.loading  = false
        return this.merchants = []
      }
    } else {
      this.loading = false
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.clearFilters()
    }
  }

  /** Busca lista de merchants */
  async getList(size = this.size, page = this.page, sort = this.sort) {
    this.loading = true;
    const response = await MerchantAPI.getList({ size, page, sort,});
    this.loading = false;
    if (!response.error) {
      this.totalPages = response.totalPages;
      this.page = response.number;
      this.merchants = response.content.map(
        (mMerchant) => new MerchantModel(mMerchant)
      );
      return this.merchants;
    }
    return response;
  }

  /** Salva novo merchant */
  async save() {
    this.loading = true;
    const data = JSON.stringify(this.merchant);
    const response = await MerchantAPI.save(data);
    if (!response.error) this.merchant = new MerchantModel();
    this.loading = false;
    return response;
  }

  /**Busca apenas um determinado merchant */
  async getMerchant(uuid) {
    this.loading = true;
    const response = await MerchantAPI.getMerchantByUUID(uuid);
    if (!response.error) this.merchant = new MerchantModel(response);
    else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Atualiza os campos da loja */
  async update() {
    this.loading = true;

    this.merchant.percentage = true;

    const data = JSON.stringify(this.merchant);

    const response = await MerchantAPI.update(this.merchant.uuid, data);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Alterações gravadas com sucesso.'
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loading = false;
    return response;
  }
  async inactiveSellerElastic(uuidSeller) {
    this.inactiveSellerLoading = true;
    const response = await MerchantAPI.inactiveSellerElasticAndProducts(uuidSeller);
    if(!response.error){
      this.merchant.active = false
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Seller inativo com sucesso.'
      );
    }else{
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'Erro ao inativar seller.'
      );
    }
    this.inactiveSellerLoading = false;
  }
  async activeSellerElastic(uuidSeller) {
    this.activeSellerLoading = true;
    const response = await MerchantAPI.activeSellerElasticAndProducts(uuidSeller);
    if(!response.error){
      this.merchant.active = true
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Seller ativo com sucesso.'
      );
    } else {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'Erro ao ativar seller.'
      );
    }
    this.activeSellerLoading = false;
  }


}

export default MerchantStore;
