import { inject, observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import DropdownComponent from '../../components/DropdownComponent/DropdownComponent';
import LogoComponent from '../../components/LogoComponent';

/**
 * @Name: Barra Superior (TopBar1)
 * @Data: 2019
 * @Desc: TopBar com bootstrap
 * @props:
 */

const TopBarContainer = (props) => {
  const { authStore, usersStore } = props;

  const { user } = usersStore;

  //Retorna nome do usuario
  const userName = () => (user ? user.name : 'username');

  return !authStore.isAuthenticated || !user ? (
    <></>
  ) : (
    <>
      <section className="TopBarContainer">
        <nav className="navbar navbar-expand-lg navbar-dark">
          <div className="container block-manager">
            <div className="navbar-brand">
              <LogoComponent />
            </div>
            <div className="navbar-menu-container">
              <i className="far fa-user-circle" />
              {/* <NotificationContainer /> */}
              <DropdownComponent title={userName()} alignRight>
                <Link
                  className="dropdown-item"
                  to={`/editar-administrador/${user.uuid}`}
                >
                  Editar Perfil
                </Link>
                {/* <button className="dropdown-item" onClick={editProfile} >Editar Perfil</button> */}
                {/* <button className="dropdown-item" onClick={showHelp}>Ajuda</button> */}
                <div className="dropdown-divider" />
                <Link className="dropdown-item" to="/logout">
                  Sair
                </Link>
              </DropdownComponent>
            </div>
          </div>
        </nav>
      </section>
    </>
  );
};
export default inject(({ authStore, usersStore }) => ({
  authStore,
  usersStore,
}))(observer(TopBarContainer));
