import BannerFileModel from '../BannerFileModel'

/**Modelo imagem banner */
class SportbayBannerFileModel extends BannerFileModel {
  constructor(data = {}) {
    super(data);
    this.promotionUUID = data.promotionUUID;
  }
}

export default SportbayBannerFileModel;
