// eslint-disable-next-line import/named
import BaseAPI, { URLS } from './BaseAPI';

class BrandAPI {
  static _exception(e, message) {
    // eslint-disable-next-line no-console
    console.log(e);

    return { error: message };
  }

  /**
   * Request que cria uma nova marca.
   * @param {Object} brand - Objeto do produto que será criado
   */
  static async save(brand) {
    try {
      const response = await BaseAPI.post(URLS.BRANDS, brand);

      if (response.status === 201 || response.status === 200) {
        return response;
      }

      return { error: 'Erro inesperado ao cadastrar uma nova marca' };
    } catch (e) {
      return this._exception(e, 'Falha ao cadastrar uma nova marca');
    }
  }

  /**
   * Request que busca todas as marcas cadastradas.
   * @param {Object} brand
  */
  static async getAll(params = {}) {
    try {
      let url = URLS.BRANDS;
      if (params.name) {
        url += `/name/${params.name}`;
        delete params.name;
      }
      const response = await BaseAPI.get(url, params);

      if (response.status === 200) {
        return response;
      }

      return { error: 'Erro inesperado ao buscar as marcas' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar as marcas');
    }
  }

  /**
   * Request que busca uma marca pelo UUID
   * @param {Object} brand
   */
  static async getByUuid(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.BRANDS}/${uuid}`);

      if (response.status === 200) {
        return response;
      }

      return { error: 'Erro inesperado ao buscar a marca' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar a marca');
    }
  }

  /**
   * Request que atualiza uma marca
   * @param {Object} brand
   */
  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.BRANDS}/${uuid}`, data);

      if (response.status === 200) {
        return response;
      }

      return { error: 'Erro inesperado ao atualizar a marca' };
    } catch (e) {
      return this._exception(e, 'Falha ao atualizar a marca');
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.BRANDS}/${uuid}`);

      if (response.status === 200) {
        return response.data;
      }

      return { error: 'Erro inesperado ao deletar a marca' };
    } catch (e) {
      return this._exception(e, 'Falha ao deletar a marca');
    }
  }

  /**
   * Request que atualiza uma marca
   * @param {Object} brand
   */
  static async findByName(params = {}, name) {
    try {
      const response = await BaseAPI.get(`${URLS.BRANDS}/name/${name}`, params);

      if (response.status === 200) {
        return response;
      }

      return { error: 'Erro inesperado ao buscar a marca' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar a marca');
    }
  }

  /**
   * Request que busca todas as marcas cadastradas para o select da pdp
   * @param {Object} brand
  */
  static async getAllSelect() {
    try {
      const response = await BaseAPI.get(`${URLS.BRANDS}/all`);

      if (response.status === 200) {
        return response;
      }

      return { error: 'Erro inesperado ao buscar as marcas' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar as marcas');
    }
  }
}

export default BrandAPI;

// {{base_url}}/manager/brands/all
