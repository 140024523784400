import BaseAPI, { URLS } from './BaseAPI';

class CouponAPI {
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * @param  {object} data - dados do novo cupom que será salvo
   */
  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.SPORTBAY_PROMOTION, data);

      if (response.status === 201) return response;

      return { error: 'Erro inexperado ao cadastrar cupom' };
    } catch (e) {
      return this._exception(e, e.response.data.message);
    }
  }

  /**
   * @param  {string} uuid - Uuid da promoçao a ser deletada
   */
  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.COUPONS}/${uuid}`);
      if (response.status === 204) return response.data;
      const error =
        response.status === 404
          ? 'Cupom/campanha não encontrado'
          : 'Erro inexperado ao buscar Cupom/campanha';
      return { error };
    } catch (e) {
      return this._exception(e, 'Erro ao deletar Cupom/campanha');
    }
  }

  /**Busca lista de cupons */
  static async getList(params) {
    try {
      const response = await BaseAPI.get(URLS.COUPONS_SEARCH, params);
      if (response.status === 200) return response.data;
      return { error: 'Erro inexperado ao busca listas de cupons' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar lista de cupons');
    }
  }
  /**Busca lista de cupons by merchant*/
  static async getListByMerchant(params) {
    try {
      const response = await BaseAPI.get(URLS.COUPONS_SEARCH+'/merchant', params);
      if (response.status === 200) return response.data;
      return { error: 'Erro inexperado ao busca listas de cupons' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar lista de cupons');
    }
  }
  /**Busca Cupom pelo uuid */
  static async getCouponByUUID(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.COUPONS}/${uuid}`);
      if (response.status === 200) return response.data;
      return { error: 'Erro inexperado ao buscar cupom' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar cupom');
    }
  }

  /**Busca modelos da campanha */
  static async getCampaignModels() {
    try {
      const response = await BaseAPI.get(
        `${URLS.SPORTBAY_PROMOTION}/campaign_models`
      );
      if (response.status === 200) return response.data;
      return { error: 'Erro inexperado ao buscar modelos de campanha' };
    } catch (e) {
      return this._exception(e, 'Falha ao buscar modelos de campanha');
    }
  }

  /**
   * Atualiza campos de um cupom.
   * @param  {Object} params
   */
  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.COUPONS}/${uuid}`, data);
      if (response.status === 200) return response;
      return { error: 'Erro inesperado ao atualizar o cupom' };
    } catch (e) {
      return this._exception(e, e.message);
    }
  }

  /**
   * Associa um merchant a uma promoção
   * @param {string} merchantUuid - uuid do merchant a ser associado à promoção
   * @param {string} couponUuid - uuid da promoção a ser associada ao merchant
   */
  static async associateMerchantCoupon(merchantUuid, couponUuid) {
    try {
      const response = await BaseAPI.put(`${URLS.COUPONS}/${couponUuid}/merchant/${merchantUuid}/associate`);
      if (response.status === 200) return response;
      return { error: 'Erro inesperado ao associar se vincular à promoção' };
    } catch (e) {
      return this._exception(e, e.message);
    }
  }

  /**
   * Desassocia um merchant a uma promoção
   * @param {string} merchantUuid - uuid do merchant a ser desassociado à promoção
   * @param {string} couponUuid - uuid da promoção a ser desassociada ao merchant
   */
   static async disassociateMerchantCoupon(merchantUuid, couponUuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.COUPONS}/${couponUuid}/merchant/${merchantUuid}/disassociate`);
      if (response.status === 200) return response;
      return { error: 'Erro inesperado ao associar se desvincular da promoção' };
    } catch (e) {
      return this._exception(e, e.message);
    }
  }
}

export default CouponAPI;
