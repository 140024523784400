/** classe util que vai auxiliar a iteração com localstorage */
class StorageUtil {
  /** Pega o token do localstorage*/
  static getItem(key) {
    return localStorage.getItem(key);
  }

  /**Salva o token no localstorage */
  static setItem(key, currentToken) {
    localStorage.setItem(key, currentToken);
  }

  /**Limpa o token da sessão */
  static remove(key) {
    localStorage.removeItem(key);
  }

  static cleanAll() {
    Object.entries(KEYS).forEach(([key, value]) => {
      StorageUtil.remove(value);
    });
  }
}

//Declaração das chaves
export const KEYS = {
  TOKEN_KEY: 'euro-m-token!',
  USER_KEY: 'euro-m-username!',
  REMEMBER: 'euro-m-remember-me!',
  PATH_MENU: 'euro-m-path-manager',
  AUTH_KEY: 'euro-m-auth'
};

export default StorageUtil;
