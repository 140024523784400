import axios from 'axios';
import BaseAPI, { URLS, baseURL } from './BaseAPI';
import StorageUtil, { KEYS } from '~/helpers/utils/StorageUtil';
import { error } from 'jquery';


class ReviewProductsAPI {
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }
  static async getAllReviewProduct(boolean, category, page, size){
    try {
      const response = await BaseAPI.get(`/manager/review?approved=${boolean}&category=${category}&page=${page}&size=${size}`);
      if(response.status === 200) return response.data;
      return {error: "Falha ao buscar avaliações"}
    } catch (error) {
      return this._exception(error, 'Falha ao buscar avaliações.')
    }
  }
  static async updateReview(uuidReview, boolean, category){
    try {
      const response = await BaseAPI.patch(`/manager/${uuidReview}?status=${boolean}&category=${category}`);
      if(response.status === 200) return response.data;
      return { error: "Falha ao aprovar avaliações 1 "}
    } catch (error) {
      return this._exception(error, 'Falha ao aprovar avaliações. 2')
    }
  }

}

export default ReviewProductsAPI;