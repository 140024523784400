import { convertPrice } from '~/helpers/utils/Functions';
import BaseModel from '../BaseModel';
import SportbayCategoryModel from './SportbayCategoryModel';
import SportbayFileModel from './SportbayFileModel';
import ItemsCombo from '../ItemsCombo';
import MerchantModel from '../MerchantModel';
import PriceModel from '../PriceModel';
import ProductPropertyModel from '../ProductPropertyModel';
import StockModel from '../StockModel';
import SupplierModel from '../SupplierModel';
import { CategoriesEnum } from '~/helpers/utils/Enums';

class SportbayProductModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this['@class'] = 'br.com.stoom.sportbay.model.SportbayProduct';
    this.approved = data.approved;
    this.relevance = data.relevance;
    this.sizeBayId = data.sizeBayId;
    this.allowCustom = data.allowCustom;
    this.parent = data.parent || false;
    this.numberPrice = data.numberPrice;
    this.namePrice = data.namePrice;
    this.customization = data.customization;
    this.pathVideo = data.pathVideo;
    this.pathImage = data.pathImage;
    this.model3DLink = data.model3DLink;
    this.dataKeyAR = data.dataKeyAR;
    this.productVariations = data.productVariations
      ? data.productVariations.map((a) => new SportbayProductModel(a))
      : [];
    this.productAssociated = data.productAssociated ? data.productAssociated.map((a) => a)
      : [];
    this.skuCode = data.skuCode;
    this.seo = data.seo;
    this.barcode = data.barcode;
    this.name = data.name;
    this.shortName = data.shortName;
    this.description = data.description;
    this.shortDescription = data.shortDescription;
    this.metaCanonical = data.metaCanonical;
    this.metaTitle = data.metaTitle;
    this.metaKeywords = data.metaKeywords;
    this.metaDescription = data.metaDescription;
    this.slug = data.slug;
    this.redirect = data.redirect;
    this.display = data.display !== undefined ? data.display : true;
    this.searchable = data.searchable !== undefined ? data.searchable : true;
    this.supplier = data.supplier
      ? new SupplierModel(data.supplier)
      : undefined;
    this.supplierNumber = data.supplierNumber;
    this.currency = data.currency ? data.currency : 'BRL';
    this.fakePrice = data.fakePrice;
    this.absoluteDiscount = data.absoluteDiscount;
    this.percentualDiscount = data.percentualDiscount;
    this.discountBeginDate = data.discountBeginDate && new Date(data.discountBeginDate);
    this.discountEndDate = data.discountEndDate && new Date(data.discountEndDate);
    this.releaseDate = data.releaseDate && new Date(data.releaseDate);
    this.weight = data.weight;
    this.height = data.height;
    this.width = data.width;
    this.length = data.length;
    this.shippingWeight = data.shippingWeight;
    this.shippingHeight = data.shippingHeight;
    this.shippingWidth = data.shippingWidth;
    this.shippingLength = data.shippingLength;
    this.stocks = data.stocks
      ? data.stocks.map((props) => new StockModel(props))
      : [];
    this.prices = data.prices
      ? data.prices.map((props) => new PriceModel(props))
      : [];
    this.committedStock = data.committedStock;
    this.securityStock = data.securityStock;
    this.deliveryTime = data.deliveryTime;
    this.stockUnit = data.stockUnit;
    this.merchant = new MerchantModel(data.merchant);

    this.productProperties = data.productProperties
      ? data.productProperties.map((prop) => new ProductPropertyModel(prop))
      : [];

    this.itemsCombo = data.itemsCombo
      ? data.itemsCombo.map((prop) => new ItemsCombo(prop))
      : [];

    this.baseProduct = data.baseProduct;
    this.files = data.files ? data.files.map((file) => new SportbayFileModel(file)) : [];
    this.filesImages = data.filesImages ? data.filesImages : [];
    /*this.categories = data.categories
      ? data.categories.map((cat) => new SportbayCategoryModel(cat))
      : [];*/
    this.categories = data.categories
      ? data.categories.filter((cat) => {
        if (cat.type === CategoriesEnum.VITRINE) {
          return new SportbayCategoryModel(cat);
        }
        return [];
      })
      : [];
    this.collections = data.categories
      ? data.categories.filter((cat) => {
        if (cat.type === CategoriesEnum.COLECAO) {
          return new SportbayCategoryModel(cat);
        }
        return [];
      })
      : [];
    this.brand = data.brand;
    this.observation = data.observation;
    this.ordering = data.ordering;
    this.freeShipping = data.freeShipping;
  }

  get price() {
    return this.prices.filter((p) => p.customerType.name === 'A')[0];
  }

  /** Preço formatado em string R$9999,999  */
  get priceNumber() {
    return this.prices.filter((p) => p.customerType.name === 'A')[0]
      ? this.prices.filter((p) => p.customerType.name === 'A')[0].price
      : 0;
  }

  /** Preço formatado em string R$9999,999  */
  get priceString() {
    if (this.priceNumber) return convertPrice(this.priceNumber);
    return '';
  }

  get supplierName() {
    return this.supplier ? this.supplier.name : '';
  }

  getFile(tag) {
    return this.files.find(
      (file) => file.path && file.metaTags.some((metaTag) => metaTag === tag)
    );
  }

  getFiles(tag) {
    return this.files.filter(
      (file) => file.path && file.metaTags.some((metaTag) => metaTag === tag)
    );
  }

  /** Preço formatado em string R$99999,9999 */
  get fakePriceString() {
    if (this.fakePrice) return convertPrice(this.fakePrice);
    return undefined;
  }

  /** Desconto absoluto formatado em string R$999999,99 */
  get absoluteDiscountString() {
    if (this.absoluteDiscount) return convertPrice(this.absoluteDiscount);
    return '';
  }

  /** Add novo supplier */
  setSupplier(supplier) {
    this.supplier = new SupplierModel(supplier);
  }
}

export default SportbayProductModel;
