import BaseAPI, { URLS } from "./BaseAPI";

class AutomakerAPI {

    /**Método util para retorno de erro */
    static _exception(e, message) {
        console.log(e);
        return { error: message }
    }

    /**
     * @param  {string} uuid - Uuid da Montadora à ser procurada
     */
    static async get(uuid) {
        try {
            const response = await BaseAPI.get(`${URLS.AUTOMAKER}/${uuid}`);
            if (response.status === 200) return response.data;
            const error = response.status === 404 ? "Montadora não encontrada" : "Erro inexperado ao buscar Montadora";
            return { error }
        } catch (e) {
            return this._exception(e, 'Erro ao buscar Montadora');
        }
    }


    /**
     * @param  {string} uuid - Uuid da Montadora à ser deletada
     */
    static async delete(uuid) {
        try {
            const response = await BaseAPI.delete(`${URLS.AUTOMAKER}/${uuid}`);
            if (response.status === 200) return response.data;
            const error = response.status === 404 ? "Montadora não encontrada" : "Erro inexperado ao buscar Montadora";
            return { error }
        } catch (e) {
            return this._exception(e, 'Erro ao deletar Montadora');
        }
    }

    /**
     * @param  {string} uuid - Uuid da Montadora à ser atualizada
     */
    static async update(uuid, data) {
        try {
            const response = await BaseAPI.put(`${URLS.AUTOMAKER}/${uuid}`, data);
            if (response.status === 200) return response.data;
            const error = response.status === 404 ? "Montadora não encontrada" : "Erro inexperado ao atualizar Montadora";
            return { error }
        } catch (e) {
            return this._exception(e, 'Erro ao atualizar dados da Montadora');
        }
    }

    /**
     * @param  {object} data - dados do novo automaker que será salvo
     */
    static async save(data) {
        try {
            const response = await BaseAPI.post(URLS.AUTOMAKER, data);
            if (response.status === 201) return response;
            return { error: 'Erro inexperado ao cadastrar Montadora' }
        } catch (e) {
            return this._exception(e, 'Falha ao cadastrar Montadora');
        }
    }

    /**Busca lista de automaker */
    static async getList(params) {
        try {
            const response = await BaseAPI.get(URLS.AUTOMAKER, params);
            if (response.status === 200) return response.data;
            return { error: 'Erro inexperado ao buscar lista de Montadora' }
        } catch(e) {
            return this._exception(e, "Falha ao buscar lista de Montadora");
        }
    }

     /**Busca lista de automaker */
     static async getListModels(params) {
        try {
            const response = await BaseAPI.get(`${URLS.AUTOMAKER}/models`, params);
            if (response.status === 200) return response.data;
            return { error: 'Erro inexperado ao buscar lista de Modelos' }
        } catch(e) {
            return this._exception(e, "Falha ao buscar lista de Modelos");
        }
    }

}
export default AutomakerAPI;