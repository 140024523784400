import BaseAPI, { URLS } from './BaseAPI';

class HomeStoreAPI {
  /**Método util para retorno de erro */
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * API para buscar os componentes da Home
   */
  static async getAllComponents() {
    try {
      const response = await BaseAPI.get(`${URLS.HOME}`);
      if (response.status === 200) return response.data;
      const error =
        response.status === 404
          ? 'Componentes da home não encontrados'
          : 'Erro inexperado ao buscar componentes da home';
      return { error };
    } catch (e) {
      return this._exception(e, 'Erro ao buscar componentes da home');
    }
  }

  /**
   * @param  {Object} data - Objeto que vai conter os arrays de componentes web e mobile
   */
  static async saveComponentsHome(data) {
    try {
      const response = await BaseAPI.put(`${URLS.HOME}`, data);
      if (response.status === 201) return response.data;
      const error =
        response.status === 404
          ? 'Serviço de entrega não encontrado'
          : 'Erro inexperado ao atualizar Serviço de entrega';
      return { error };
    } catch (e) {
      return this._exception(e, 'Erro ao atualizar dados da indústria');
    }
  }

  static async getHomeSeo() {
    const params = '?path=/';

    try {
      const response = await BaseAPI.get(`${URLS.MANAGER}/metadata${params}`);

      return response.data;
    } catch (err) {
      return this._exception(err, 'Erro ao consultar SEO da home');
    }
  }

  static async saveHomeSeo(seo) {
    try {
      const data = {
        '@class': 'br.com.stoom.sportbay.model.SportbayMetadata',
        path: '/',
        metainfos: [
          {
            '@class': 'br.com.stoom.sportbay.model.SportbayMetainfo',
            name: 'seo',
            info: seo,
            ordering: 1
          }
        ]
      };
      const response = await BaseAPI.put('/manager/metadata', data);

      if (response.status === 201) return response.data;
      const error =
        response.status === 404
          ? 'Erro ao salvar SEO da home'
          : 'Erro ao salvar SEO da home';
      return { error };
    } catch (e) {
      return this._exception(e, 'Erro ao salvar SEO da home');
    }
  }

  static async createCacheHome(type) {
    try {
      if (type === 'new') {
        const response = await BaseAPI.post(`${URLS.MANAGER}/redis/new/home`);
        if (response.status === 200) {
          return response;
        }
      } else {
        const response = await BaseAPI.post(`${URLS.MANAGER}/redis/home`);
        if (response.status === 200) {
          return response;
        }
      }

    } catch (err) {
      return this._exception(err, 'Erro ao criar cache da home');
    }

  }

  static async reseteCacheHome(type) {
    try {
      if(type === 'new'){
        const response = await BaseAPI.post(`${URLS.MANAGER}/redis/new/home/reset`);

        if(response.status === 200){
          return response;
        }
      }else{
        const response = await BaseAPI.post(`${URLS.MANAGER}/redis/home/reset`);

        if(response.status === 200){
          return response;
        }
      }
    } catch (err) {
      return this._exception(err, 'Erro ao resetar o cache da home');
    }

  }
}

export default HomeStoreAPI;
