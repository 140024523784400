import { name } from "dayjs/locale/pt-br";
import { extendObservable, decorate, action } from "mobx";
import { toast } from "react-toastify";
import ToastHelper, {STATUS_HELPER} from '~/helpers/ToastHelper';
import BannerFileModel from "~/models/BannerFileModel";
import BannerLPModel from "~/models/BannerLandingPageModel";
import ContentDataModel from "~/models/ComponentContentModel";
import ComponentLPModel from "~/models/ComponentLPModel";
import FileModel from "~/models/FileModel";
import LandingPageModel from "~/models/LandingPageModel";
import LandingPageAPI from "~/services/LandingPageAPI";

const initValues = {
    loading: false,
    loadingLandingPage: false,
    loadingComponent: false,
    landingPage: {},
    landingPages: [],
    banner: {},
    bannerFile: {},
    bannerMobile: {},
    components : [],
    component : {},
    content: {},
    faqContent: {}
}

class LandingPageStore {
    totalPages = 0;
    page = 0;
    size = 10;
    status = false;
    loadingSearchProduct = false;
    loading = false;
    loadingLandingPage = false;
    loadingComponent = false;
    toogleActive = false;
    ToastHelper = new ToastHelper();

    constructor() {
        extendObservable(this, {...initValues});
        this.landingPage = new LandingPageModel();
        this.banner = new BannerLPModel();
    }

    reset = () => {
        this.loading = false;
        this.loadingLandingPage = false;
        this.loadingComponent = false;
        this.landingPage = {};
        this.landingPages = [];
        this.banner = {};
        this.bannerFile = {};
        this.bannerMobile = {};
        this.components = [];
        this.component = {};
        this.content = {};
        this.faqContent = {};
        this.totalPages = 0;
        this.page = 0;
        this.size = 10;

    }

    initializeLandingPage = () => {
        this.landingPage = new LandingPageModel();
    }

    async handleChange (props, value) {
        const {landingPage} = this;

        landingPage[props] = value;

        if(props === 'slug'){
            const regex = /^[a-z0-9\-]+$/;
            if(!regex.test(value) && value !== '') {
                toast.error('Slug inválido. Utilize apenas letras minúsculas, números e hífen.');
                return;
            }
            
            landingPage[props] = value;
        }

        if(props === 'startDate'){
            if(this.landingPage.endDate !== undefined || this.landingPage.endDate !== null){
                if(value > this.landingPage.endDate){
                    toast.error('Data de início não pode ser maior que a data de término.');
                    return;
                }
            }
        }

        if(props === 'endDate'){
            if(this.landingPage.startDate !== undefined || this.landingPage.startDate !== null){
                if(value < this.landingPage.startDate){
                    toast.error('Data de término não pode ser menor que a data de início.');
                    return;
                }
            }
        }

        if(props === 'file'){

            if (value.length === 0) {
                landingPage[props] = null;
                return;
            }
    
            const sanitizedFileName = this.sanitizeFileName(value[0].name);

            const newFile = new File([value[0]], sanitizedFileName, {
                type: value[0].type,
                lastModified: value[0].lastModified
            });

            const fileData = new FileModel({
                ...value[0],
                file: newFile,
                name: sanitizedFileName
            });
    
            landingPage[props] = fileData;
        }

        this.landingPage = new LandingPageModel(landingPage);
    }

    handleAddBanner = () => {
        const {banner, bannerFile} = this;

        banner.images.push(bannerFile);

        this.bannerFile = {};
        this.banner = new BannerLPModel(banner);
    }

    handleAddBannerMobile = () => {
        const {banner, bannerMobile} = this;

        banner.mobile.push(bannerMobile);

        this.bannerMobile = {};
        this.banner = new BannerLPModel(banner);
    }

    handleSubmitBanner = (file) => {
        const {bannerFile} = this;

        bannerFile.file =  new FileModel(file[0]);
        bannerFile.dataFile = file;

        this.bannerFile = bannerFile;
    }

    handleCreateComponent = () => {
        const {component} = this;

        this.component = new ComponentLPModel(component);
    }

    handleChangeComponent(props, value){
        const {component} = this;

        if(props === 'ordering'){
            value = parseInt(value);
            component[props] = value;
        }

        if(props === 'tag'){
            this.component = new ComponentLPModel();
            component.description = undefined;
            component.ordering = undefined;
            this.content = new ContentDataModel();
            component.content = [];
            component.faq = [];
            this.component = new ComponentLPModel(component);
        }

        component[props] = value;

        this.component = new ComponentLPModel(component);
    }

    handleChangeComponentContent(props, index, value) {
        const {component} = this;

        if(props === 'ordering'){
            value = parseInt(value);
            component.content[index][props] = value;
        }

        component.content[index][props] = value;

        this.component = new ComponentLPModel(component);
    }

    handleAddImageContent = async (file) => {
        const {component} = this;
    
        if (!component) {
            this.component = new ComponentLPModel();
        }
    
        const fileData = new FileModel({
            ...file[0],
            file: file[0]
        });
    
        const response = await LandingPageAPI.uploadFile(fileData);
    
        if (response.error) {
            console.error('Erro ao fazer upload do arquivo:', response.error);
            return;
        }
    
        fileData.path = response;
        fileData.metaTags = ['img_content'];
    
        component.content[0].images = component.content[0].images === undefined ? [] : component.content[0].images;
        component.content[0].images.push(fileData);
    
        this.component = new ComponentLPModel(component);
    }

    handleAddItemInContent = () => {
        const {component, content} = this;

        if (!component.content) {
            component.content = [];
        }

        const newContent = new ContentDataModel(content);

        component.content.push(newContent);

        this.component = new ComponentLPModel(component);

        this.content = {};
    }

    handleRemoveItemInContent = (index) => {
        const {component} = this;

        component.content.splice(index, 1);

        this.component = new ComponentLPModel(component);
    }

    handleAddFaqComponent = () => {
        const {component, faqContent} = this;

        component.faq.push(faqContent);

        this.component = new ComponentLPModel(component);
        this.faqContent = {};
    }

    handleDeleteFaqQuestion = async (uuid, index) => {
        const { component } = this;

        if(uuid !== null) {
            const response = await LandingPageAPI.deleteFaqComponent(uuid);

            if(response.error){
                toast.error(response.error);
                return;
            }

            component.faq = component.faq.filter((faq) => faq.uuid !== uuid);

            this.component = new ComponentLPModel(component);
        }

        if(component.faq.length > 1){

            const newlist = component.faq.splice(index, 1);
            component.faq = [...newlist];
            this.component = new ComponentLPModel(component);

        } else {
            component.faq = [];
            this.component = new ComponentLPModel(component);
        }
    }

    handleChangeContentByIndex = async (index, props, value, isMobile) => {
        const {component} = this;

        
        if(!component.content[index]){
            component.content[index] = new ContentDataModel();
        }
        
        if(props === 'ordering'){
            value = parseInt(value);

            component.content[index][props] = value;
        }

        if(props === 'mobile') {
            component.content[index][props] = value
        }

        if(isMobile) {
            component.content[index].mobile = isMobile
        }

        if(props === 'image'){

            if (value.length === 0) {
                component.content[index].images = null;
                return;
            }

            const sanitizedFileName = this.sanitizeFileName(value[0].name);

            const newFile = new File([value[0]], sanitizedFileName, {
                type: value[0].type,
                lastModified: value[0].lastModified
            });

            let fileData = new FileModel({
                ...value[0],
                file: newFile,
                name: sanitizedFileName
            });

            fileData ={
                ...fileData, 
                name: sanitizedFileName,
                size: value[0].size,
                metaTags: ['img_content']
            };

            component.content[index].images = component.content[index].images === undefined ? [] : component.content[index].images;
            component.content[index].images.pop();
            component.content[index].images.push(fileData);
            
            this.component = new ComponentLPModel(component);
            return;
        }

        if(component.tag === 'imageAndVideo'){
            if(props === 'link'){
                const newUrl = value.replace(/watch\?v=/g, 'embed/');

                if(newUrl.includes('www.youtube.com/embed/')){
                    component.content[index].link = newUrl
                }else {
                   this.ToastHelper.notify(STATUS_HELPER.ERROR, 'URL inválida. Por favor, insira uma URL válida do Youtube.');
                }
            }
        }

        if(component.tag === 'videoFull'){
            if(props === 'link'){
                const newUrl = value.replace(/watch\?v=/g, 'embed/');

                if(newUrl.includes('www.youtube.com/embed/')){
                    component.content[index].link = newUrl
                }else {
                   this.ToastHelper.notify(STATUS_HELPER.ERROR, 'URL inválida. Por favor, insira uma URL válida do Youtube.');
                }
            }
        }

        component.content[index][props] = value;

        this.component = new ComponentLPModel(component);
    }

    handleChangeFaqContent(index, props, value) {
        const {component} = this;

        if(!component.faq[index]){
            component.faq[index] = {};
        }

        component.faq[index][props] = value;

        this.component = new ComponentLPModel(component);
    }

    handleSaveComponent = async () => {
        const { component, components } = this;
        this.loadingComponent = true;
        let isValid = true;
    
        try {
            if (component.content) {
                for (let content of component.content) {
                    if (content.images) {
                        for (let image of content.images) {
                    
                            if (image.file) {
                                const response = await LandingPageAPI.uploadFile(image.file);
                                if (response.error) {
                                    isValid = false;
                                    this.loadingComponent = false;
                                    this.ToastHelper.notify(STATUS_HELPER.ERROR, response.error);
                                    return { error: response.error };
                                }
                                image.path = response;
                                image.file = image.file;

                                image = new FileModel(image);
                            }
                        }
                    }

                    content = new ContentDataModel(content)
                }
            }

            const data = new ComponentLPModel(component)

            if(isValid){

                const response = await LandingPageAPI.createComponent(data);
        
                if (response.error) {
                    toast.error(response.error);
                    this.loadingComponent = false;
                    return { error: response.error };
                }

                const savedComponent = new ComponentLPModel(response);
                components.push(savedComponent);
        
                const ordering = components.sort((a, b) => a.ordering - b.ordering);

                this.loadingComponent = false;
                this.components = ordering;
                this.component = {};
                this.content = {};
                this.faqContent = {};
                this.ToastHelper.notify(STATUS_HELPER.INFO, 'Componente criado com sucesso.');
            }
    
        } catch (error) {
            console.log(error);
        }
    }

    handleDeleteComponent = async (uuid) => {
        try {
            const response = await LandingPageAPI.deleteComponent(uuid);

            if(response?.error){
                return { error: response.error };
            }

            this.components = this.components.filter((component) => component.uuid !== uuid);
        } catch (error) {
            console.log(error);
        }
    }

    handleCreateLandingPage = async (landingPage) => {

        try {
            this.loadingLandingPage = true;
            if(landingPage.file){
                let file = landingPage.file;

                const response = await LandingPageAPI.uploadFile(file.file);

                if (response.error) {
                    console.error('Erro ao fazer upload do arquivo:', response.error);
                    return { error: response.error };
                }
    
                file.path = response;
                file.metaTags = ['img_landing'];
    
                const newItem = {
                    ...file,
                    name: file.file.name,
                    size: file.file.size,
                };

                landingPage.file = newItem;
            }

            const response = await LandingPageAPI.createLandingPage(landingPage);

            if(response?.error){
                this.loadingLandingPage = false;
                return { error: response.error };
            }

            this.loadingLandingPage = false;
            return response;
        } catch (error) {
            console.log(error);
        }
    }

    handleUpdateLandingPage = async (landingPage) => {
        try {
            this.loadingLandingPage = true;
            let data = landingPage;

            if (data.file && data.file.uuid === undefined) {
                let file = data.file;

                const response = await LandingPageAPI.uploadFile(file.file);
    
                if (response.error) {
                    this.loadingLandingPage = false;
                    console.error('Erro ao fazer upload do arquivo:', response.error);
                    return { error: response.error };
                }
    
                file.path = response;
                file.metaTags = ['img_landing'];
    
                const newItem = {
                    ...file,
                    name: file.file.name,
                    size: file.file.size,
                };
    
                data.file = newItem;
            }

            const updatedData = JSON.stringify(data);
    
            const response = await LandingPageAPI.updateLandingPage(data.uuid, updatedData);
    
            if (response.error) {
                this.loadingLandingPage = false;
                console.error('Erro ao atualizar a landing page:', response.error);
                return { error: response.error };
            }
    
            this.loadingLandingPage = false;

            return response;
    
        } catch (error) {
            console.log('Erro no processo de atualização da landing page:', error);
        }
    };

    handleGetLandingByUuid = async (uuid) => {
        try {
            this.loading = true;
            let response = await LandingPageAPI.getLandingPageByUuid(uuid);

            if(response?.error){
                return { error: response.error };
            }

            if(response.file){
                const responseLandingFile = new FileModel(response.file);

                response.file = responseLandingFile;
            }

            this.landingPage = new LandingPageModel(response);

            this.components = response.components.map((component) => new ComponentLPModel(component));

            this.loading = false;
        } catch (error) {
            console.log(error);
        }
    }

    handleGetAllLandingPages = async (page) => {
        try {
            this.loading = true;

            let params = {
                page: 0,
                size: 10,
            }


            if(page){
                params = {
                    page: page,
                    size: 10,
                }
            }

            const response = await LandingPageAPI.getAllLandingPages(params);

            if(response?.error){
                return { error: response.error };
            }

            this.landingPages = response.content.map((landingPage) => new LandingPageModel(landingPage));
            this.totalPages = response.totalPages;
            this.page = response.number;
            this.loading = false;
        } catch (error) {
            console.log(error);
        }
    }

    sanitizeFileName = (fileName) => {
        if (!fileName || fileName.trim() === "") {
            return fileName;
        }
    
        // Substitui caracteres restritos e reservados por hífen "-"
        let sanitizedFileName = fileName
            .replace(/[+&?=@#:,]/g, "-")     // Caracteres reservados no S3
            .replace(/\s+/g, "-")            // Substitui espaços por hífen "-"
            .replace(/[^a-zA-Z0-9._\-]/g, "-"); // Mantém apenas letras, números, ponto, sublinhado e hífen
    
        // Remove múltiplos hífens consecutivos
        sanitizedFileName = sanitizedFileName.replace(/-+/g, "-");
    
        // Remove hífen no início ou no final do nome do arquivo
        sanitizedFileName = sanitizedFileName.replace(/^-|-$/g, "");
    
        return sanitizedFileName;
    };

}

export default LandingPageStore;