import { extendObservable } from 'mobx';

import AutomakerApi from '../services/AutomakerAPI';
import AutomakerModel from '~/models/euro/EuroAutomakerModel';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import { removeItemList } from '~/helpers/utils/Functions';
import EuroModel from '~/models/euro/EuroModel';
import EuroSeriesModel from '~/models/euro/EuroSerieModel';

const initValues = {
  loading: false,
  automaker: undefined,
  automakers: [],
  series: [],
  serie: undefined,
  models: [],
  model: undefined,
  values: {},
  autoValues: {},
};

/**Store que manipula dados de Montadoras. */
class AutomakerStore {
  totalPages = 0;
  page = 0;
  size = 10;
  sort = 'name';

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  /**Cria nova instancia de Automaker */
  newAutomaker() {
    return new AutomakerModel();
  }
  newSerie() {
    return new EuroSeriesModel();
  }
  newModel() {
    return new EuroModel();
  }

  addSerie() {
    this.automaker.series = [...this.automaker.series, new EuroSeriesModel()];
    return this.automaker;
  }

  addModel(serieUuid) {
    this.automaker.series
      .filter((s) => s.uuid === serieUuid)[0]
      .models.push(new EuroModel());
    return this.automaker;
  }

  reset() {
    this.automaker = undefined;
    this.automakers = [];
  }

  /**Retorna um manucturer por uuid */
  async get(uuid) {
    this.loading = true;
    const response = await AutomakerApi.get(uuid);
    if (!response.error) {
      this.automaker = new AutomakerModel(response);
      return this.automaker;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca montadoras por nome. */
  async findByName(name, sort = this.sort) {
    const response = await AutomakerApi.getList({ name, sort });
    if (!response.error)
      this.automakers = response.content.map((m) => new AutomakerModel(m));
    return this.automakers;
  }

  /**Atualiza automaker atual*/
  async update(data) {
    this.loading = true;
    const response = await AutomakerApi.update(this.automaker.uuid, data);
    this.loading = false;
    if (!response.error)
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Editado Com sucesso!');
    else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Retorna um manucturer por uuid */
  async delete(uuid) {
    this.loading = true;
    const response = await AutomakerApi.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Excluído com sucesso!');
      const mDeleted = this.automakers.find((m) => m.uuid === uuid);
      removeItemList(this.automakers, mDeleted);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Salva novo montadora. */
  async save(data) {
    this.loading = true;
    const response = await AutomakerApi.save(data);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.error, response.error);
    else {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Cadastro efetuado com sucesso!'
      );
      this.automaker = undefined;
    }
    this.loading = false;
    return response;
  }

  /**Busca automakerers */
  async getList() {
    this.loading = true;
    const response = await AutomakerApi.getList({
      page: this.page,
      size: this.size,
      sort: this.short,
    });
    this.loading = false;
    if (!response.error) {
      this.totalPages = response.totalPages;
      this.page = response.number;
      this.automakers = response.content.map((m) => new AutomakerModel(m));
      return this.automakers;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Busca models */
  async getListModels() {
    this.loading = true;
    const response = await AutomakerApi.getListModels();
    this.loading = false;
    if (!response.error) {
      this.models = response.content.map((m) => new EuroModel(m));
      return this.models;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Busca models */
  async getListModelsBtName(name) {
    this.loading = true;
    const response = await AutomakerApi.getListModels({ name });
    this.loading = false;
    if (!response.error) {
      this.models = response.content.map((m) => new EuroModel(m));
      return this.models;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 20) {
    this.page = numPage;
    this.size = size;
    this.short = 'name';
    await this.getList();
    return true;
  }

  getListSelect() {
    const automakers = this.automakers.map((auto) => ({
      value: auto.uuid,
      label: auto.name,
    }));
    return automakers;
  }

  getListModelsSelect() {
    const models = this.models.map((m) => ({
      value: m.uuid,
      label: `${m.name} - Serie: ${m.serie.name} - Montadora: ${m.serie.maker.name}`,
    }));
    return models;
  }
}

export default AutomakerStore;
