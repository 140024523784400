import BaseModel from '../BaseModel';
import SportbayOrderModel from './SportbayOrderModel';
import LuminiProductModel from '../lumini/LuminiProductModel';

class SportbayRecomendedItemModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this['@class'] = 'br.com.stoom.sportbay.model.SportbayRecommendedItem';
    this.product = data.product && new LuminiProductModel(data.product);
    this.order = data.order && new SportbayOrderModel(data.order);
    this.observation = data.observation || '';
    this.unitPrice = data.unitPrice;
    this.amount = data.amount || 1;
  }
}

export default SportbayRecomendedItemModel;
