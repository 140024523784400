import { extendObservable } from 'mobx';
import { STATUS_HELPER } from '~/helpers/ToastHelper';
import { sortListBy } from '~/helpers/utils/Functions';
import WeightRangeModel from '~/models/WeightRangeModel';
import ZipCodeRangeModel from '~/models/ZipCodeRangeModel';
import ZipCodeRangeAPI from '../services/ZipCodeRangeAPI';
import UploadAPI from "../services/UploadAPI";

const initValues = {
  loading: false,
  zipCodeRange: new ZipCodeRangeModel(),
  zipCodeRanges: [],
  weightRange: new WeightRangeModel(),
  sortOrder: 'desc',
};

class ZipCodeRangeStore {
  totalPages = 0;
  page = 0;
  size = 10;
  sort = 'modified,desc';
  filter = '';

  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, { ...initValues });
  }

  /**Notificações. */
  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  reset() {
    this.zipCodeRanges = [];
    this.weightRange = new WeightRangeModel();
    this.zipCodeRange = new ZipCodeRangeModel();
    this.totalPages = 0;
    this.page = 0;
    this.sort = 'modified,desc';
    this.size = 10;
  }

  resetZipCodeRange() {
    this.zipCodeRange = new ZipCodeRangeModel();
  }

  updatePropClass(prop, value) {
    if (!this.zipCodeRange) {
      this.zipCodeRange = new ZipCodeRangeModel();
    }

    this.zipCodeRange[prop] = value;
  }

  async getPaginated() {
    this.loading = true;
    const response = await ZipCodeRangeAPI.getListServices({
      page: this.page,
      size: this.size,
      sort: this.sort,
    });
    if (!response.error) {
      this.zipCodeRanges = response.content.map(
        (s) => new ZipCodeRangeModel(s)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else
      this.notificationStore.notifyError(STATUS_HELPER.ERROR, response.error);

    this.loading = false;
    return response;
  }

  sortWeightsRange(prop) {
    this.sortOrder = this.sortOrder === 'desc' ? 'cres' : 'desc';
    const zipCodeRange = { ...this.zipCodeRange };
    this.zipCodeRange.weightRanges = sortListBy(
      zipCodeRange.weightRanges,
      prop,
      this.sortOrder
    );
  }

  async get(uuid) {
    this.loading = true;
    const response = await ZipCodeRangeAPI.get(uuid);
    if (!response.error) {
      this.zipCodeRange = new ZipCodeRangeModel(response);
    } else
      this.notificationStore.notifyError(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  async save() {
    this.loading = true;

    const response = await ZipCodeRangeAPI.save(this.zipCodeRange);
    if (!response.error) {
      this.zipCodeRange = new ZipCodeRangeModel();

      this.notificationStore.notify(
        STATUS_HELPER.INFO,
        'Faixa de frete cadastrada.'
      );
    } else {
      this.notificationStore.notifyError(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  async update() {
    this.loading = true;
    const response = await ZipCodeRangeAPI.update(
      this.zipCodeRange.uuid,
      this.zipCodeRange
    );
    if (!response.error) {
      this.zipCodeRange = new ZipCodeRangeModel();
      this.notificationStore.notify(
        STATUS_HELPER.INFO,
        'Alterações gravadas com sucesso.'
      );
    } else {
      this.notificationStore.notifyError(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  async delete(uuid) {
    this.loading = true;
    const response = await ZipCodeRangeAPI.delete(uuid);
    if (!response.error) {
      this.notificationStore.notify(
        STATUS_HELPER.INFO,
        'Faixa de frete excluída com sucesso'
      );
    } else {
      this.notificationStore.notifyError(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  /**Retorna lista para uso no select */
  getListSelect(zipCodeRanges = this.zipCodeRanges) {
    return zipCodeRanges.map((zipCodeRange) => ({
      value: zipCodeRange.uuid,
      label: zipCodeRange.name,
    }));
  }

  async searchByName(inputValue, size = 12) {
    this.page = 0;
    this.sort = 'name';
    this.filter = inputValue;
    this.size = size;
    await this.getPaginated();
    return this.getListSelect();
  }

  async setPage(numPage, size = 10, sort) {
    this.page = numPage;
    this.size = size;

    if (!this.sort) {
      this.sort = 'modified,desc';
    }

    if (sort) {
      this.sort = sort;
    }

    return await this.getPaginated();
  }

  async sendCSV (formData) {
    try {
      this.loading = true;
      const response = await UploadAPI.sendZipCodeCSV(formData);
      this.loading = false;
      this.getPaginated();
      if (!response.data.error && !response.error) {
          this.notificationStore.notify(STATUS_HELPER.SUCCESS, response.data.infos[response.data.infos.length - 1].message);
          return;
      }
    } catch (err) {
        this.notificationStore.notifyError(STATUS_HELPER.ERROR, 'Erro ao enviar csv de faixa cep');
        this.loading = false;
        return;
    }
  }
}

export default ZipCodeRangeStore;
