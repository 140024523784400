
import SportbayOrderStatusModel from './SportbayOrderStatusModel';
import BaseModel from '../BaseModel';

class SportbayShippingService extends BaseModel {
    constructor(data = {}) {
        super(data);
        this["@class"] = "br.com.stoom.sportbay.model.SportbayShippingService";
        this.name = data.name
        this.description = data.description
        this.nextStatus = new SportbayOrderStatusModel(data.nextStatus);
        this.shippingClasses = data.shippingClasses && data.shippingClasses.map(shipping => ({ value: shipping.uuid, label: shipping.name }));
    }

    setStatusName(statusName) {
        this.nextStatus.statusName = statusName;
    }
}

export default SportbayShippingService;