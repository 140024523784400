import { action, decorate, extendObservable } from 'mobx';
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import SportbayAuctionModel from "~/models/sportbay/SportbayAuctionModel";
import AuctionAPI from "~/services/AuctionAPI";

const initValues = {
    totalPages : 0,
    page : 0,
    size : 10,
    status : false,
    active : false,
    loadingSearchProduct : false,
    loading : false,
    toogleActive : false,
    isVisibleProductSelected : false,
    selectedPrducts : [],
    auction : {},
    auctions : [],
    filter_name: '',
    filter_lote: '',
    filter_sku: '',
    filter_status: undefined,
    filter_active: undefined,
    filter_withBid: undefined,
    info:{},
  };

class AuctionStore {
    totalPages = 0;
    page = 0;
    size = 15;
    status = false;
    active = false;
    sort = 'name';
    loadingSearchProduct = false;
    loading = false;
    toogleActive = false;
    isVisibleProductSelected = false;
    selectedProducts = [];
    auction = {};
    auctions = [];
    filter_lote = '';
    filter_name = '';
    filter_sku = '';
    filter_status = undefined;
    filter_active = undefined;
    filter_withBid = undefined;
    info = [];

    constructor(rootStore) {
      this.rootStore = rootStore;
      extendObservable(this, { ...initValues });
      this.toastHelper = new ToastHelper();
      this.auction = new SportbayAuctionModel();
    }

    reset() {
        this.auction = new SportbayAuctionModel();
        this.totalPages = 0;
        this.page = 0;
        this.size = 10;
        this.sort = 'name';
        this.term = '';
        this.isVisibleProductSelected = false;
        this.auctions = [];
        this.filter_lote = '';
        this.filter_name = '';
        this.filter_sku = '';
        this.filter_status = undefined;
        this.filter_active = undefined;
        this.filter_withBid = undefined;
    }

    clearFilters = () => {
        this.filter_name = '';
        this.filter_status = undefined;
        this.filter_sku = '';
        this.filter_lote = '';
        this.filter_active = undefined;
        this.filter_withBid = undefined;
    }

    setFilterName(data) {
        this.filter_name = data;
    }

    setFilterLote(data) {
        this.filter_lote = data;
    }

    setFilterSku(data) {
        this.filter_sku = data;
    }

    setFilterStatus(data) {
        this.filter_status = data;
    }

    setFilterActive(data) {
        this.filter_active = data;
    }

    setFilterWithBid(data) {
        this.filter_withBid = data;
    }

    async searchByname(inputValue, size = 10) {
        this.page = 0;
        this.sort = 'name';
        this.filter_name = inputValue;
        this.size = size;

        return await this.getListByType(inputValue);
    }

    async searchByLote(inputValue, size = 10) {
        this.page = 0;
        this.sort = 'lote';
        this.filter_lote = inputValue;
        this.size = size;

        return await this.getListByType(inputValue);
      }

    async searchBySku(inputValue, size = 10) {

        this.page = 0;
        this.sort = 'skuCode';
        this.filter_sku = inputValue;
        this.size = size;

        return await this.getListByType(inputValue);
    }

    async searchByStatus(inputValue, size = 10) {
        this.page = 0;
        this.sort = 'status';
        this.filter_status = inputValue;
        this.size = size;

        return await this.getListByType(inputValue);
    }

    async searchByActive(inputValue, size = 10) {
        this.page = 0;
        this.sort = 'active';
        this.filter_active = inputValue;
        this.size = size;

        return await this.getListByType(inputValue);
    }

    async searchByWithBid(inputValue, size = 10) {
        this.page = 0;
        this.sort = 'withBid';
        this.filter_withBid = inputValue;
        this.size = size;

        return await this.getListByType(inputValue);
    }

    /**Busca todos os leilões */
    async getListByType() {
        this.loading = true;
        let search = '';

        if (this.filter_name)
        search = `name=${this.filter_name}&`;
        else{search = `name=&`}
        if (this.filter_lote)
        search = `lote=${this.filter_lote}&${search}`;
        else{search = `${search}lote=&`}
        if (this.filter_sku)
        search = `sku=${this.filter_sku}&${search}`;
        else{search = `${search}sku=&`}
        if (this.filter_status)
        search = `status=${this.filter_status}&${search}`;
        else{search = `${search}status=&`}
        if (this.page)
        search = `page=${this.page}&${search}`;
        else{search = `${search}page=0`}
        if (this.filter_active !== undefined)
        search = `active=${this.filter_active}&${search}`;
        else{search = `${search}active=&`}
        if (this.filter_withBid !== undefined)
        search = `withBid=${this.filter_withBid}&${search}`;
        else{search = `${search}withBid=`}

        const response = await AuctionAPI.listByType(search);

        if(!response.error){
            this.auctions = response

            this.totalPages = response.totalPages;
            this.page = response.number;
            this.loading = false;
        } else{
            this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
            this.clearFilters()
            this.getListAuction(0,10)
        }
    }

    /**Cria novo leilão */
    createEmptyAuction() {
        this.auction = undefined;
        this.auction = new SportbayAuctionModel();
    }

    /**Função que atribui o valor do campo no model */
    handleAuctionChange(prop, value) {
        const { auction } = this;

        if (prop === 'active') {
        this.toogleActive = value;
        }

        auction[prop] = value;

        this.auction = new SportbayAuctionModel(auction);
    }

    async getListAuction (page, size) {
        this.loading = true;

        const response = await AuctionAPI.getAuctions(page, size, this.sort)
        if(!response.error){
            this.auctions = response

            this.totalPages = response.totalPages;
            this.page = response.number;
        } else{
            this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
        }

        this.loading = false;
    }

    async getAuctionByUuid (uuid) {
        /*Essa logica verifica a cada 3 segundos se teve atualização em lances quando o Leilão estiver ONLINE */
        return new Promise((resolve, reject) => {
            const intervalId = setInterval(async () => {
              try {
                const response = await AuctionAPI.getAuctionByUuid(uuid);

                if (!response.error) {
                  if (!this.auction || (JSON.stringify(this.auction?.auctionBids) !== JSON.stringify(response.auctionBids)) || (JSON.stringify(this.auction?.currentBid) !== JSON.stringify(response.currentBid))) {
                    this.auction = response;
                    resolve(this.auction);
                  }
                } else {
                  reject(response.error);
                }

                if (this.auction && (this.auction.status === "FINISHED" || this.auction.status === "CREATED")) {
                  clearInterval(intervalId);
                }

              } catch (error) {
                reject(error);
              }
            }, 3000);
          });
    }

    async save() {
        this.loading = true;

        const data = JSON.stringify(this.auction);

        let response = await AuctionAPI.createAuction(data);

        if(!response.error){
            this.createEmptyAuction();
            this.toastHelper.notify(STATUS_HELPER.INFO, 'Item do leilão criado com sucesso!');
        }else{
            this.toastHelper.notify(
                STATUS_HELPER.ERROR,
                response.error
            )
        }
        this.loading = false;
        return response;

    }

    async changeStatusAuction (uuid, status) {
        const newStatus = !status;
        const response = await AuctionAPI.changeActiveStatusAuction(uuid, newStatus)

        if(this.filter_active){
          this.totalPages = response.totalPages;
          this.page = response.number;
          return this.getListByType()
        }

        if(!response.error){
            this.toastHelper.notify(STATUS_HELPER.INFO, "Status alterado com sucesso");
            this.totalPages = response.totalPages;
            this.page = response.number;
            await this.getListAuction();
        }else{
            this.toastHelper.notify(
                STATUS_HELPER.ERROR,
                response.error
            )
        }
        return response;
    }

    async sendMessageBid(statusMessage, descMessage) {
        let uuid = this.auction.uuid;

        const data = {
            status: statusMessage,
            description : descMessage,
        };

        const response = await AuctionAPI.createBidMessage(uuid, data);

        if(!response.error){
            this.toastHelper.notify(STATUS_HELPER.INFO, "Mensagem enviada com sucesso");
        }else{
            this.toastHelper.notify(
                STATUS_HELPER.ERROR,
                response.error
            )
        }
        return response;
    }

    async changeStatus (status) {
        let uuid = this.auction.uuid;

        const response = await AuctionAPI.changeStatus(uuid, status);

        if(!response.error){
            this.toastHelper.notify(STATUS_HELPER.INFO, "Ação concluida!");
            /*Verifica quando muda o status para online para atualizar a chamada */
            if(response.status === "ONLINE"){
                this.getAuctionByUuid(uuid)
            }
        }else{
            this.toastHelper.notify(
                STATUS_HELPER.ERROR,
                response.error
            )
        }
    }

    async setPage(numPage, size = 10) {
        this.page = numPage;
        this.size = size;

        this.getListByType()
    }

    async recreateAuctionPayment() {
        this.loading = true;
        let uuid = this.auction.uuid;

        const response = await AuctionAPI.recreatePayment(uuid)

        if(!response.error){
            this.toastHelper.notify(STATUS_HELPER.INFO, "Pedido Recriado!");
        }else{
            this.toastHelper.notify(
                STATUS_HELPER.ERROR,
                response.error
            )
        }
        this.loading = false

    }

    async auctionStatusCreate(data) {
        this.loading = true;

        const response = await AuctionAPI.auctionStatusConfig(data)

        if(!response.error){
            this.toastHelper.notify(STATUS_HELPER.INFO, "Erro ao fazer ação!");
        }else{
            this.toastHelper.notify(
                STATUS_HELPER.ERROR,
                response.error
            )
        }
        this.loading = false;
    }

    async saveAuctionInfo(data) {
        this.loading = true;

        const response = await AuctionAPI.auctionStatusConfig(data)

        if(!response.error){
            this.loading = false;
            return response

        }else{
            this.toastHelper.notify(
                STATUS_HELPER.ERROR,
                response.error
            )

            this.loading = false;
        }
    }

    async getAuctionInfos() {
        this.loading = true;

        const response = await AuctionAPI.getAucionInfos();

        if(!response.error){
            this.list = response

        } else{
            this.toastHelper.notify(
                STATUS_HELPER.ERROR,
                response.error
            )

            this.loading = false;
        }

        this.loading = false;
    }

    async setAuctionLive() {
        let uuid = this.auction.uuid;
        const response = await AuctionAPI.activeAuctionLive(uuid)

        if(!response.error){
            this.toastHelper.notify(STATUS_HELPER.INFO, "Produto esta pronto para o ao vivo!");
            return response
        }else{
            this.toastHelper.notify(
                STATUS_HELPER.ERROR,
                "Houve um erro, leilão já finalizado ou leilão ainda não esta online.",
                5000
            )
        }
    }
}

export default AuctionStore;
