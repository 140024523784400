import BaseModel from './BaseModel';
import BannerFileModel from './BannerFileModel';
import MerchantComponentModel from "~/models/MerchanlComponentModel";

/**Modelo banner */
class BannerModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.tag = data.tag;
    this.nameTag = data.nameTag;
    this.categoryUUID = data.categoryUUID;
    this.brandUUID = data.brandUUID;
    this.active = data.active || false;
    this.responsive = data.responsive || false;
    const processedMerchants = data.merchants
      ? data.merchants.map((merchant,index) => {
        return {
          ordering: index,
          merchantUuid: merchant.uuid,
          ...merchant
        };
      })
      : [];

    this.merchants = processedMerchants.map((merchant) => new MerchantComponentModel(merchant));
    this.files = data.files
      ? data.files.map((file) => new BannerFileModel(file))
      : [];
  }
}

export default BannerModel;
