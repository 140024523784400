
import BaseModel from '../BaseModel';
import EuroShippingService from './EuroShippingService';
import EuroShippingClass from './EuroShippingClass';

class EuroShippingDetails extends BaseModel {
    constructor( data = {} ) {
        super(data);
        this["@class"] = "br.com.stoom.eurobrake.model.EuroShippingDetails";
        this.optionName = data.optionName;
        this.shippingPrice = data.shippingPrice;
        this.shippingDiscount = data.shippingDiscount;
        this.shippingTotal = data.shippingTotal;
        this.service = new EuroShippingService(data.service);
        this.shippingClass = new EuroShippingClass(data.shippingClass);
    }
}

export default EuroShippingDetails;