import MerchantModel from './SportbayMerchantModel';
import AccountModel from '../AccountModel';

class SportbayAccountModel extends AccountModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    if (data.merchant) { this.merchant = new MerchantModel(data.merchant); }
  }
}

export default SportbayAccountModel;
