import { extendObservable } from 'mobx';

import ApplicationAPI from '../services/ApplicationAPI';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import { removeItemList } from '~/helpers/utils/Functions';
import EuroApplicationModel from '~/models/euro/EuroApplicationModel';
import EuroModel from '~/models/euro/EuroModel';

const initValues = {
  loading: false,
  application: undefined,
  applications: [],
  models: [],
};

/**Store que manipula dados de aplicações. */
class ApplicationStore {
  totalPages = 0;
  page = 0;
  size = 10;
  short = 'name';

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  new() {
    this.application = new EuroApplicationModel();
    this.models = [];
  }

  reset() {
    this.application = undefined;
    this.models = [];
  }

  addModel(data) {
    if (this.application.models.filter((m) => m.uuid !== data.value))
      this.application.models.push(new EuroModel({ uuid: data.value }));
  }

  removeModel(uuid) {
    this.application.models = this.application.models.filter(
      (m) => m.uuid !== uuid
    );
    this.models = this.getModelsSelect(this.application.models);
  }

  /**Atualiza uma propriedade do application*/
  updateProp(prop, value) {
    const application = this.application;
    application[prop] = value;
    this.application = new EuroApplicationModel(application);
  }

  /**Retorna um manucturer por uuid */
  async get(uuid) {
    this.loading = true;
    const response = await ApplicationAPI.get(uuid);
    if (!response.error) {
      this.application = new EuroApplicationModel(response);
      this.models = this.getModelsSelect(this.application.models);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca aplicações por nome. */
  async findByName(name) {
    const response = await ApplicationAPI.getList({ name, sort: 'name' });
    if (!response.error)
      this.applications = response.content.map(
        (m) => new EuroApplicationModel(m)
      );
    return this.applications;
  }

  /**Atualiza application atual*/
  async update() {
    this.loading = true;
    this.application.models = this.application.models.map(
      (i) => new EuroModel({ uuid: i.uuid })
    );
    const data = JSON.stringify(this.application);
    const response = await ApplicationAPI.update(this.application.uuid, data);
    this.loading = false;
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Retorna um manucturer por uuid */
  async delete(uuid) {
    this.loading = true;
    const response = await ApplicationAPI.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Excluído com sucesso!');
      const mDeleted = this.applications.find((m) => m.uuid === uuid);
      removeItemList(this.applications, mDeleted);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Salva novo fornecedor. */
  async save() {
    this.loading = true;
    const data = JSON.stringify(this.application);
    const response = await ApplicationAPI.save(data);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.error, response.error);
    else {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Cadastro efetuado com sucesso!'
      );
      this.application = undefined;
    }
    this.loading = false;
    return response;
  }

  /**Busca applicationers */
  async getList() {
    this.loading = true;
    const response = await ApplicationAPI.getList();
    this.loading = false;
    if (!response.error) {
      this.totalPages = response.totalPages;
      this.page = response.number;
      this.applications = response.content.map(
        (m) => new EuroApplicationModel(m)
      );
      return this.applications;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 20, sort) {
    this.page = numPage;
    this.size = size;

    sort ? (this.sort = sort) : (this.sort = 'name');

    await this.getList();
    return true;
  }

  /**Retorna lista de categorias para uso no select */
  getListSelect(applications = this.applications) {
    return applications.map((application) => ({
      value: application.uuid,
      label: application.getNameSelect,
    }));
  }

  /**Retorna lista de categorias para uso no select */
  getModelsSelect(models) {
    return models.map((m) => ({ value: m.uuid, label: m.getNameSelect }));
  }
}

export default ApplicationStore;
