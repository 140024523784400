import CustomerModel from '../CustomerModel';

class LuminiCustomerModel extends CustomerModel {
  constructor(data = {}) {
    super(data);
    this['@class'] = 'br.com.stoom.lumini.model.LuminiCustomer';
    this.stateRegistration = data.stateRegistration;
    this.exempt = data.exempt;
    if(this.exempt) this.stateRegistration = "";
  }
}

export default LuminiCustomerModel;
