import BaseAPI, { URLS } from './BaseAPI';

class ComparativeAPI {
    static _exception(e, message) {
        console.log(e);
        return { error: message };
    }

    /**
     * Cria novo comparativo
     * @param  {String} classUuid - uuid do comparativo;
     * @param  {String} groupUuid - uuid do grupo;
     * @param  {Object} value - nome da variação
     */
    static async createComparative(data) {
        try {
            const response = await BaseAPI.post(URLS.COMPARATIVE, data);
            if (response.status === 201) return response.data;
            return { error: 'Erro inesperado ao cadastrar comparativo' };
        } catch (e) {
            return this._exception(e, 'Falha ao cadastrar comparativo');
        }
    }

    /**
   * Request que lista os comparativos
   * @param {Object} params São os parametros da busca, por exemplo pagina e items por página;
   */
    static async list(params = {}) {
        try {
            const response = await BaseAPI.get(URLS.COMPARATIVE, params);
            if (response.status === 200) return response.data;
            return { error: 'Erro inesperado ao buscar kits' };
        } catch (e) {
            return this._exception(e, 'Falha ao listar kits');
        }
    }

    /**
   * @param  {string} uuid Uuid do comparativo que será editado
   * @param {object} data dados do comparativo que será editado
   */
    static async updateComparative(uuid, data) {
        try {
            const response = await BaseAPI.put(`/manager/feature/${uuid}`, data);
            if (response.status === 200) return response.data;
            return { error: 'Falha ao atualizar comparativo' };
        } catch (e) {
            console.log(e);
            return { error: 'Falha ao atualizar comparativo' };
        }
    }

    /**
   * @description Request para delete de comparativo
   * @param {string} uuid valor que deseja buscar
   * * @param {string} groupUuid valor que deseja buscar
   */
    static async deleteComparative(uuid) {
        try {
            const response = await BaseAPI.delete(`${URLS.COMPARATIVE}/${uuid}`);
            if (response.status === 200) return response.data;
            return { error: 'Erro ao deletar o atributo' };
        } catch (e) {
            return this._exception(e, 'Falha ao deletar o atributo');
        }
    }


    //
    static async getComparativeByUuid(uuid) {
        try {
            const response = await BaseAPI.get(`${URLS.COMPARATIVE}/${uuid}`);
            if (response.status === 200) return response.data;
        } catch (e) {
            console.log(e.message);
        }
        return { error: 'Falha ao sincronizar com ERP' };
    }
}

export default ComparativeAPI;
