import {extendObservable} from 'mobx';

import AdministratorAPI from '../services/AdministratorAPI';
import ToastHelper, {STATUS_HELPER} from '~/helpers/ToastHelper';

import {cryptoPass} from '~/helpers/utils/Functions';
import EuroAdministratorModel from '~/models/euro/EuroAdministratorModel';
import StorageUtil, {KEYS} from '~/helpers/utils/StorageUtil';
import AdministratorMerchant from "~/models/AdministratorMerchant";

class AdministradorStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      administrator: undefined,
      administrators: [],
      staffs: [],
      loading: false,
      superAdmin: false,
      totalPages: 0,
      size: 20,
      page: 0,
      sort: 'name',
      filter_nameAd: "",
      filter_userAd: "",
      filter_cnpjAd: "",
      filter_statusAd: "",
      filter_merchant_uuidAd: "",
    });
  }

  clearFilters = () => {
    this.filter_nameAd = '';
    this.filter_statusAd = undefined;
    this.filter_cnpjAd = '';
    this.filter_userAd = '';
    this.filter_merchant_uuidAd = "";
  }

  // setFilterUser(data) {
  //   this.filter_userAd = data;
  // }

  // setFilterCnpj(data) {
  //   this.filter_cnpjAd = data;
  // }

  setFilterName(data) {
    this.filter_nameAd = data;
  }
  setFilterStatus(data) {
    this.filter_statusAd = data;
  }

  /** get Current user. */
  get user() {
    return this.userStore.user;
  }

  get userStore() {
    return this.rootStore.usersStore;
  }

  /**Retorna merchanto do usuário */
  get userMerchant() {
    return this.userStore.userMerchant;
  }

  get isSuperAdmin() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === 'SUPERADMIN' || currentAuth === 'STOOM';
  }

  reset() {
    this.administrator = undefined;
  }

  // async searchByUserAd(inputValue, size = 10) {
  //   this.page = 0;
  //   this.sort = 'user';
  //   this.filter_userAd = inputValue;
  //   this.size = size;
  //
  //   return await this.getListByType(inputValue);
  // }

  // async searchByCnpjAd(inputValue, size = 10) {
  //
  //   this.page = 0;
  //   this.sort = 'cnpj';
  //   this.filter_cnpjAd = inputValue;
  //   this.size = size;
  //
  //   return await this.getListByType(inputValue);
  // }

  async searchByNameAd(inputValue, size = 15) {
    this.page = 0;
    this.sort = 'name';
    this.filter_nameAd = inputValue;
    this.size = size;

    return await this.getListByType(inputValue);
  }

  async searchByStatusAd(inputValue, size = 15) {
    this.page = 0;
    this.sort = 'name';
    this.filter_statusAd = inputValue;
    this.size = size;

    return await this.getListByType(inputValue);
  }
  async searchByMerchant(merchantUuid ,size = 15) {
    this.page = 0;
    this.sort = 'name';
    this.size = size;
    this.filter_merchant_uuidAd = merchantUuid;
    await this.getListByType(this.filter_merchant_uuidAd);
    return this.getListSelect();
  }

  async getListByType() {
    this.loading = true;

    let searchParams = "";
    if (this.filter_nameAd)
      searchParams = `name:*${this.filter_nameAd}*,`;
    else {searchParams = `name,`}
    if (this.filter_userAd) {
      searchParams = (`User:${this.filter_userAd},${searchParams}`);
    } else  {searchParams = `${searchParams}User,`}
    if (this.filter_statusAd === "true") {
      searchParams = `active:true,${searchParams}`;
    } else if (this.filter_statusAd === "false") {
      searchParams = `active:false,${searchParams}`;
    } else {
      searchParams = `${searchParams}active,`;
    }
    if (this.filter_merchant_uuidAd) {
      searchParams = (`merchant_uuid:${this.filter_merchant_uuidAd},${searchParams}`);
    }else  {searchParams = `${searchParams}merchant_uuid,`}

    const response = await AdministratorAPI.listByType(searchParams,this.page, this.size, this.sort);
    if (!response.error) {
      if (response.content && response.content.length > 0) {
        this.totalPages = response.totalPages;
        this.page = response.number;
        this.administrators = response.content.map(
          (usr) => new EuroAdministratorModel(usr)
        );

      } else {
        this.toastHelper.notify(
          STATUS_HELPER.ERROR,
          "Nenhum encontrado.",
          2000
        )
        this.totalPages = response.totalPages;
        this.page = response.number;
        this.loading  = false
        return this.administrators = []
      }
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        "Falha ao listar administradores",
        2000
      )
      this.clearFilters()
      await this.getList()
      this.loading = false
    }
    this.loading = false;
  }


  /**Atualiza uma propriedade do novo adminstrator*/
  async updatePropAdministrator(prop, value) {
    const account = this.administrator
      ? this.administrator
      : new EuroAdministratorModel();
    switch (prop) {
      case 'username':
        account.user[prop] = value;
        break;
      case 'email':
        account.user[prop] = value;
        break;
      case 'pass':
        account.user.password = await cryptoPass(value);
        break;
      case 'merchant':
        account.merchant = value;
        break;
      case 'merchants':
        account.merchants = value?.map((item) => new AdministratorMerchant(item));
        break;
      case 'owner':
        this.administrator.owner = value === 'true' ? true : false;
        break;
      default:
        account[prop] = value;
    }
    this.administrator = new EuroAdministratorModel(account);
  }

  /**Cria um novo usuário adminin */
  async createAdministrator() {
    this.loading = true;
    //if (!this.isSuperAdmin) this.administrator.merchant = this.userMerchant;
    const data = JSON.stringify(this.administrator);
    const response = await AdministratorAPI.createAdministrator(data);
    this.loading = false;
    if (!response.error) {
      this.administrator = new EuroAdministratorModel();
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Usuário criado com sucesso.'
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 15, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = 'name';

    sort ? (this.sort = sort) : (this.sort = 'name');

    if(this.filter_nameAd !== "" || this.filter_merchant_uuidAd !== ""){
      await this.getListByType();
    }else{
   await this.getList()
    return true;
    }
  }

  /**Busca todos os usuarios addmins */
  async getList(size = 15, page = this.page, sort = this.sort) {
    this.loading = true;
    const response = await AdministratorAPI.getAllAdministrators({
      size,
      page,
      sort,
    });
    this.loading = false;
    if (response.error) return [];
    this.administrators = response.content.map(
      (usr) => new EuroAdministratorModel(usr)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  /**
   * Busca todas as informações de um usuario administrador.
   * @param  {string} userName
   */
  async get(userName) {
    this.loading = true;
    const response = await AdministratorAPI.getUserAdmin(userName);
    this.loading = false;
    if (!response.error) {
      this.administrator = new EuroAdministratorModel(response);
      return this.administrator;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**
   * Busca todas as informações de um usuario administrador.
   * @param  {string} userName
   */
  async getAdminLogin(user) {
    this.administrator = new EuroAdministratorModel(user);
    this.superAdmin = this.administrator.isSuperAdmin;
    return this.administrator;
  }

  /**Busca um usuário customer por um parametro. */
  async getAdminByQuery(value, param = 'uuid') {
    this.loading = true;
    const response = await AdministratorAPI.getAdminBy(param, value);
    if (!response.error) {
      this.administrator = new EuroAdministratorModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca um usuário customer por um parametro. */
  async update() {
    this.loading = true;

    // o Kernel barra se o password for not empty ou not null, porem no update ele nao usa o password para nada. Tratativa necessaria para o update fluir.
    this.administrator.user.password = 'temp';

    const data = JSON.stringify(this.administrator);
    const response = await AdministratorAPI.updateAdministrator(
      this.administrator.uuid,
      data
    );
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Informações atualizadas com sucesso!'
      );
      this.administrator = new EuroAdministratorModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Deleta administrador */
  async delete(uuid) {
    this.loading = true;
    const response = await AdministratorAPI.deleteAdministrator(uuid);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Administrador excluído com sucesso!'
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  getListSelect(administrators = this.administrators) {
    return administrators
      .filter((a) => !a.isSuperAdmin)
      .map((a) => ({value: a.uuid, label: a.name}));
  }

  formatCNPJ(cnpj) {
    let formaterCnpj = cnpj
    if (typeof formaterCnpj === 'number') {
      formaterCnpj = cnpj.toString();
    }

    if (typeof formaterCnpj !== 'string' || cnpj.length !== 14) {
      return "erro no CNPJ.";
    }

    return (
      cnpj.substr(0, 2) + '.' +
      cnpj.substr(2, 3) + '.' +
      cnpj.substr(5, 3) + '/' +
      cnpj.substr(8, 4) + '-' +
      cnpj.substr(12, 2)
    );
  }
}

export default AdministradorStore;
