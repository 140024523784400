import BaseModel from "../BaseModel";

export default class EuroInstitutionalModel extends BaseModel {
    constructor( data = {} ) {
        super(data);
        this.name = data.name;
        this.slug = data.slug;
        this.text = data.text;
    }

}