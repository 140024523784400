import { extendObservable } from 'mobx';

import SupplierAPI from '../services/SupplierAPI';
import SupplierModel from '~/models/SupplierModel';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import { removeItemList } from '~/helpers/utils/Functions';
import FileModel from '~/models/FileModel';
import UploadAPI from '../services/UploadAPI';

const initValues = {
  loading: false,
  supplier: undefined,
  suppliers: [],
};

/**Store que manipula dados de fornecedores. */
class SupplierStore {
  totalPages = 0;
  page = 0;
  size = 10;
  short = 'name';

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  reset() {
    this.supplier = undefined;
  }

  /**Retorna instancia da notificationStore  */
  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Cria nova instancia de forncedores com dados do merchant */
  newSupplier() {
    const merchant = this.rootStore.usersStore.user.merchant;
    return new SupplierModel({ merchant });
  }

  /**Atualiza uma propriedade do supplier*/
  updateProp(prop, value) {
    const supplier = this.supplier ? this.supplier : this.newSupplier();
    supplier[prop] = value;
    this.supplier = new SupplierModel(supplier);
  }

  /**Retorna um manucturer por uuid */
  async get(uuid) {
    this.loading = true;
    const response = await SupplierAPI.get(uuid);
    if (!response.error) this.supplier = new SupplierModel(response);
    else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca fornecedors por nome. */
  async findByName(name) {
    const response = await SupplierAPI.getList({ name, sort: 'name' });
    if (!response.error)
      this.suppliers = response.content.map((m) => new SupplierModel(m));
    return this.suppliers;
  }

  /**Atualiza supplier atual*/
  async update() {
    this.loading = true;
    const data = JSON.stringify(this.supplier);
    let response;
    if (this.supplier.brandLogo && this.supplier.brandLogo.file) {
      response = await this.sendNewFiles(
        this.supplier.uuid,
        this.supplier.brandLogo
      );
      if (response.error) {
        this.toastHelper.notify(STATUS_HELPER.error, response.error);
        return response;
      }
    }
    response = await SupplierAPI.update(this.supplier.uuid, data);
    this.loading = false;
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);

    return response;
  }

  /**Retorna um manucturer por uuid */
  async delete(uuid) {
    this.loading = true;
    const response = await SupplierAPI.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Excluído com sucesso!');
      const mDeleted = this.suppliers.find((m) => m.uuid === uuid);
      removeItemList(this.suppliers, mDeleted);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Adiciona imagem ao produto */
  handleImages(tag, file) {
    if (!this.supplier) this.supplier = this.newSupplier();
    this.supplier.brandLogo = new FileModel({ file, ...file, metaTags: [tag] });
  }

  /**Salva novo fornecedor. */
  async save() {
    this.loading = true;
    let file = this.supplier.brandLogo;
    this.supplier.brandLogo = undefined;
    const data = JSON.stringify(this.supplier);
    let response = await SupplierAPI.save(data);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.error, response.error);
    else {
      response = await this.sendNewFiles(response.data.uuid, file);
      if (response.error)
        this.toastHelper.notify(STATUS_HELPER.error, response.error);
      else {
        this.toastHelper.notify(
          STATUS_HELPER.INFO,
          'Cadastro efetuado com sucesso!'
        );
        this.supplier = undefined;
      }
    }

    this.loading = false;
    return response;
  }

  /**Busca supplierers */
  async getList() {
    this.loading = true;
    const response = await SupplierAPI.getList({
      page: this.page,
      size: this.size,
      short: this.short,
    });
    this.loading = false;
    if (!response.error) {
      this.totalPages = response.totalPages;
      this.page = response.number;
      this.suppliers = response.content.map((m) => new SupplierModel(m));
      return this.suppliers;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 20) {
    this.page = numPage;
    this.size = size;
    this.size = 'name';
    await this.getList();
    return true;
  }

  /**Retorna lista de categorias para uso no select */
  getListSelect(suppliers = this.suppliers) {
    return suppliers.map((mSupplier) => ({
      value: mSupplier.uuid,
      label: mSupplier.name,
    }));
  }

  getSelectedSupplier(name, suppliers = this.suppliers) {
    //fazer pelo id
    const hasSelected = suppliers.find((s) => s.name === name);
    // const selected : { value: hasSelected.uuid, label: hasSelected.name}
    // return selected;
  }

  async sendNewFiles(uuid, newFile) {
    const response = await UploadAPI.uploadLogoSupplier(uuid, newFile);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'Falha anexar arquivos.');
    return response;
  }
}

export default SupplierStore;
