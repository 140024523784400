
import BaseModel from '../BaseModel';
import MerchantModel from '../MerchantModel';

/**
 * Tipo de variação (ex: cor)
 */
class SportBayComparativeModel extends BaseModel {
    constructor(data = {}) {
        super(data);
        this.uuid = data.uuid;
        this.created = data.created;
        this.filter = data.filter !== undefined ? data.filter : true;
        this.active = data.active !== undefined ? data.active : true;
        this.deleted = data.deleted;
        this.name = data.name;
        this.type = data.type;
        this.merchant = new MerchantModel(data.merchant);
    }

    get isValid() {
        if (!this.className || this.className.length < 3) return false;
        if (!this.displayString) return false;
        return true;
    }
}

export default SportBayComparativeModel;
