import CustomerModel from '../CustomerModel';

/**Modelo que guarda infos dos customers */
class SportBayCustomerModel extends CustomerModel {
    constructor(data = {}) {
        super(data);
        this['@class'] = 'br.com.stoom.sportbay.model.SportbayCustomer';
        this.stateRegistration = data.stateRegistration;
        this.exempt = data.exempt;
        this.verifyCode = data.verifyCode
        this.verifyEmail = data.verifyEmail
        if (this.exempt) this.stateRegistration = "";
    }
}

export default SportBayCustomerModel;
