import BaseModel from './BaseModel';
import FileModel from './FileModel';

export default class SupplierModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.name = data.name;
    this.brandLogo = data.brandLogo ? new FileModel(data.brandLogo) : undefined;
  }
}
