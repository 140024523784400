import BaseAPI, { URLS } from "./BaseAPI";

class PreOrderAPI {

    static _exception(e, message) {
        console.log(e);
        return { error: message }
    }

    /**
    *@param {preOrderUuid} preOrderUuid identificador de orçamento
    *@param {orderData} orderData Dados do novo pedido à partir do Orçamento.
    @PostMapping("/preorders/{uuid}/orders")
    */
    static async saveOrder(preOrderUuid, orderData) {
        try {
            const url = `${URLS.PRE_ORDERS}/${preOrderUuid}/orders`
            const response = await BaseAPI.post(url, orderData);
            if (response.status === 201) return response.data;
            return { error: 'Falha o criar um orçamento' };
        } catch (e) {
            return this._exception(e, 'Falha ao criar um orçamento');
        }
    }

     /**
    *@description Seta O vendedor no Customer caso o vendedor responda um pre-order em 
    * que o customer não tem administrador associado.
    *@param {preOrderUuid} preOrderUuid identificador do preOrder
    @PostMapping("/preorders/${preOrderUuid}/administrator")
    */
   static async setAdministrator(preOrderUuid) {
    try {
        const url = `/manager/preorders/${preOrderUuid}/administrator`
        const response = await BaseAPI.post(url);
        if (response.status === 200) return response.data;
        return { error: 'Erro ao adicionar vendedor' }
    } catch (e) {
        return this._exception(e, 'Erro ao adicionar vendedor.');
    }
}

    /**
    *@param {preOrderUuid} preOrderUuid identificador do orçamento
    @GetMapping("/preorders/{uuid}")
    */
    static async get(preOrderUuid) {
        try {
            const response = await BaseAPI.get(`${URLS.PRE_ORDERS}/${preOrderUuid}`);
            if (response.status === 200) return response.data;
            return { error: "Erro inexperado ao buscar orçamento" }
        } catch (e) {
            return this._exception(e, 'Erro ao buscar orçamento');
        }
    }

    /**
     * @param params parâmetros de paginaçao
    *Busca todos os preOrders que aguardam análise
    @GetMapping("/preorders/pendents")
    */
    static async getPendents(params) {
        try {
            const url = `${URLS.PRE_ORDERS}/pendents`
            const response = await BaseAPI.get(url, params);
            if (response.status === 200) return response.data;
            return { error: "Erro inexperado ao buscar orçamentos" }
        } catch (e) {
            return this._exception(e, 'Erro ao buscar orçamentos');
        }
    }

    /**
     * @param params parâmetros de paginaçao
    *Busca todos os preOrders
    @GetMapping("/preorders
    */
    static async getList(params) {
        try {
            const response = await BaseAPI.get(URLS.PRE_ORDERS, params);
            if (response.status === 200) return response.data;
            return { error: "Erro inexperado ao buscar orçamentos" }
        } catch (e) {
            return this._exception(e, 'Erro ao buscar orçamentos');
        }
    }

    /**
     * @param order_uuid Id do pedido
    *Busca a preOrder pelo order_uuid
    @GetMapping("/preorders/{order_uuid}/preorder")
    */
   static async getPreOrderByOrder(order_uuid) {
    try {
        const url = `${URLS.PRE_ORDERS}/${order_uuid}/preorder`
        const response = await BaseAPI.get(url);
        if (response.status === 200) return response.data;
        return { error: "Erro inexperado ao buscar orçamentos" }
    } catch (e) {
        return this._exception(e, 'Erro ao buscar orçamento');
    }
}
}

export default PreOrderAPI;