import BaseModel from "../BaseModel";


class SportbayAuctionModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.name = data.name;
    this.description = data.description;
    this.active = data.active;
    this.currentBid = data.currentBid;
    this.startingBid = data.startingBid;
    this.currentBid = data.currentBid;
    this.skuCode = data.skuCode;
    this.startDatetime = data.startDatetime;
    this.videoLink = data.videoLink;
    this.imageLink = data.imageLink;
    this.standardBids = data.standardBids;
    this.auctionProducts = data.auctionProducts;
    this.live = false;
   
  }
}

export default SportbayAuctionModel;