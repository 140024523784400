import BaseModel from '../BaseModel';
import SportbayFileModel from './SportbayFileModel';
import SportbayDiscountModel from './SportbayDiscountModel';
import SportbayProductKitModel from './SportbayProductKitModel';
import SportbayCategoryModel from './SportbayCategoryModel';
import { CategoriesEnum } from '~/helpers/utils/Enums';

class KitModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.name = data.name;
    this.price = data.price;
    this.status = data.status;
    this.description = data.description;
    this.categories = data.categories
      ? data.categories.filter((cat) => {
        if (cat.type === CategoriesEnum.VITRINE) {
          return new SportbayCategoryModel(cat);
        }
        return [];
      })
      : [];
    this.collections = data.categories
      ? data.categories.filter((cat) => {
        if (cat.type === CategoriesEnum.COLECAO) {
          return new SportbayCategoryModel(cat);
        }
        return [];
      })
      : [];
    this.files = data.files
      ? data.files.reduce((list, file) => {
        if (!list.some((lFile) => lFile.metaTags[0] === file.metaTags[0])) {
          list.push(new SportbayFileModel(file));
        }
        return list;
      }, [])
      : [];
    this.products = data.products
      ? data.products.map((prod) => new SportbayProductKitModel(prod))
      : [];
    this.itemsDiscount = data.itemsDiscount
      ? data.itemsDiscount.map((disc) => new SportbayDiscountModel(disc))
      : [];
    this.active = data.active;
    this.skuCode = data.skuCode;
    this.type = data.type;
  }

  getFile(tag) {
    return this.files.find(
      (file) => file.path && file.metatag === tag
    );
  }

  getFiles(tag) {
    return this.files.filter(
      (file) => file.path && file.metaTags.some((metaTag) => metaTag === tag)
    );
  }
}

export default KitModel;
