import BaseModel from './BaseModel';

class ImpostosFederalModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.uuid = data.uuid;
    this.created = data.created;
    this.modified = data.modified;
    this.active = data.active;
    this.deleted = data.deleted;
    this.nome = data.nome;
    this.descricao = data.descricao;
    this.aliquota = data.aliquota;
  }
}

export default ImpostosFederalModel;
