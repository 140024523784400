import BaseModel from './BaseModel';

class ItemMenuModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.title = '' || data.title;
    this.subTitle = '' || data.subTitle;
    this.icon = '' || data.icon;
    this.path = '' || data.path;
    this.component = data.component && data.component;
    this.props = data.props && data.props;
    this.subItems = data.subItems && data.subItems.map((sub) => new ItemMenuModel(sub));
  }
}

export default ItemMenuModel;
