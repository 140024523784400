import { extendObservable } from 'mobx';

import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import { removeItemList } from '~/helpers/utils/Functions';
import EuroInstitutionalModel from '~/models/euro/EuroInstitutionalModel';
import InstitucionalAPI from '../services/InstitucionalAPI';

const initValues = {
  loading: false,
  institutional: undefined,
  institutionals: [],
};

/**Store que manipula dados de institucionals. */
class InstitutionalStore {
  totalPages = 0;
  page = 0;
  size = 10;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  newInstitutional() {
    return new EuroInstitutionalModel();
  }

  /**Atualiza uma propriedade de Institucional*/
  updateProp(prop, value) {
    const institutional = this.institutional
      ? this.institutional
      : new EuroInstitutionalModel();
    institutional[prop] = value;
    this.institutional = new EuroInstitutionalModel(institutional);
  }

  /**Retorna um Institucional por uuid */
  async get(uuid) {
    this.loading = true;
    const response = await InstitucionalAPI.get(uuid);
    if (!response.error)
      this.institutional = new EuroInstitutionalModel(response);
    else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Atualiza Institucional atual*/
  async update() {
    this.loading = true;
    const data = JSON.stringify(this.institutional);
    const response = await InstitucionalAPI.update(
      this.institutional.uuid,
      data
    );
    this.loading = false;
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Retorna um Institucional por uuid */
  async delete(uuid) {
    this.loading = true;
    const response = await InstitucionalAPI.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Excluído com sucesso!');
      const mDeleted = this.institutionals.find((m) => m.uuid === uuid);
      removeItemList(this.institutionals, mDeleted);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Salva novo Institcional. */
  async save() {
    this.loading = true;
    const data = JSON.stringify(this.institutional);
    const response = await InstitucionalAPI.save(data);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.error, response.error);
    else {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Cadastro efetuado com sucesso!'
      );
      this.institutional = undefined;
    }
    this.loading = false;
    return response;
  }

  /**Busca Institcional */
  async getList() {
    this.loading = true;
    const response = await InstitucionalAPI.getList({
      page: this.page,
      size: this.size,
    });
    this.loading = false;
    if (!response.error) {
      this.totalPages = response.totalPages;
      this.page = response.number;
      this.institutionals = response.map((m) => new EuroInstitutionalModel(m));
      return this.institutionals;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Retorna lista de categorias para uso no select */
  getListSelect(institutionals = this.institutionals) {
    return institutionals.map((i) => ({ value: i.uuid, label: i.name }));
  }
}

export default InstitutionalStore;
