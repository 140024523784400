import { extendObservable, decorate, action } from 'mobx';

import PreOrderModel from '~/models/lumini/PreOrderModel';
import PreOrderAPI from '../services/PreOrderAPI';

import PageModel from '~/models/PageModel';
import { ORDER_STATUS } from '~/models/OrderStatusModel';
import OrderAPI from '../services/OrderAPI';

const initValues = {
  loading: false,
  preOrder: undefined,
  filterStatus: undefined,
  filterStaff: undefined,
  order: undefined,
  preOrders: [],
  dashBoard: [],
};

class PreOrderStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, initValues);
    this.pageData = new PageModel({ sort: 'created', size: 12 });
  }

  get orderStore() {
    return this.rootStore.orderStore;
  }

  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  setPageData(params) {
    this.pageData = new PageModel(params);
  }

  async setPage(newPage) {
    this.pageData.page = newPage;
    await this.getList();
    return true;
  }

  setSort(newSort) {
    this.pageData.sort = newSort;
  }

  get page() {
    return this.pageData.page;
  }

  get totalPages() {
    return this.pageData.totalPages;
  }

  reset() {
    this.preOrder = undefined;
    this.preOrders = [];
    this.pageData.reset();
  }

  /**Troca status do pedido em análise para carrinho. */
  async createCart() {
    const { order } = this.preOrder;
    if (order && order.uuid) {
      this.loading = true;
      const response = await this.orderStore.changeStatusOrder(
        ORDER_STATUS.CART,
        order.uuid,
        this.preOrder.customer.uuid
      );
      if (!response.error) this.get(this.preOrder.uuid);
      else {
        this.notificationStore.notifyError('Erro ao responder orçamento.');
      }
    } else {
      this.loading = true;
      const responseOrder = await this.orderStore.createOrder(
        this.preOrder.uuid
      );
      if (!responseOrder.error) {
        await this.get(this.preOrder.uuid);
        const response = await this.orderStore.changeStatusOrder(
          ORDER_STATUS.CART,
          this.preOrder.order.uuid,
          this.preOrder.customer.uuid
        );
        if (!response.error) this.get(this.preOrder.uuid);
        else {
          this.notificationStore.notifyError('Erro ao responder orçamento.');
        }
      } else {
        this.notificationStore.notifyError('Erro ao responder orçamento.');
      }
    }
    this.loading = false;
  }

  async getListByStatus(statusName) {
    this.filterStatus = statusName;
    return await this.getList(statusName);
  }

  async getPreOrderByStaff(uuidStaff, statusName = this.filterStatus) {
    this.filterStaff = uuidStaff;
    statusName === 'PENDENTS'
      ? await this.getPendents()
      : await this.getList(statusName);
    this.loading = true;
    if (uuidStaff !== 'ALL') {
      this.preOrders = this.preOrders.filter(
        (pO) =>
          pO.customer.administrator
          && pO.customer.administrator.uuid === uuidStaff
      );
    }
    this.loading = false;
  }

  async getPreOrderByStaffDashBoard(uuidStaff) {
    this.filterStaff = uuidStaff;
    await this.getDashBoard();
    this.loading = true;
    if (uuidStaff !== 'ALL') {
      this.preOrders = this.preOrders.filter(
        (pO) =>
          pO.customer.administrator
          && pO.customer.administrator.uuid === uuidStaff
      );
    }
    this.loading = false;
  }

  /**Busca orçamentos que o pedido tem status em análise.*/
  async getDashBoard() {
    await this.getList(ORDER_STATUS.ANALYSIS);
    this.dashBoard = this.preOrders;
    await this.getPendents(ORDER_STATUS.ANALYSIS);
    this.preOrders = [...this.preOrders, ...this.dashBoard];
  }

  /**Busca orçamentos que o pedido tem status em análise.*/
  async getAnalysis() {
    return this.getList(ORDER_STATUS.ANALYSIS);
  }

  /**Busca orçamentos que o pedido tem status em carrinho.*/
  async getCart() {
    return this.getList(ORDER_STATUS.CART);
  }

  /**Busca orçamentos que o pedido tem status em análise.*/
  async getShippingAnalysis() {
    return this.getList(ORDER_STATUS.SHIPPING_ANALYSIS);
  }

  /**Troca texto de um item  */
  async changeObservationItem(orderItem, textObservation) {
    this.loading = true;
    const response = await this.orderStore.changeObservationItem(
      this.preOrder.order.uuid,
      orderItem.uuid,
      textObservation
    );
    this.loading = false;
    return response;
  }

  /**Troca texto  */
  async changeMainObservation(orderUuid, preOrderUuid, textObservation) {
    this.loading = true;
    const updatePreOrder = await this.orderStore.changeMainObservation(
      orderUuid,
      preOrderUuid,
      textObservation
    );
    if (updatePreOrder) await this.get(preOrderUuid);
    this.loading = false;
  }

  /*Caso seja cliente novo seta o vendedor que responder  o Orçamento*/
  async setAdministrator(preOrder) {
    if (
      preOrder.customer.administrator === undefined
      || preOrder.customer.administrator === null
    ) {
      const response = await PreOrderAPI.setAdministrator(preOrder.uuid);
      if (response.error) this.loading = false;
    }
  }

  /**Cria novo pedido */
  async addNewOrderItem(
    preOrderUuid,
    product,
    preOrderItem,
    orderUuid,
    customerUuid
  ) {
    this.loading = true;
    const response = await this.orderStore.addNewOrderItem(
      preOrderUuid,
      product,
      preOrderItem,
      orderUuid,
      customerUuid
    );
    if (!response.error) await this.get(preOrderUuid);
    this.loading = false;
  }

  /**Adiciona novo item recomendado */
  async addProductRecomended(preOrderUuid, orderUuid, product) {
    this.loading = true;
    const response = await this.orderStore.addProductRecomended(
      orderUuid,
      product
    );
    if (!response.error) await this.get(preOrderUuid);
    this.loading = false;
    return response;
  }

  /**Adiciona novo item recomendado */
  async removeProductRecomended(preOrderUuid, orderUuid, product) {
    this.loading = true;
    const response = await this.orderStore.removeProductRecomended(
      orderUuid,
      product
    );
    if (!response.error) await this.get(preOrderUuid);
    this.loading = false;
    return response;
  }

  /**Remove produto do carrinho...*/
  async updateOrderItem(item, customerUuid, orderUuid) {
    this.loading = true;
    const response = await OrderAPI.addItems(customerUuid, orderUuid, [item]);
    if (!response.error) await this.get(this.preOrder.uuid);
    this.loading = false;
  }

  /**Remove produto do carrinho...*/
  async removeOrderItem(item, customerUuid, orderUuid) {
    this.loading = true;
    const response = await OrderAPI.removeItems(customerUuid, orderUuid, [
      item,
    ]);
    if (!response.error) await this.get(this.preOrder.uuid);
    this.loading = false;
    return response;
  }

  /**Busca todos pedidos do usuário */
  async getList(status = this.filterStatus) {
    this.loading = true;
    const response = await PreOrderAPI.getList({
      status,
      ...this.pageData.params,
    });
    const { administrator } = await this.rootStore.administratorStore;
    const isSuperAdmin = await administrator.isSuperAdmin;
    this.filterStatus = 'ALL';
    if (!response.error) {
      let listPreorders = response.content.map(
        (order) => new PreOrderModel(order)
      );
      if (!isSuperAdmin) {
        this.loading = true;
        const newList = listPreorders.filter((order) => {
          if (order.customer.administrator !== null) {
            return order.customer.administrator.uuid === administrator.uuid;
          }
          return order;
        });
        listPreorders = newList;
      }
      this.preOrders = listPreorders;
      if (this.filterStaff !== undefined && this.filterStaff !== 'ALL') {
        this.preOrders = this.preOrders.filter(
          (pO) =>
            pO.customer.administrator
            && pO.customer.administrator.uuid === this.filterStaff
        );
      }
      this.pageData.totalPages = response.totalPages;
      //this.pageData = new PageModel(...response.data, { sort: this.pageData.sort});
    } else this.notificationStore.notifyError(response.error);
    this.loading = false;
    return response;
  }

  /**Busca todos pedidos do usuário */
  async getPendents() {
    this.loading = true;
    this.preOrders = [];
    const response = await PreOrderAPI.getPendents(this.pageData.params);
    const { administrator } = await this.rootStore.administratorStore;
    const isSuperAdmin = await administrator.isSuperAdmin;
    this.filterStatus = 'PENDENTS';
    if (!response.error) {
      let listPreorders = response.content.map(
        (order) => new PreOrderModel(order)
      );
      if (!isSuperAdmin) {
        this.loading = true;
        const newList = listPreorders.filter((order) => {
          if (order.customer.administrator !== null) {
            return order.customer.administrator.uuid === administrator.uuid;
          }
          return order;
        });
        listPreorders = newList;
      }
      this.preOrders = listPreorders;
      if (this.filterStaff !== undefined && this.filterStaff !== 'ALL') {
        this.preOrders = this.preOrders.filter(
          (pO) =>
            pO.customer.administrator
            && pO.customer.administrator.uuid === this.filterStaff
        );
      }
    } else this.notificationStore.notifyError(response.error);
    this.loading = false;
    return response;
  }

  /**Busca pedido pelo pelo uuid */
  async get(uuid) {
    this.loading = true;
    const response = await PreOrderAPI.get(uuid);
    if (!response.error) {
      this.preOrder = new PreOrderModel(response);
      /* const order = await OrderAPI.getOrderUser(response.order.uuid, response.customer.uuid);
            if(!order.error) {
                this.order = new OrderModel(order)
            } */
    } else this.notificationStore.notifyError(response.error);
    this.loading = false;
    return response;
  }

  /**Busca orçamento pelo pelo order uuid */
  async getPreOrderByOrder(order_uuid) {
    this.loading = true;
    const response = await PreOrderAPI.getPreOrderByOrder(order_uuid);
    if (!response.error) {
      this.preOrder = new PreOrderModel(response);
    } else this.notificationStore.notifyError(response.error);
    this.loading = false;
    return response;
  }

  /**Busca pedido pelo pelo uuid */
  async cancel(uuid) {
    this.loading = true;
    const response = await PreOrderAPI.cancel(this.userUuid, uuid);
    if (!response.error) {
      this.preOrders = this.preOrders.filter((p) => p.uuid !== uuid);
    } else this.notificationStore.notifyError(response.error);
    this.loading = false;
    return response;
  }
}

export default PreOrderStore;

decorate(PreOrderStore, {
  getList: action,
  create: action,
  get: action,
});
