import BaseAPI, { URLS } from "./BaseAPI";

class SupplierAPI {

    /**Método util para retorno de erro */
    static _exception(e, message) {
        console.log(e);
        return { error: message }
    }

    /**
     * @param  {string} uuid - Uuid da fornecedor à ser procurada
     */
    static async get(uuid) {
        try {
            const response = await BaseAPI.get(`${URLS.SUPPLIER}/${uuid}`);
            if (response.status === 200) return response.data;
            const error = response.status === 404 ? "Fornecedor não encontrada" : "Erro inexperado ao buscar Fornecedor";
            return { error }
        } catch (e) {
            return this._exception(e, 'Erro ao buscar fornecedor');
        }
    }



    /**
     * @param  {string} uuid - Uuid da fornecedor à ser deletada
     */
    static async delete(uuid) {
        try {
            const response = await BaseAPI.delete(`${URLS.SUPPLIER}/${uuid}`);
            if (response.status === 204) return response.data;
            const error = response.status === 404 ? "Fornecedor não encontrada" : "Erro inexperado ao buscar Fornecedor";
            return { error }
        } catch (e) {
            return this._exception(e, 'Erro ao deletar Fornecedor');
        }
    }

    /**
     * @param  {string} uuid - Uuid da fornecedor à ser atualizada
     */
    static async update(uuid, data) {
        try {
            const response = await BaseAPI.put(`${URLS.SUPPLIER}/${uuid}`, data);
            if (response.status === 200) return response.data;
            const error = response.status === 404 ? "Fornecedor não encontrada" : "Erro inexperado ao atualizar Fornecedor";
            return { error }
        } catch (e) {
            return this._exception(e, 'Erro ao atualizar dados da indústria');
        }
    }

    /**
     * @param  {object} data - dados do novo fornecedor que será salvo
     */
    static async save(data) {
        try {
            const response = await BaseAPI.post(URLS.SUPPLIER, data);
            if (response.status === 201) return response;
            return { error: 'Erro inexperado ao cadastrar fornecedor' }
        } catch (e) {
            return this._exception(e, 'Falha ao cadastrar fornecedor');
        }
    }

    /**Busca lista de suppliers */
    static async getList(params) {
        try {
            const response = await BaseAPI.get(URLS.SUPPLIER, params);
            if (response.status === 200) return response.data;
            return { error: 'Erro inexperado ao buscar lista de suppliers' }
        } catch (e) {
            return this._exception(e, "Falha ao buscar lista de suppliers");
        }
    }

}
export default SupplierAPI;