import { extendObservable } from 'mobx';
import { isArrayEmpty } from '~/helpers/utils/Functions';
import BaseModel from './BaseModel';
import WeightRangeModel from './WeightRangeModel';

class ZipCodeRangeModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;

    this.startZipCode = data.startZipCode;
    this.endZipCode = data.endZipCode;
    this.free = data.free;
    this.deliveryTime = data.deliveryTime;
    this.deliveryTimeOriginal = data.deliveryTimeOriginal;
    this.shippingCompanyCode = data.shippingCompanyCode;
    this.shippingCompany = data.shippingCompany;
    this.typeShippingCompany = data.typeShippingCompany;
    this.name = data.name;
    this.correctedValue = data.correctedValue;
    this.distance = data.distance;
    this.additionalValueKg = data.additionalValueKg;
    this.active = data.active || false;

    extendObservable(this, {
      weightRanges: isArrayEmpty(data.weightRanges)
        ? []
        : data.weightRanges.map((m) => new WeightRangeModel(m)).sort(
          (a, b) => (a.startWeight > b.startWeight ? 1 : -1))
    });
  }
}

export default ZipCodeRangeModel;
