import BaseAPI, { URLS } from "./BaseAPI";

class MerchantAPI {

    /**Método util para retorno de erro */
    static _exception(e, message) {
        console.log(e);
        return { error: message }
    }

    /**
     * @param  {object} data - dados do novo Merchant que será salvo
     */
    static async save(data) {
        try {
            const response = await BaseAPI.post(URLS.MERCHANT, data);
            if (response.status === 201) return response;
            return { error: 'Erro inexperado ao cadastrar merchant' }
        } catch (e) {
            return this._exception(e, 'Falha ao cadastrar merchant');
        }
    }

    /**
     *
     * @param {string} uuid - uuid do merchant a ser deletado
     */
    static async deleteMerchant(uuid) {
      try {
        const response = await BaseAPI.delete(`${URLS.MERCHANT}/${uuid}`);
        if (response.status === 204) return response;
        return { error: 'Erro inesperado ao excluir merchant' };
      } catch (error) {
        return this._exception(error, 'Falha ao excluir merchant');
      }
    }

    /**Busca lista de merchants */
    static async getList(params) {
        try {
            const response = await BaseAPI.get(URLS.MERCHANT, params);
            if (response.status === 200) return response.data;
            return { error: 'Erro inexperado ao busca listas de merchant' }
        } catch(e) {
            return this._exception(e, "Falha ao buscar lista de merchants");
        }
    }

  static async listByType(search,page,size,sort) {
    try {
      const url = `${URLS.MERCHANT}?search=${search}&page=${page}&size=${size}&sort=${sort}`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar merchants' };
    } catch (e) {
      return this._exception(e, 'Falha ao listar merchants');
    }
  }

    /**Busca Merchant pelo uuid */
    static async getMerchantByUUID(uuid) {
        try {
            const response = await BaseAPI.get(`${URLS.MERCHANT}/${uuid}`);
            if (response.status === 200) return response.data;
        return { error: 'Erro inesperado ao buscar merchant' };
        } catch (e) {
            return this._exception(e, 'Falha ao buscar merchant');
        }
    }

    /**
     * Atualizada campos de um merchant.
     * @param  {Object} data Dados a serem atualizados
     */
    static async update(uuid, data) {
      try {
            const response = await BaseAPI.put(`${URLS.MERCHANT}/${uuid}`, data);
            if (response.status === 200) return response;
            return { error: 'Erro inesperado ao atualizar a loja' };
        } catch (e) {
            return this._exception(e, 'Erro ao atualizar a loja');
        }
    }
    static async inactiveSellerElasticAndProducts(uuid) {
      try {
            const response = await BaseAPI.post(`${URLS.MERCHANT}/inactive/${uuid}`);
            if (response.status === 200) return response;
        } catch (e) {
            return this._exception(e, 'Erro ao inativar seller');
        }
    }
    static async activeSellerElasticAndProducts(uuid) {
      try {
            const response = await BaseAPI.post(`${URLS.MERCHANT}/active/${uuid}`);
            if (response.status === 200) return response;
        } catch (e) {
            return this._exception(e, 'Erro ao ativar seller');
        }
    }

}
export default MerchantAPI;
