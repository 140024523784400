import {extendObservable} from 'mobx';

import ToastHelper, {STATUS_HELPER} from '~/helpers/ToastHelper';
import ImpostosEstaduaisAPI from '../services/ImpostosEstaduaisAPI';
import ImpostoEstaduaisModel from '~/models/ImpostosEstaduaisModel';


const initValues = {
  loading: false,
  impostos: [],
  imposto: '',
}

class ImpostoEstaduaisStore {
  
  loadingSearchProduct = false;
  loading = false;
  status = false;

  constructor(rootStore){
    this.rootStore = rootStore;
    extendObservable(this, { ...initValues });
    this.toastHelper = new ToastHelper();
    this.imposto = new ImpostoEstaduaisModel();
    this.impostos = []
  }

  reset(){
    this.regime = new ImpostoEstaduaisModel();
    this.sorte = 'name';
    this.impostos = [];
    this.imposto = '';
  }
  setFilterSearch(data){
    this.search = data;
  }

  async getAllImpostosEstaduais(){
    this.loading = true;

    const response = await ImpostosEstaduaisAPI.getAllImpostosEstaduais();
    if(!response.error){
      this.impostos = response.map(imposto => new ImpostoEstaduaisModel(imposto))
    }else{
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
  }
  async getImpostosEstaduaisByUuid(uuid){
    this.loading = true;
    const response = await ImpostosEstaduaisAPI.getImpostosEstaduaisByUuid(uuid);
    if(!response.error){
      this.impostos = new ImpostoEstaduaisModel(response)
    }else{
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
  }
  async putImpostosEstaduais(data){
    this.loading = true;
    try {
      const response = await ImpostosEstaduaisAPI.putImpostosEstaduais(data, data.uuid);
      if(!response.error){
        this.loading = false;
        this.toastHelper.notify(STATUS_HELPER.INFO, 'Imposto Estadual atualizado');
        return response
      } 
      return { error: response.error };
    } catch (error) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, 'Falha ao atualizar Imposto Estadual');
    }
  }
  async deleteImpostosEstaduais(uuid){
    this.loading = true;
    try {
      const response = await ImpostosEstaduaisAPI.deleteImpostosEstaduais(uuid);
      if(!response.error){
        this.loading = false
        this.toastHelper.notify(STATUS_HELPER.INFO, 'Imposto Estadual inativado');
        return response;
      }
      return { error: response.error };
    } catch (error) {
      this.loading = false
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'Falha ao inativar Imposto Estadual');
    }
  }
  async postImpostosEstaduais(data){
    this.loading = true;
    try {
      const response = await ImpostosEstaduaisAPI.postImpostosEstaduais(data);
      if(!response.error){
        this.toastHelper.notify(STATUS_HELPER.INFO, 'Imposto Estadual cadastrado');
        this.loading = false;
        return response
      } 
      return { error: response.error };
    } catch (error) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, 'Falha ao cadastrar Imposto Estadual');
    }
    this.loading = false;
  }


  async getListByType(inputValue){
    this.loading = true;
    
    const response = await ImpostosEstaduaisAPI.listByType(inputValue);

    if (!response.error) {
      this.impostos = response.map(
        (imposto) => new ImpostoEstaduaisModel(imposto)
      );
      this.loading = false;
      return this.impostos;
    }
    this.loading = false;
    this.toastHelper.notify(STATUS_HELPER.ERROR, 'Não foi encontrado  Imposto(s) Estadual(is)');
  }

}
export default ImpostoEstaduaisStore;