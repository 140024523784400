import {extendObservable} from 'mobx';

import ToastHelper, {STATUS_HELPER} from '~/helpers/ToastHelper';
import ReviewProductsAPI from '../services/ReviewProductsAPI';
import ReviewProductModel from '~/models/ReviewProductModel';


const initValues = {
  loading: false,
  reviews: [],
  size: 4,
  page: 0,
  totalPages: 0,
  tipo: '',
}

class ReviewProductStore {
  
  loadingSearchProduct = false;
  loading = false;
  status = false;

  constructor(rootStore){
    this.rootStore = rootStore;
    extendObservable(this, { ...initValues });
    this.toastHelper = new ToastHelper();
    this.reviews = new ReviewProductModel();
  }

  reset(){
    this.reviews = new ReviewProductModel();
    this.sorte = 'name';
    this.nome = '';
    this.search = ''
    this.descricao = '';
  }
  setFilterSearch(data){
    this.search = data;
  }

  async getAllReviewProduct(boolean, category){
    this.loading = true;

    const response = await ReviewProductsAPI.getAllReviewProduct(boolean, category, this.page, this.size);
    if(!response.error){
      this.totalPages = response.totalPages;
      this.size = response.size;
      this.reviews = response.content[0].reviews.map(tipo => new ReviewProductModel(tipo))
    }else{
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
  }

  async updateReview(uuidReview, boolean, category){
    this.loading = true;
    const response = await ReviewProductsAPI.updateReview(uuidReview, boolean, category);
    if(!response.error) {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Atualizado com sucesso");
      this.loading = false;
      return response;
    }else{
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
  }

  async setPage(value, numPage, size = 4) {
    this.page = numPage;
    this.size = size;
    await this.getAllReviewProduct(value, numPage, size)
  }

}
export default ReviewProductStore;