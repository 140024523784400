class PageModel {
  constructor(data = {}) {
    this._sort = data.sort || 'name';
    this._totalPages = data.totalPages || 0;
    this._page = data.page || 0;
    this._size = data.size || 20;
    this._defaults = { ...data };
  }

  /**Seta valores padrão */
  reset() {
    this._page = this._defaults.page || 0;
    this._size = this._defaults.size || 20;
    this._sort = this._defaults.sort || 'name';
    this._totalPages = this._defaults.totalPages || 0;
  }

  get params() {
    return { sort: this.sort, page: this.page, size: this.size };
  }

  get sort() {
    return this._sort;
  }

  get totalPages() {
    return this._totalPages;
  }

  get page() {
    return this._page;
  }

  get size() {
    return this._size;
  }

  set sort(value) {
    this._sort = value;
  }

  set totalPages(value) {
    this._totalPages = value;
  }

  set page(value) {
    this._page = value;
  }

  set size(value) {
    this._size = value;
  }
}

export default PageModel;
