import OrderStatusModel from './SportbayOrderStatusModel';
import { dateToText } from '~/helpers/utils/Functions';
import BaseModel from '../BaseModel';

class SportbayStatusChangesModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.created = data.created;
    this.newStatus = new OrderStatusModel(data.newStatus);
    this.previousStatus = new OrderStatusModel(data.previousStatus);
  }
}

export default SportbayStatusChangesModel;
