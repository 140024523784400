import BaseModel from './BaseModel';

class ReviewProductModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.uuid = data.uuid;
    this.created = data.created;
    this.modified = data.modified;
    this.active = data.active;
    this.deleted = data.deleted;
    this.rating = data.rating;
    this.title = data.title;
    this.description = data.description;
    this.skuFilho = data.skuFilho;
    this.approved = data.approved;
    this.images = data.images;
  }
}

export default ReviewProductModel;
