import BaseModel from './BaseModel';

/**
 * Variações (Ex: Azul, vermelhor, amarelo)
 */
class ItemsCombo extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.quantity = data.quantity;
    this.product = data.product;
  }
}
export default ItemsCombo;
