import {extendObservable} from 'mobx';

import ToastHelper, {STATUS_HELPER} from '~/helpers/ToastHelper';
import TipoProdutoAPI from '../services/TipoProdutoAPI';
import TipoProdutoModel from '~/models/TipoProdutoModel';


const initValues = {
  loading: false,
  tipos: [],
  tipo: '',
}

class TipoProdutoStore {
  
  loadingSearchProduct = false;
  loading = false;
  status = false;

  constructor(rootStore){
    this.rootStore = rootStore;
    extendObservable(this, { ...initValues });
    this.toastHelper = new ToastHelper();
    this.tipo = new TipoProdutoModel();
  }

  reset(){
    this.tipo = new TipoProdutoModel();
    this.sorte = 'name';
    this.nome = '';
    this.search = ''
    this.descricao = '';
  }
  setFilterSearch(data){
    this.search = data;
  }

  async getAllTipoProduto(){
    this.loading = true;

    const response = await TipoProdutoAPI.getAllTipoProduto();
    if(!response.error){
      this.tipos = response.map(tipo => new TipoProdutoModel(tipo))
    }else{
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
  }
  async getTipoProdutoByUuid(uuid){
    this.loading = true;
    const response = await TipoProdutoAPI.getTipoProdutoByUuid(uuid);
    if(!response.error){
      this.tipo = new TipoProdutoModel(response)
    }else{
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
  }
  async putTipoProduto(data){
    this.loading = true;
    try {
      const response = await TipoProdutoAPI.putTipoProduto(data, data.uuid);
      if(!response.error){
        this.loading = false;
        this.toastHelper.notify(STATUS_HELPER.INFO, 'Tipo Produto atualizado');
        return response
      } 
      return { error: response.error };
    } catch (error) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, 'Falha ao atualizar Tipo Produto');
    }
  }
  async deleteTipoProduto(uuid){
    this.loading = true;
    try {
      const response = await TipoProdutoAPI.deleteTipoProduto(uuid);
      if(!response.error){
        this.loading = false
        this.toastHelper.notify(STATUS_HELPER.INFO, 'Tipo Produto inativado');
        return response;
      }
      return { error: response.error };
    } catch (error) {
      this.loading = false
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'Falha ao inativar Tipo Produto');
    }
  }
  async postTipoProduto(data){
    this.loading = true;
    try {
      const response = await TipoProdutoAPI.postTipoProduto(data);
      if(!response.error){
        this.toastHelper.notify(STATUS_HELPER.INFO, 'Tipo Produto cadastrado');
        this.loading = false;
        return response
      } 
      return { error: response.error };
    } catch (error) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, 'Falha ao cadastrar Tipo Produto');
    }
    this.loading = false;
  }


  async getListByType(inputValue){
    this.loading = true;
    
    const response = await TipoProdutoAPI.listByType(inputValue);

    if (!response.error) {
      this.tipos = response.map(
        (tipo) => new TipoProdutoModel(tipo)
      );
      this.loading = false;
      return this.tipos;
    }
    this.loading = false;
    this.toastHelper.notify(STATUS_HELPER.ERROR, 'Não foi encontrado  Tipo(s) Produto(s)');
  }

}
export default TipoProdutoStore;