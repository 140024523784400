import {extendObservable} from 'mobx';

import ToastHelper, {STATUS_HELPER} from '~/helpers/ToastHelper';
import RegimeTributarioAPI from '../services/RegimeTributarioAPI';
import RegimeTributarioModel from '~/models/RegimeTributarioModel';


const initValues = {
  loading: false,
  regimes: [],
  regime: '',
  uuid: ''
}

class RegimeTributario {
  
  loadingSearchProduct = false;
  loading = false;
  status = false;

  constructor(rootStore){
    this.rootStore = rootStore;
    extendObservable(this, { ...initValues });
    this.toastHelper = new ToastHelper();
    this.regime = new RegimeTributarioModel();
  }

  reset(){
    this.regime = new RegimeTributarioModel();
    this.sorte = 'name';
    this.search = ''
  }

  async getAllRegimesTributarios(){
    this.loading = true;

    const response = await RegimeTributarioAPI.getAllRegimeTributario();
    if(!response.error){
      this.regimes = response.map(regime => new RegimeTributarioModel(regime))
    }else{
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
  }
  async getRegimeTributarioByUuid(uuid){
    this.loading = true;
    const response = await RegimeTributarioAPI.getRegimeTributarioByUuid(uuid);
    if(!response.error){
      this.regime = new RegimeTributarioModel(response)
    }else{
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
  }
  async putRegimeTributario(data){
    this.loading = true;
    try {
      const response = await RegimeTributarioAPI.putRegimeTributario(data, data.uuid);
      if(!response.error){
        this.loading = false;
        this.toastHelper.notify(STATUS_HELPER.INFO, 'Regime Tributario atualizado');
        return response
      } 
      return { error: response.error };
    } catch (error) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, 'Falha ao atualizar Regime Tributario');
    }
  }
  async deleteRegimeTributario(uuid){
    this.loading = true;
    try {
      const response = await RegimeTributarioAPI.deleteRegimeTributario(uuid);
      if(!response.error){
        this.toastHelper.notify(STATUS_HELPER.INFO, 'Regime Tributario inativado');
        return response;
      }
      return { error: response.error };
    } catch (error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'Falha ao inativar Regime Tributario');
    }
  }
  async postRegimeTributario(data){
    this.loading = true;
    try {
      const response = await RegimeTributarioAPI.postRegimeTributario(data);
      if(!response.error){
        this.toastHelper.notify(STATUS_HELPER.INFO, 'Regime Tributario cadastrado');
        this.loading = false;
        return response
      } 
      return { error: response.error };
    } catch (error) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, 'Falha ao cadastrar Regime Tributario');
    }
    this.loading = false;
  }
  async getListByType(input){
    this.loading = true;
    
    const response = await RegimeTributarioAPI.listByType(input);

    if (!response.error) {
      this.regimes = response.map(
        (regime) => new RegimeTributarioModel(regime)
      );
      this.loading = false;
      return this.regimes;
    }
    this.loading = false;
    this.toastHelper.notify(STATUS_HELPER.ERROR, 'Não foi encontrado  Regime(s) Tributario(s)');
  }
}
export default RegimeTributario;