import BaseModel from "./BaseModel";
import FileModel from "./FileModel";

class ContentDataModel {
    constructor(data = []) {
      this.description = data.description;
      this.ordering = data.ordering;
      this.link = data.link;
      this.images = data.images ? data.images.map((image) => new FileModel(image)) : [];
      this.contentFiles = data.contentFiles ? data.contentFiles : [];
      this.mobile = data.mobile;
    }
}

export default ContentDataModel;