import { extendObservable } from 'mobx';

import AdministratorAPI from '../services/AdministratorAPI';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';

import AccountModel from '~/models/sportbay/SportbayAccountModel';
import StorageUtil, { KEYS } from '~/helpers/utils/StorageUtil';

class UsersStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      _user: undefined, //Current user.
      loading: false,
    });
  }

  /** get Current user. */
  get user() {
    return this._user;
  }

  /**Set Current user */
  set user(value) {
    this._user = value;
  }

  /**Retorna merchanto do usuário */
  get userMerchant() {
    return this.user && this.user.merchant;
  }

  /**Verifica se a credencial do usuário é de superadmin */
  get isStaff() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === 'SUPERADMIN' || currentAuth === 'STOOM';
  }

  /**
    * Busca todas as informações de um usuario administrador.
    * @param  {string} userName
    */
  async get(userName) {
    this.loading = true;
    const response = await AdministratorAPI.getUserAdmin(userName);
    if (!response.error) {
      this.user = new AccountModel(response);
      await this.rootStore.administratorStore.getAdminLogin(this.user);
      await this.rootStore.menuStore.loadMenu();
      this.loading = false;
      return this.user;
    } this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }
}
export default UsersStore;
