import EuroModel from "./EuroModel";
import BaseModel from "../BaseModel";

class EuroApplicationModel extends BaseModel{
    constructor(data = {}){
        super(data);
        this.name = data.name;
        this.models = data.models ? data.models.map(model => new EuroModel(model)) : [];
    }

    get getNameSelect() {
        let modelsName = this.models.map(m => m.name)
        return `${this.name} - Modelos: ${modelsName}`
    }
}

export default EuroApplicationModel;