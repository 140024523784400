import React from 'react';

/** 
  * @Name: dropdownComponent
  * @Data: 2019
  * @Desc: O dropdownComponent é um botão e uma lista de sua escolha
  * @props:
  *       
  */

const DropdownComponent = (props) =>{

    // Recebe as propriedades
     const{title, alignRight, classButton="btn-primary", children} = props;

    return (
        <React.Fragment>
        <div className="dropdown">
          <button type="button" className={`btn ${classButton} dropdown-toggle`} data-toggle="dropdown">
            {title}
         </button>
          <div className={`dropdown-menu ${alignRight ? 'dropdown-menu-right' : ''}`}>
           {children}
          </div>
        </div>
        </React.Fragment>
    )
}

export default DropdownComponent;