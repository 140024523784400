import { action, decorate, extendObservable } from 'mobx';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import { cryptoPass, removeItemList } from '~/helpers/utils/Functions';
import { entityTypes } from '~/helpers/utils/Selects';
import CustomerModel from '~/models/CustomerModel';
import SportBayCustomerModel from '~/models/sportbay/SportBayCustomerModel';
import CustomerAPI from '../services/CustomerAPI';

class CustomerStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      customer: undefined,
      customers: [],
      loading: false,
      loadingDoc:false,
      loadingDocPhoto:false,
      totalPages: 0,
      size: 10,
      page: 0,
      sort: 'firstName',
      filter: {
        email: '',
        document: '',
        firstName: '',
      },
    });
  }

  reset() {
    this.customer = undefined;
    this.customers = [];
    this.totalPages = 0;
    this.size = 10;
    this.page = 0;
    this.sort = 'firstName';
  }

  /**Mensagem genérica. */
  showMessage(message,err) {
    if(err){
      this.toastHelper.notify(STATUS_HELPER.ERROR, message);
    }else
    this.toastHelper.notify(STATUS_HELPER.INFO, message);
  }

  /**Atualiza uma propriedade do novo adminstrator*/
  async updateCustomerProps(prop, value) {
    const customer = this.customer
      ? this.customer
      : new SportBayCustomerModel({ entity: entityTypes[0].value });
    switch (prop) {
      case 'username':
      case 'email':
        customer.user[prop] = value;
        break;
      case 'entity':
        const entity = customer.entity === entityTypes[0].value
          ? entityTypes[1].value
          : entityTypes[0].value;
        customer.entity = entity;
        break;
      case 'pass':
        customer.user.password = await cryptoPass(value);
        break;
      case 'exempt':
        (value == 'on') ? customer.user.exempt = true : customer.user.exempt = false;
      case 'blocked':
        customer.blocked = value;
        break;
      case 'score':
        customer.score = value;
        break;
        default:
        customer[prop] = value;
    }
    this.customer = new SportBayCustomerModel(customer);
  }


  /**Cria um novo usuário (cliente) */
  async create() {
    this.loading = true;

    // Insere um customer type default para todo usuário cadastrado no sistema.
    this.customer.customerType = this.rootStore.customerTypeStore.getDefaultCustomerType();
    this.customer.user.username = this.customer.user.email;
    const data = JSON.stringify(this.customer);
    const response = await CustomerAPI.createUser(data);

    if (!response.error) {
      this.customer = new SportBayCustomerModel({ entity: entityTypes[0].value });
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Usuário criado com sucesso.'
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  /**Atualiza informações do cliente */
  async update() {
    this.loading = true;
    const customer = { ...this.customer };
    delete customer.uuid;
    customer['@class'] = 'br.com.stoom.sportbay.model.dto.request.SportbayCustomerDTO';

    const data = JSON.stringify(customer);
    const response = await CustomerAPI.update(this.customer.uuid, data);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Informações atualizadas com sucesso.'
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 12, isPending = false, sort) {
    this.page = numPage;
    this.size = size;
    sort && (this.sort = sort);
    !isPending ? await this.getList() : await this.getPending();
    return true;
  }

  /**Busca lista de clientes */
  async getList(filter) {
    this.loading = true;
    if(filter) this.filter = filter;
    let params = { size: this.size, page: this.page, sort: this.sort};
    let search = "";
    Object.keys(this.filter).map((p) => {
      if(this.filter[p]) {
        if(p === 'addresses_zipCode')
          search += `${p}:${this.filter[p].match( /\d+/g ).join('')},`
        else if ( p === 'addresses_block')
          search += `${p};*${this.filter[p]}*,`
        else
          search += `${p};${this.filter[p]},`
      }
    });
    params = { ...params, search }
    const response = await CustomerAPI.getList(params);
    this.loading = false;
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return [];
    }
    this.customers = response.content.map(
      (usr) => new SportBayCustomerModel(usr)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  /**Busca lista de clientes */
  async getPending() {
    this.loading = true;
    const params = { size: this.size, page: this.page };
    const response = await CustomerAPI.getPending(params);
    this.loading = false;
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return [];
    }
    this.customers = response.content.map(
      (usr) => new SportBayCustomerModel(usr)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  /**Ativa um usuário */
  async activeClient(customer) {
    if (this.loading) return;
    this.loading = true;
    const data = { uuid: customer.uuid };
    const response = await CustomerAPI.activeClient(data);
    if (response.error) this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    else {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Usuário foi ativado com sucesso!',
        3000
      );
    }
    this.getPending();
    this.loading = false;
  }

  /**
   * *Busca todas as informações de um usuario customer
   * @param  {} userName
   */
  async get(userName) {
    this.loading = true;
    const response = await CustomerAPI.get(userName);
    this.loading = false;
    if (!response.error) {
      this.customer = new SportBayCustomerModel(response);
      return this.customer;
    } this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Busca um usuário customer por um parametro. */
  async getByQuery(param, value) {
    this.loading = true;
    const response = await CustomerAPI.getBy(param, value);
    if (!response.error) {
      this.customer = new SportBayCustomerModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca um usuário customer por document do customer.
   * @param  {string} docValue
   */
  async getUserByDoc(docValue) {
    return await this.getByQuery('doc', docValue);
  }

  /**
   * Busca usuário customer por uuid
   * @param  {int} uuidValue
   */
  async getUserByUuid(uuidValue) {
    const customer = await this.getByQuery('uuid', uuidValue);
    this.loading = true;
    // await CustomerAPI.getUpdateErp(uuidValue);
    this.loading = false;
    return customer;
  }

  /**
   * Busca logs do costumer por uuid
   * @param {int} uuidCostumer
   */
  async getLogCustomer(uuidCostumer, page){

    const response = await CustomerAPI.getLogsByUuid(uuidCostumer, page)

    return response
  }
  async getDocumentPathName(pathName) {
    this.loadingDoc = true
    const response = await CustomerAPI.DocumentPathName(pathName);
    if(!response.error){
      this.loadingDoc = false
      return response
    }else{
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loadingDoc = false
      return response
    }
  }
  async getDocumentWithPhoto(pathName) {
    this.loadingDocPhoto = true
    const response = await CustomerAPI.DocumentWithPhoto( pathName);
    if(!response.error){
      this.loadingDocPhoto = false
      return response
    }else{
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loadingDocPhoto = false
      return response
    }
  }
    /**Delete */
    async delete(uuid) {
      this.loading = true;
      const response = await CustomerAPI.delete(uuid);
      this.loading = false;
      if (!response.error) {
        this.toastHelper.notify(
          STATUS_HELPER.INFO,
          'Usuário deletado com sucesso'
        );
        const customer = this.customers.find((c) => c.uuid === uuid);
        removeItemList(this.customers, customer);
      } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
  async updateEmail(uuid, email){
    this.loading = true;
    const response = await CustomerAPI.updateEmail(uuid, email)
    this.loading = false;
    if(!response.error){
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Email Atualizado com sucesso!');
      return response
    }else{
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
  }
}

export default CustomerStore;

decorate(CustomerStore, {
  create: action,
  update: action,
  get: action,
  getByQuery: action,
  getPending: action,
});
