import BaseModel from './BaseModel';
import FileModel from './FileModel';

/**Modelo imagem banner */
class BannerFileModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.name = data.name;
    this.ordering = data.ordering;
    this.link = data.link;
    this.subDescription = data.subDescription;
    this.description = data.description;
    this.path = data.path;
    this.file = new FileModel(data.file);
    this.dataFile = data.dataFile;
  }
}

export default BannerFileModel;
