import BaseModel from '../BaseModel';

class SportBayHomeStoreModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.components = data.components
    && data.components.map((com) => new Component(com));
    this.componentsMobile = data.components
    && data.components.map((com) => new Component(com));
  }
}

export class Component extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.name = data.name;
    this.ordering = data.ordering;
    this.type = data.type;
    this.application = data.application;
    this.categoryUuid = data.categoryUuid;
    this.categoryName = data.categoryName;
    this.bannerUuid = data.bannerUuid;
    this.bannerName = data.bannerName;
    this.products = data.products;
  }
}

export default SportBayHomeStoreModel;
