import {extendObservable} from 'mobx';

import ToastHelper, {STATUS_HELPER} from '~/helpers/ToastHelper';
import CategoryAPI from '../services/CategoryAPI';
import BannerAPI from '../services/BannerAPI';
import ProductAPI from '../services/ProductAPI';
import HomeStoreAPI from '../services/HomeStoreAPI';
import {Component} from '~/models/sportbay/SportbayHomeStoreModel';

const initValues = {
  loading: false,
  loadingBanner: false,
  loadingSave: false,
  loadingProduct: false,
  loadingCacheHome:false,
  loadingResetCacheHome:false,
  loadingCacheNewHome:false,
  loadingResetCacheNewHome:false,
  seo: null,
  components: [],
  componentsMobile: [],
  component: new Component(),
  optionsCollection: [],
  optionsBanner: [],
  optionsProduct: [],
  product: '',
  products: []
};

class HomeStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {...initValues});
  }

  async getAllComponents() {
    this.loading = true;
    const response = await HomeStoreAPI.getAllComponents();

    if (!response.error) {
      this.components = response.components;
      this.componentsMobile = response.componentsMobile;
    } else {
      this.components = [];
      this.componentsMobile = [];
    }

    this.loading = false;
  }

  changeComponentValue(prop, value) {
    const {component} = this;

    component[prop] = value;

    this.component = new Component(component);
  }

  async getCollectionsOptions() {
    // traz todas as categorias
    this.loading = true;
    const response = await CategoryAPI.getTree();
    this.loading = false;
    if (!response.error) {
      const optionsCollection = [];
      response.map((c) => {
        if (c.type === 'COLECAO') {
          optionsCollection.push({label: c.categoryName, value: c.uuid});
        }
        return false;
      });
      this.optionsCollection = optionsCollection;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return [];
  }

  async getSeo() {
    this.loading = false;
    const response = await HomeStoreAPI.getHomeSeo();

    if (!response.error) {
      return response;
    }

    return false;
  }

  async getBannersOptions(name) {
    this.loadingBanner = true;
    const params = {
      name,
      status: 'active'
    };
    const response = await BannerAPI.list(params);

    if (!response.error) {
      const optionsBanner = [];
      if (response.content && response.content.length > 0) {
        response.content.map((b) => {
          optionsBanner.push({value: b.uuid, label: b.nameTag});
          return false;
        });
      }
      this.optionsBanner = optionsBanner;
      this.loadingBanner = false;
      return optionsBanner;
    }
    // this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loadingBanner = false;
    return [];
  }

  resetApplication() {
    const {component} = this;

    component.bannerUuid = '';
    component.categoryUuid = '';
    component.bannerName = '';
    component.categoryName = '';

    this.component = new Component(component);
    this.products = [];
    this.optionsProduct = [];
    this.product = '';
    this.optionsBanner = [];
  }

  handleAddComponent() {
    if (this.component.application === 'PRODUTO') {
      const {component, products} = this;

      component.products = products;

      this.component = new Component(component);
      this.products = [];
    }

    if (this.component.type === 'WEB') {
      const {components} = this;

      components.push(this.component);

      this.components = components;
    } else {
      const {componentsMobile} = this;

      componentsMobile.push(this.component);

      this.componentsMobile = componentsMobile;
    }
    this.component = new Component();
  }

  handleDeleteComponent(component) {
    if (component.type === 'WEB') {
      const componentsFilter = this.components.filter((e) => e.name !== component.name);
      this.components = componentsFilter;
    } else {
      const componentsFilter = this.componentsMobile.filter((e) => e.name !== component.name);
      this.componentsMobile = componentsFilter;
    }
  }

  handleDeleteProduct(index) {
    const products = this.products.filter((p, i) => i !== index);
    this.products = products;
  }

  async handleSaveComponents() {
    this.loadingSave = true;

    if (this.seo) {
      this.loading = true;
      await HomeStoreAPI.saveHomeSeo(this.seo);
      this.loading = false;
    }

    const data = {
      components: this.components,
      componentsMobile: this.componentsMobile
    };

    const response = await HomeStoreAPI.saveComponentsHome(data);

    if (!response.error) {
      this.components = response.components;
      this.componentsMobile = response.componentsMobile;
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Home atualizada com sucesso');
    }

    this.loadingSave = false;
    return false;
  }

  async getProductsOptions(name) {
    this.loadingProduct = true;
    const params = {
      search: `name;*${name}*`,
      status: 'active'
    };
    const response = await ProductAPI.getProductModelsWithParams(params);

    if (!response.error) {
      const optionsProduct = [];
      if (response.content && response.content.length > 0) {
        response.content.map((p) => {
          optionsProduct.push({value: p.uuid, label: p.name});
          return false;
        });
      }
      this.optionsProduct = optionsProduct;
      this.loadingProduct = false;
      return optionsProduct;
    }
    this.loadingProduct = false;
    return [];
  }

  onChangeProduct(product) {
    this.product = product;
  }

  handleAddProductArray() {
    const {products} = this;

    products.push(this.product);
    this.product = '';

    this.products = products;
  }

  async handleCreateCache(type) {
    if(type === 'new'){
      this.loadingCacheNewHome = true
    }else{
      this.loadingCacheHome = true
    }
    const response = await HomeStoreAPI.createCacheHome(type);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Home atualizada com sucesso');
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loadingCacheNewHome = false
    this.loadingCacheHome = false
  }

  async handleReseteCache(type) {
    if(type === 'new'){
      this.loadingResetCacheNewHome = true
    }else{
      this.loadingResetCacheHome = true
    }
    const response = await HomeStoreAPI.reseteCacheHome(type);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Home resetada com sucesso');
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);

    this.loadingResetCacheHome= false
    this.loadingResetCacheNewHome = false
  }

  reset() {
    this.component = new Component();
    this.optionsCollection = [];
  }
}

export default HomeStore;
