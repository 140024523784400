import BaseAPI, { URLS } from "./BaseAPI";

class InstitucionalAPI {

    /**Método util para retorno de erro */
    static _exception(e, message) {
        console.log(e);
        return { error: message }
    }

    /**
     * @param  {string} uuid - Uuid do Institucional à ser procurada
     */
    static async get(uuid) {
        try {
            const response = await BaseAPI.get(`${URLS.INSTITUTIONAL}/${uuid}`);
            if (response.status === 200) return response.data;
            const error = response.status === 404 ? "Institucional não encontrado" : "Erro inexperado ao buscar Institucional";
            return { error }
        } catch (e) {
            return this._exception(e, 'Erro ao buscar Institucional');
        }
    }


    /**
     * @param  {string} uuid - Uuid do Institucional à ser deletada
     */
    static async delete(uuid) {
        try {
            const response = await BaseAPI.delete(`${URLS.INSTITUTIONAL}/${uuid}`);
            if (response.status === 200) return response.data;
            const error = response.status === 404 ? "Institucional não encontrado" : "Erro inexperado ao buscar Institucional";
            return { error }
        } catch (e) {
            return this._exception(e, 'Erro ao buscar Institucional');
        }
    }

    /**
     * @param  {string} uuid - Uuid do Institucional à ser atualizada
     */
    static async update(uuid, data) {
        try {
            const response = await BaseAPI.put(`${URLS.INSTITUTIONAL}/${uuid}`, data);
            if (response.status === 200) return response.data;
            const error = response.status === 404 ? "Institucional não encontrado" : "Erro inexperado ao buscar Institucional";
            return { error }
        } catch (e) {
            return this._exception(e, 'Erro ao buscar Institucional');
        }
    }

    /**
     * @param  {object} data - dados do novo Institucional que será salvo
     */
    static async save(data) {
        try {
            const response = await BaseAPI.post(URLS.INSTITUTIONAL, data);
            if (response.status === 201) return response;
            return { error: 'Erro inexperado ao cadastrar Institucional' }
        } catch (e) {
            return this._exception(e, 'Falha ao cadastrar Institucional');
        }
    }

    /**Busca lista de Institucional */
    static async getList(params) {
        try {
            const response = await BaseAPI.get(URLS.INSTITUTIONAL, params);
            if (response.status === 200) return response.data;
            return { error: 'Erro inexperado ao buscar lista de Institucional' }
        } catch(e) {
            return this._exception(e, "Falha ao buscar lista de Institucional");
        }
    }

}
export default InstitucionalAPI;