import axios from 'axios';
import BaseAPI, { URLS, baseURL } from './BaseAPI';
import StorageUtil, { KEYS } from '~/helpers/utils/StorageUtil';


class ImpostosFederaisAPI {
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }
  static async getAllImpostosFederais(){
    try {
      const url = `${URLS.IMPOSTOFEDERAIS}`
      const response = await BaseAPI.get(url)
      if(response.status === 200) return response.data;
      return { error: 'Falha ao buscar Impostos Federais.'}
    } catch (error) {
      return this._exception(error, 'Falha ao buscar Impostos Federais.')
    }
  }
  static async getImpostosFederaisByUuid(uuid){
    try {
      const url = `${URLS.IMPOSTOFEDERAIS}/${uuid}`
      const response = await BaseAPI.get(url)
      if(response.status === 200) return response.data;
      return { error: 'Falha ao buscar Imposto Federal.'}
    } catch (error) {
      return this._exception(error, 'Falha ao buscar Imposto Federal.')
    }
  }
  static async postImpostosFederais(data){
    try {
      const url = `${URLS.IMPOSTOFEDERAIS}`
      const response = await BaseAPI.post(url, data);
      if(response.status === 200) return response.data;
      return {error: 'Falha ao cadastrar Imposto Federal'}
    } catch (error) {
      this._exception(error, 'Falha ao cadastrar Imposto Federal')
    }
  }
  static async putImpostosFederais(data, uuid){
    try {
      const url = `${URLS.IMPOSTOFEDERAIS}/${uuid}`
      const response = await BaseAPI.put(url, data);
      if(response.status === 200) return response.data;
      return {error: 'Falha ao editar Imposto Federal'}
    } catch (error) {
      this._exception(error, 'Falha ao editar Imposto Federal')
    }
  }
  static async deleteImpostosFederais(uuid){
    try {
      const url = `${URLS.IMPOSTOFEDERAIS}/${uuid}`
      const response = await BaseAPI.delete(url)
      if(response.status === 200) return response.data;
      return { error: 'Falha ao excluir Imposto Federal'}
    } catch (error) {
      this._exception(error, 'Falha ao excluir Imposto Federal')
    }
  }
    static async listByType(search) {
    try {
      const url = `${URLS.IMPOSTOFEDERAIS}/res?search=${search}`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar Imposto Federal'};
    } catch (e) {
      return this._exception(e, 'Falha ao listar Imposto Federal');
    }
  }

}

const getConfig = (uploadItem) => ({
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${StorageUtil.getItem(KEYS.TOKEN_KEY)}`,
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) =>
      (uploadItem.percentCompleted = parseInt(
        Math.floor((progressEvent.loaded * 100) / progressEvent.total)
      )),
  });

export default ImpostosFederaisAPI;