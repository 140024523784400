class SportbayProductComparativeModel {
  constructor(data = {}) {
    this.name = data.name;
    this.uuid = data.uuid;
    this.value = data.value;
    this.type = data.type;
  }
}

export default SportbayProductComparativeModel;
