import EuroSerieModel from "./EuroSerieModel";
//import FileModel from "../FileModel";
import BaseModel from "../BaseModel";

export default class EuroAutomakerModel extends BaseModel {
    constructor( data = {} ) {
        super(data);
        this.name = data.name;
        // this.brandLogo = data.brandLogo ?  new FileModel(data.brandLogo) : [];
        this.series = data.series ? data.series.map(serie => new EuroSerieModel(serie)) : [];
    }

    addModel = (serie) => {
        this.series.push(new EuroSerieModel(serie));

    }

}