import BaseModel from '../BaseModel';
import FileModel from '../FileModel';

class SportbayBrandModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.uuid = data.uuid;
    this.name = data.name;
    this.description = data.description;
    this.slug = data.slug;
    this.active = data.active || false;
    this.file = new FileModel(data.file);
  }
}

export default SportbayBrandModel;
