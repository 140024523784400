import UserModel from './UserModel';
import MerchantModel from './MerchantModel';
import BaseModel from './BaseModel';

class AdministratorMerchant extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    if (data.merchant !== undefined)
      this.merchant = new MerchantModel(data.merchant);
    else
      this.merchant = new MerchantModel(data);
  }
}

export default AdministratorMerchant;
