import BaseModel from './BaseModel';

export default class OrderStatusModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.statusName = data.statusName;
    this['@class'] = data['@class'];
  }

  /**Retorna description de acordo com o statusName(definido no back) */
  get description() {
    return Object.freeze({
      ANALYSIS: 'Em análise', // em analise
      CART: 'Orçamento liberado', // CARRINHO
      SHIPPING_ANALYSIS: 'Em análise (Frete)', //analise eurobreak 2
      STOCK_SEPARATION: 'Não foi possível enviar ao ERP',
      CHECKOUT: 'Orçamento com cálculo de frete liberado', //checkoout
      NEW: 'Novo', //NOVO
      PAID: 'Pago', //PAGO
      BILLED: 'Faturado', // FATURADO
      SEPARATED: 'Separado', // SEPARADO
      SENT: 'Enviado', //ENVIADO
      CANCELED: 'Cancelado', //CANCELADO
      EXTERNAL_ID: 'Número ERP', //Numero ERP
      ID_ORDER: 'Número do pedido', //Numero pedido
      ID_PRE_ORDER: 'Orçamento' //Orcamento
    })[this.statusName];
  }

  // Todo pedido que tiver no status acima do NEW
  get isNew() {
    switch (this.statusName) {
      case ORDER_STATUS.ANALYSIS: return false;
      case ORDER_STATUS.CART: return false;
      case ORDER_STATUS.SHIPPING_ANALYSIS: return false;
      case ORDER_STATUS.CHECKOUT: return false;
      case ORDER_STATUS.NEW: return true;
      case ORDER_STATUS.PAID: return true;
      case ORDER_STATUS.BILLED: return true;
      case ORDER_STATUS.SEPARATED: return true;
      case ORDER_STATUS.SENT: return true;
      case ORDER_STATUS.CANCELED: return true;
      case ORDER_STATUS.EXTERNAL_ID: return true;
      case ORDER_STATUS.ID_ORDER: return true;
      case ORDER_STATUS.ID_PRE_ORDER: return true;
      default:
        return false;
    }
  }
}

/**Status de pedidos */
export const ORDER_STATUS = Object.freeze({
  ANALYSIS: 'ANALYSIS', // em analise
  CART: 'CART', // CARRINHO
  SHIPPING_ANALYSIS: 'SHIPPING_ANALYSIS', //analise eurobreak 2
  CHECKOUT: 'CHECKOUT', //checkoout
  NEW: 'NEW', //NOVO
  PAID: 'PAID', //PAGO
  SEPARATED: 'SEPARATED', // SEPARADO
  BILLED: 'BILLED', // FATURADO
  SENT: 'SENT', //ENVIADO
  CANCELED: 'CANCELED', //CANCELADO
  EXTERNAL_ID: 'externalId', //Numero ERP
  ID_ORDER: 'idOrder', //Numero pedido
  ID_PRE_ORDER: 'ID_PRE_ORDER' //Orcamento
});
