/* eslint-disable class-methods-use-this */
import { extendObservable } from 'mobx';

import StorageUtil, { KEYS } from '~/helpers/utils/StorageUtil';
import ItemMenuModel from '~/models/ItemMenuModel';

// const ManagerPropertyContainer = lazy(() => import('../containers/ManagerPropertyContainer'));

class MenuStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, {
      menus: [],
      dashboard: new ItemMenuModel(this.dashboardData),
      itemMenu: undefined,
      subItemMenu: undefined,
    });
  }

  /** Carrega menu */
  loadMenu() {
    const showMenuStoom = this.rootStore.authStore.isStoomAdmin;
    let items = this.itemsMenu;
    //if (!showMenuStoom) { items = this.itemsMenu.filter((item) => item.title !== 'Stoom'); }

    items = this.removeMenuItensForSeller(items);

    this.menus = items.map((item) => new ItemMenuModel(item));
    if (StorageUtil.getItem(KEYS.PATH_MENU)) {
      this.setCurrentMenu(StorageUtil.getItem(KEYS.PATH_MENU));
    }
  }

  removeMenuItensForSeller(itemsMenu) {
    const { user } = this.rootStore?.usersStore;
    if(user && user.merchant && user.merchant.isSeller){
      itemsMenu = itemsMenu.filter((item) =>
        item.title !== 'Clientes' &&
        item.title !== 'Banners' &&
        item.title !== 'Categorias' &&
        item.title !== 'Kit' &&
        item.title !== 'Atributos' &&
        item.title !== 'Comparativos' &&
        item.title !== 'NewsLetter' &&
        item.title !== 'Merchants' &&
        item.title !== 'Home da Loja' &&
        item.title !== 'Administradores' &&
        item.title !== 'Logistica' &&
        item.title !== 'Relatórios' &&
        item.title !== 'Leilão' &&
        item.title !== 'Landing Page'
      );
      /** Filtro nas Sub-menus */
      itemsMenu = itemsMenu.map((item) => {
         if(item.title === 'Produtos')
          item.subItems = item.subItems.filter((subItem) =>
             subItem.title === 'Gerenciamento de Produtos');
         return item;
      });
    }
    return itemsMenu;
  }

  /** Altera o menu atual. */
  setCurrentMenu(path) {
    StorageUtil.setItem(KEYS.PATH_MENU, path);
    this.itemMenu = this.menus.find((item) => {
      const sub = item.subItems.find((subItem) => subItem.path === path);
      if (sub) {
        this.subItemMenu = sub;
        return item;
      }
      return null;
    });
  }

  /** Retorna o titulo do menu selecionado */
  get title() {
    if (!this.itemMenu) return this.dashboard.title;
    return this.itemMenu && this.itemMenu.title;
  }

  /** Retorna o subtitulo do submenu */
  get subtitle() {
    if (!this.subItemMenu) return this.dashboard.subtitle;
    return this.subItemMenu && this.subItemMenu.title;
  }

  /** Retorna se dashboard está visible */
  get dashboardVisible() {
    return !this.itemMenu;
  }

  /** Dados da dashboard */
  get dashboardData() {
    return {
      title: 'Painel de Controle',
      subtitle: 'Um painel completo para controlar seu site',
      icon: 'fas fa-clipboard-list',
      subItems: [],
    };
  }

  /** Items de menu */
  get itemsMenu() {
    return Object.values({
      administrators: {
        title: 'Administradores',
        subtitle: 'Área de administradores',
        icon: 'fas fa-user-shield',
        subItems: [
          {
            path: '/cadastrar-administrador',
            title: 'Cadastro de Administradores',
          },
          { path: '/administradores', title: 'Gerenciar Administradores' },
        ],
      },
      usuarios: {
        title: 'Clientes',
        icon: 'fas fa-users',
        subItems: [
          { path: '/cadastrar-clientes', title: 'Novo Cliente' },
          { path: '/clientes', title: 'Gerenciamento de Clientes' },
        ],
      },
      banners: {
        title: 'Banners',
        icon: 'fas fa-images',
        subItems: [
          { path: '/cadastrar-banner', title: 'Novo Banner' },
          { path: '/banners', title: 'Gerenciamento de Banners' },
        ],
      },
      homeStore: {
        title: 'Home da Loja',
        icon: 'fas fa-home',
        subItems: [
          { path: '/gerenciar-home-store', title: 'Gerenciar Home da Loja' },
        ],
      },
      categorias: {
        title: 'Categorias',
        icon: 'fas fa-th',
        subItems: [
          { path: '/cadastrar-categoria', title: 'Nova Categoria' },
          { path: '/categorias', title: 'Gerenciamento de Categorias' },
        ],
      },
      pedidos: {
        title: 'Pedidos',
        subtitle: '',
        icon: 'fas fa-clipboard-list',
        subItems: [
          { path: '/pedidos', title: 'Gerenciamento de pedidos' },
          { path: '/conciliacao', title: 'Conciliação de pedidos' },
        ],
      },
      produtos: {
        title: 'Produtos',
        icon: 'fab fa-product-hunt',
        subItems: [
          { path: '/cadastrar-produtos', title: 'Novo Produto' },
          { path: '/produtos', title: 'Gerenciamento de Produtos' },
          { path: '/cadastrar-marcas', title: 'Cadastrar Marca' },
          { path: '/marcas', title: 'Gerencimento de marcas' },
        ],
      },
      kits: {
        title: 'Kit',
        icon: 'fa fa-cubes',
        subItems: [
          { path: '/cadastrar-kit', title: 'Novo Kit' },
          { path: '/kits', title: 'Gerenciamento de Kits' },
        ],
      },
      leilao: {
        title: 'Leilão',
        icon: 'fa fa-cubes',
        subItems: [
          { path: '/cadastrar-leilao', title: 'Novo leilão' },
          { path: '/leilao', title: 'Gerenciamento de leilão' },
          { path: '/leilao/status', title: 'Status do leilão' },
        ],
      },
      propriedades: {
        title: 'Atributos',
        icon: 'fas fa-gift',
        subItems: [
          { path: '/cadastrar-propriedade', title: 'Novo Tipo de Atributo' },
          { path: '/propriedades', title: 'Gerenciamento de Atributos' },
        ],
      },
      comparativos: {
        title: 'Comparativos',
        icon: 'fas fa-exchange-alt',
        subItems: [
          { path: '/cadastrar-comparativo', title: 'Novo Comparativo' },
          { path: '/comparativos', title: 'Gerenciar Comparativos' },
          { path: '/gerenciar-associados', title: 'Atribuir Comparativos' },
        ],
      },
      cupons: {
        title: 'Cupons/Campanhas',
        icon: 'fas fa-tags',
        subItems: [
          { path: '/cadastrar-cupom', title: 'Novo Cupom/Campanha' },
          { path: '/cupons', title: 'Gerenciamento de Cupons/Campanhas' },
        ],
      },
      logistica: {
        title: 'Logistica',
        subtitle: '',
        icon: 'fas fa-truck',
        subItems: [
          {
            path: '/cadastrar-transportadora',
            title: 'Nova transportadora',
          },
          {
            path: '/transportadoras',
            title: 'Gerenciar transportadoras',
          },
          {
            path: '/cadastrar-faixa-frete',
            title: 'Nova faixa de Frete',
          },
          {
            path: '/faixas-frete',
            title: 'Gerenciar Faixas de Frete',
          },
          {
            path: '/frete-gratis',
            title: 'Genrenciar frete gratis',
          },
          {
            path: '/simular-frete',
            title: 'Simulação de frete',
          },
        ],
      },
      stoom: {
        title: 'Merchants',
        subtitle: 'Área Staff',
        icon: 'fas fa-hammer',
        subItems: [
          { path: '/cadastrar-merchant', title: 'Cadastro de Merchant' },
          { path: '/merchants', title: 'Gerenciar Merchants' },
          { path: '/cadatrar-trasferencia', title: 'Cadastro de transferência '},
        ],
      },
      newsLetter: {
        title: 'NewsLetter',
        icon: 'fas fa-envelope',
        subItems: [
          { path: '/newsletters', title: 'Gerenciar Newsletter' },
          { path: '/newsletter', title: 'Nova Newsletter' },
        ],
      },
      vendas: {
        title: 'Relatórios',
        subtitle: 'Um painel completo para controlar seu site',
        icon: 'fas fa-clone',
        subItems: [
          { path: '/vendas/general', title: 'Vendas geral' },
        ],
      },
      landing: {
        title: 'Landing Page',
        subtitle: 'Um painel completo para construção de landing page',
        icon: 'fas fa-table',
        subItems: [
          { path: '/landing/criar', title: 'Criar Landing Page' },
          { path: '/landing/lista', title: 'Lista de Landing Page' },
        ],
       
      },
      business: {
        title: "B2B",
        subtitle: "Painel dedicado para tratativas B2B",
        icon: 'fas fa-shopping-cart',
        subItems: [
          { path: '/business/regime-tributario', title: 'Regime Tributario'},
          { path: '/business/tipo-produto', title: 'Tipo Produto'},
          { path: '/business/imposto-estadual', title: 'Impostos Estaduais'},
          { path: '/business/imposto-federal', title: 'Impostos Federais'},
        ]
      },
      avaliações: {
        title: "Avaliações",
        subtitle: "Painel de aprovar avaliações",
        icon: "fas fa-star",
        subItems: [
          { path: '/review', title: 'Aprovações de avaliações' }
        ]
      }
    });
  }
}

export default MenuStore;
