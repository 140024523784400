import OrderModel from "../OrderModel";
import EuroOrderItemModel from "./EuroOrderItemModel";
import EuroRecomendedItemModel from "./EuroRecomendedItemModel";
import EuroShippingService from "./EuroShippingService";
import EuroShippingDetails from "./EuroShippingDetails";

class EuroOrderModel extends OrderModel{
    constructor(data = {}) {
        super(data);
        this['@class'] = 'br.com.stoom.eurobrake.model.EuroOrder';
        this.orderItems = data.orderItems && data.orderItems.map(item => new EuroOrderItemModel(item));
        this.recommendedItems = data.recommendedItems && data.recommendedItems.map(r => new EuroRecomendedItemModel(r));
        this.shippingDetails = data.shippingDetails && new EuroShippingDetails(data.shippingDetails);
        this.shippingService = data.shippingService && new EuroShippingService(data.shippingService);
        this.shippingOptions = data.shippingOptions && data.shippingOptions.map(so => new EuroShippingDetails(so));

        }

    get getAdmin(){
        return this.customer.administrator;
    }

}

export default EuroOrderModel;