import BannerModel from "../BannerModel";
import SportbayBannerFileModel from './SportbayBannerFileModel';
/**Modelo banner */
class SportbayBannerModel extends BannerModel {
    constructor(data = {}) {
      super(data);
      this.files = data.files
        ? data.files.map((file) => new SportbayBannerFileModel(file))
        : [];
    }
  }
  
  export default SportbayBannerModel;
  