import UserModel from './UserModel';
import MerchantModel from './MerchantModel';
import BaseModel from './BaseModel';
import AdministratorMerchant from "~/models/AdministratorMerchant";

class AccountModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.idAdministrator = data.idAdministrator;
    this.name = data.name;
    this.user = new UserModel(data.user);
    this.merchant = new MerchantModel(data.merchant);
    this.merchants = data?.merchants?.map((item) => new AdministratorMerchant(item));
    this.owner = data.owner;
    this.acceptedTerms = data.acceptedTerms;
  }
}

export default AccountModel;
