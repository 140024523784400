import React, {Component} from 'react';
import { BlipChat } from "blip-chat-widget";
import './ChatWidget.scss'

const handleBubble = () => {
    const bubbleText = document.getElementsByClassName("ChatDivContainer")
    bubbleText[0].classList.toggle("inative")
}

class ChatWidget extends Component {

    componentDidMount() {

    const customStyle = `
      #message-input {
        box-sizing: border-box;
        border: 1px solid #0CC8CC;
        border-radius: 6px;
        background: #252B39;
      }
      #message-input textarea {
        background: #252B39;
        font-size: 12px;
        color: white;
      }
    `;
        try {
            const blipClient = new BlipChat()
                .withAppKey('c3Vwb3J0ZXNwb3J0YmF5bWFya2V0cGxhY2U6MTRmNzY2ODctNTA4OS00MmQxLTkzM2EtY2MwNTNkNzAyNmQy')
                .withButton({"color":"#11a21e","icon":"https://blipmediastore.blob.core.windows.net/public-medias/Media_740a856b-f851-4584-aba7-ef3f557d0548"})
                .withCustomCommonUrl('https://sportbay.chat.blip.ai/')
                .withCustomStyle(customStyle)
                .withEventHandler(BlipChat.LEAVE_EVENT, function () {
                    handleBubble()
                })
                .withEventHandler(BlipChat.ENTER_EVENT, function () {
                    handleBubble()
                })
                .withTarget("ChatDivContainer")
                .build();
        } catch (error) {
            console.log("BLIPCHAT offline")
        }
    }


    render() {
        return (
            <div className="ChatDivContainer">
                {/*<div className='Animated Animated2' /> */}
            </div>
        );
    }
}

export default ChatWidget;