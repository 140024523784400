import { extendObservable, decorate, action } from "mobx";
import { v4 as uuidv4 } from 'uuid';

import KitAPI from '../services/KitAPI';
import ProductAPI from '../services/ProductAPI';
import UploadAPI from '../services/UploadAPI';

import { onlyUnique } from '~/helpers/utils/Functions';
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import SportbayKitModel from '~/models/sportbay/SportbayKitModel';
import SportbayProductModel from '~/models/sportbay/SportbayProductModel';
import SportbayFileModel from '~/models/sportbay/SportbayFileModel';
import SportbayCategoryModel from '~/models/sportbay/SportbayCategoryModel';

const initValues = {
  loading: false,
  kit: {},
  kits: [],
  optionsProducts: [], // Opções para o async select
  optionsProductsAlternative: [], // Opções para o async select
  productsResponse: [], // Produtos que vem da chamada api
  productsAlternative: [], // Produtos alternativos do produto do kit que estiver ativo
  product: undefined,
  productsUUID: [], //UUID dos produtos do kit que serão enviados para retornar o preço
}

class KitStore {
  totalPages = 0;
  page = 0;
  size = 10;
  status = false;
  loadingSearchProduct = false;
  loading = false;
  toogleActive = false;
  isVisibleProductSelected = false;
  categoriesRemove = [];
  categoriesAdd = [];
  collectionsRemove = [];
  collectionsAdd = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, { ...initValues });
    this.toastHelper = new ToastHelper();
    this.kit = new SportbayKitModel();
  }

  reset() {
    this.kit = new SportbayKitModel();
    this.totalPages = 0;
    this.page = 0;
    this.kits = [];
    this.size = 10;
    this.sort = 'name';
    this.term = '';
    this.isVisibleProductSelected = false;
    this.productsUUID = [];
  }

  handleChangeComponentProduct() {
    this.isVisibleProductSelected = !this.isVisibleProductSelected;
  }

  /**Busca todos os kit */
  async getList() {
    this.loading = true;

    let params = {}

    if (this.status === 'active' || this.status === 'inactive') {
      params = {
        page: this.page,
        size: this.size,
        status: this.status,
        term: this.term,
      }
    } else {
      params = {
        page: this.page,
        size: this.size,
        sort: this.sort,
        term: this.term,
      };
    }

    const response = await KitAPI.list(params);

    if (!response.error) {
      this.kits = await response.content.map(
        (kt) => new SportbayKitModel(kt)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async searchByName(inputValue, size = 10) {
    this.term = inputValue;

    if (!inputValue) {
      await this.getList();
      return this.getListSelect();
    }

    this.page = 0;
    this.size = size;
    this.sort = 'name';

    const params = {
      page: this.page,
      size: this.size,
      sort: this.sort,
      status: (this.status === 'active' || this.status === 'inactive') ? this.status : '',
      term: this.term,
    };
    this.loading = true;

    const response = await KitAPI.listByName(params);

    if (!response.error) {
      this.kits = await response.content.map(
        (kt) => new SportbayKitModel(kt)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async searchByStatus(selectedValue, size = 10) {
    this.page = 0;
    this.size = size;
    this.status = selectedValue;
    await this.getList();
    return this.getListSelect();
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = sort ? sort : 'created,desc';

    await this.getList();
  }

  /**Retorna lista para uso no select */
  getListSelect(kits = this.kits) {
    return kits.map((kt) => ({
      value: kt.uuid,
      label: `${kt.name}`,
    }));
  }

  /**Função que atribui o valor do campo no model */
  handleKitChange(prop, value) {
    const { kit } = this;

    if (prop === 'status') {
      this.toogleActive = value;
    }

    kit[prop] = value;

    this.kit = new SportbayKitModel(kit);
  }

  /**Adiciona imagem ao produto */
  handleKitImages(tag, file) {
    const kit = this.kit;

    const fileItem = kit.files.find((file) => file.metatag === tag);
    //Se fileItem for null é uma nova imagem.
    if (!fileItem) {
      const newFileItem = new SportbayFileModel({ file, ...file, metatag: tag });
      kit.files.push(newFileItem);
    }
    //FileItem existe, Atualiza valores do arquivo recebido
    else fileItem.updateFile(file);
  }

  /**Adiciona uma ordenação de desconto */
  handleAddDiscount(ordering, percentage) {
    const kit = this.kit;

    kit.itemsDiscount.push({ ordering, percentage });

    this.kit = new SportbayKitModel(kit);
  }

  /**Remove o desconto selecionado */
  async handleRemoveOrdering(ordering) {
    let kit = this.kit;
    let discountUuid = '';

    const itemsDiscount = kit.itemsDiscount.filter(
      (e) => {
        if (e.ordering === ordering && e.uuid) {
          discountUuid = e.uuid;
        }
        return e.ordering !== ordering
      }
    );

    if (discountUuid) {
      await KitAPI.handleRemoveDiscountKit(this.kit.uuid, discountUuid);
    }

    kit.itemsDiscount = itemsDiscount;

    this.kit = new SportbayKitModel(kit);

    this.toastHelper.notify(STATUS_HELPER.INFO, 'Desconto deletado com sucesso.');
  }

  /**Adiciona um produto alternativo */
  handleAddProductAlternative(productUuid, productName) {
    const productsAlternative = this.productsAlternative;

    productsAlternative.push({ productUuid, productName });

    this.productsAlternative = productsAlternative;
  }

  /**Remove o produto alternativo selecionado */
  async handleRemoveProductAlternative(product, uuidKit, productUuid) {
    if (uuidKit != undefined && product.uuid) {
      this.loading = true;
      const response = await KitAPI.handleRemoveProductAlternativeKit(productUuid, product.productUuid);

      if (!response.error) {
        const productsAlternative = this.productsAlternative.filter(
          (e) => e.productUuid !== product.productUuid
        );

        this.productsAlternative = productsAlternative;
        this.kit.products.map((e) => {
          if (e.uuid === productUuid) {
            e.productsAlternative = this.productsAlternative;
          }
        })
      } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);

      this.loading = false;
    } else {
      const productsAlternative = this.productsAlternative.filter(
        (e) => e.productUuid !== product.productUuid
      );

      this.productsAlternative = productsAlternative;
    }
  }

  resetProductKit() {
    this.optionsProducts = [];
    this.product = undefined;
    this.loadingSearchProduct = false;
    this.productsAlternative = [];
  }

  /**Reseta as opções quando a string é menor 3 caracteres */
  resetProductOption() {
    this.optionsProducts = [];
    this.loadingSearchProduct = false;
  }

  /**Retorna lista para uso no select */
  getListSelect(products = this.productsResponse) {
    return products.map((prd) => ({
      value: prd.uuid,
      label: prd.name,
    }));
  }

  /**Busca por produto ativo por nome ou sku*/
  async searchProductActiveByNameSKU(query) {
    this.loadingSearchProduct = true;

    const isNumber = parseFloat(query);

    let params = {};

    if (isNumber) {
      params = {
        search: `skuCode;*${query}*`
      };
    } else {
      params = {
        search: `name;*${query}*`
      };
    }

    /*const params = {
      search: `name;${query},skuCode;${query}`//skuCode;*${query}*`
    };*/

    let response = await ProductAPI.searchProductActiveByNameSKU(params);

    if (this.kit.products.length > 0 && !response.error && response.content.length > 0) {
      this.kit.products.map((p) => {
        response.content = response.content.filter((pr) => {
          return pr.uuid !== p.productUuid
        });

        if (p.productsAlternative.length > 0) {
          p.productsAlternative.map((pdt) => {
            response.content = response.content.filter((pr) => {
              return pr.uuid !== pdt.productUuid
            });
          })
        }
      });
    }

    if (!response.error) {
      this.productsResponse = await response.content.map(
        (prd) => new SportbayProductModel(prd)
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);

    this.loadingSearchProduct = false;

    return this.getListSelect();
  }

  onSaveProductInKit(product) {
    const products = this.kit.products;
    let productsUUID = this.productsUUID;
    products.push({ productUuid: product.value, productName: product.label, productsAlternative: this.productsAlternative })
    productsUUID.push(product.value);

    this.kit.products = products;
    this.productsUUID = productsUUID;

    this.calculateProductsPriceInKit();

    this.toastHelper.notify(STATUS_HELPER.INFO, 'Produto Adicionado com Sucesso no Kit');
  }

  // Atualiza o produto no kit
  onUpdateProductInKit(product) {
    this.kit.products.map((e) => {
      if (e.productUuid === product.value) {
        e.productsAlternative = this.productsAlternative;
      }
    })

    this.toastHelper.notify(STATUS_HELPER.INFO, 'Produto Atualizado com Sucesso no Kit');
  }

  /**Remove o produto selecionado do kit */
  async handleRemoveProductKit(product, uuidKit) {
    this.loading = true;
    if (uuidKit !== undefined) {
      await KitAPI.handleRemoveProductKit(uuidKit, product.productUuid);
    }

    const products = this.kit.products.filter(
      (e) => e.productUuid !== product.productUuid
    );

    const productsUUID = this.productsUUID.filter(
      (e) => e !== product.productUuid
    );

    this.productsUUID = productsUUID;
    this.kit.products = products;

    if (products.length === 0) {
      this.handleKitChange('status', false);
      if (uuidKit !== undefined) {
        await KitAPI.disableKit(uuidKit);
      }
    }

    this.calculateProductsPriceInKit();
    this.loading = false;
  }

  /**Seta os produtos alternativos no update do produto do kit */
  setCurrentProductsAlternative(productUuid) {
    let productsAlternative;

    this.kit.products.map((e) => {
      if (e.productUuid === productUuid) {
        productsAlternative = e.productsAlternative;
      }
    });

    this.productsAlternative = productsAlternative;
  }

  /**Busca apenas um determinado produto */
  async get(value, prop = 'uuid') {
    this.loadingSearchProduct = true;
    const response = await ProductAPI.get(prop, value);
    if (!response.error) {
      const product = new SportbayProductModel(response);
      const categories = await this.getCategories(product.uuid);
      product.categories = categories;
      this.product = new SportbayProductModel(product);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loadingSearchProduct = false;
    return this.product;
  }

  async getCategories(productUuid) {
    const response = await ProductAPI.getCategories(productUuid);
    let categories = [];
    if (!response.error)
      categories = response.map((cat) => new SportbayCategoryModel(cat));
    else this.toastHelper.notify(STATUS_HELPER.error, response.error);
    return categories;
  }

  /**Salva um novo kit */
  async save() {
    this.loading = true;
    // const files = this.kit.files;

    // this.kit.files = [];

    const data = JSON.stringify(this.kit);

    let response = await KitAPI.save(data);

    if (!response.error) {
      const kit = new SportbayProductModel(response.data);
      // this.sendNewFiles(files, kit.uuid);
      await this.handleCategories(kit.uuid);
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Kit Cadastrado');
    } else {
      // this.kit.files = files;
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'Não foi possível cadastrar o kit'
      );
    }
    this.loading = false;
    return response;
  }

  /**Atualiza Kit */
  async update() {
    this.loading = true;

    const categories = this.kit.categories;
    this.kit.categories = [];
    // const files = this.kit.files;
    // if (files.length > 0) this.sendFiles(this.kit.uuid, files);

    const data = JSON.stringify(this.kit);

    const response = await KitAPI.update(this.kit.uuid, data);

    if (!response.error) {
      await this.handleCategories(this.kit.uuid, categories);
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Alterações gravadas com sucesso.'
      );
    } else {
      this.kit.categories = categories;
      // this.kit.files = files;
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.loading = false;
    return response;
  }

  /**Envia arquivo da imagem do kit */
  async sendFiles(kitUuid, files) {
    const newFiles = files.filter((file) => !file.uuid);
    const updateds = files.filter(
      (file) => file.uuid && file.localModified && file.file
    );
    newFiles.length > 0 && (await this.sendNewFiles(newFiles, kitUuid));
    updateds.length > 0 && (await this.updateFiles(updateds));
  }

  async updateFiles(updatedFiles) {
    const promiseUpdate = updatedFiles.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      // await UploadAPI.updateFile(file);
    });
    const response = await this.handlePromises(
      promiseUpdate,
      'Falha atualizar arquivos.'
    );
    setTimeout(() => {
      updatedFiles.forEach((file) =>
        this.notificationStore.removeItemUpload(file)
      );
    }, 3300);
    return response;
  }

  /**Funcão útil para dar feedbeack sobre envio de arquivos. */
  async handlePromises(promises, message) {
    const response = await Promise.all(promises);
    const errors = response.filter((r) => r && r.error !== undefined);
    if (errors && errors.length > 0) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, message);
    }
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  // Envia nova imagem
  async sendNewFiles(newFiles, productUuid) {
    const promiseNew = newFiles.map(async (file) => {
      this.rootStore.notificationStore.addItemUpload(file);
      await UploadAPI.sendNewFilesKit(productUuid, file);
    });
    const response = await this.handlePromises(
      promiseNew,
      'Falha anexar arquivos.'
    );
    setTimeout(() => {
      newFiles.forEach((file) => this.rootStore.notificationStore.removeItemUpload(file));
    }, 3300);
    return response;
  }

  /**Busca apenas um determinado kit */
  async getKit(kitUuid) {
    this.loading = true;
    const response = await KitAPI.findByUUID(kitUuid);
    if (!response.error) {
      const kit = new SportbayKitModel(response);
      kit.categories = await this.getKitCategories(kit.uuid);
      this.kit = new SportbayKitModel(kit);
      const productsUUID = [];
      if (response.products.length > 0) {
        response.products.map((pdt) => {
          productsUUID.push(pdt.productUuid);
        })
      }
      this.productsUUID = productsUUID;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return this.kit;
  }

  handleChangeImageKit(path) {
    const kit = this.kit;
    const metaTags = [
      "card"
    ];

    let file = { path, metaTags, size: 1, name: uuidv4(), metatag: 'card' };

    file = new SportbayFileModel(file);

    if (kit.files && kit.files.length > 0) {
      kit.files = [file]
    } else if (kit.files && kit.files.length === 0) {
      kit.files.push(file);
    }

    this.kit = new SportbayKitModel(kit);
  }

  async calculateProductsPriceInKit() {
    this.loading = true;
    const kit = this.kit;
    let uuids = '';

    if (this.productsUUID.length > 0) {
      this.productsUUID.map((uuid, index) => {
        if (index === 0) {
          uuids += uuid;
        } else {
          uuids += `,${uuid}`
        }
      });

      const params = {
        uuids: uuids,
      };

      const response = await KitAPI.calculateProductsPriceInKit(params);

      if (!response.error) {
        kit.price = response.price;
        this.kit = new SportbayKitModel(kit);
      } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      kit.price = '';
      this.kit = new SportbayKitModel(kit);
    }

    this.loading = false;
    return this.kit;
  }

  /**Manipula lista de categorias selecionadas ou descelecionadas */
  setCategories(selecteds) {
    this.categoriesRemove = this.kit.categories.filter(
      (cat) => !selecteds.some((mCat) => mCat.uuid === cat.uuid)
    );
    this.categoriesAdd = selecteds.filter(
      (cat) => !this.kit.categories.some((mCat) => mCat.uuid === cat.uuid)
    );

    return [...this.kit.categories, ...this.categoriesAdd]
      .filter((f) => this.categoriesRemove.indexOf(f) < 0)
      .filter(onlyUnique);
  }

  /**Manipula lista de coleções selecionadas ou descelecionadas */
  setCollections(selecteds) {
    this.collectionsRemove = this.kit.collections.filter(
      (cat) => !selecteds.some((mCat) => mCat.uuid === cat.uuid)
    );
    this.collectionsAdd = selecteds.filter(
      (cat) => !this.kit.collections.some((mCat) => mCat.uuid === cat.uuid)
    );

    return [...this.kit.collections, ...this.collectionsAdd]
      .filter((f) => this.collectionsRemove.indexOf(f) < 0)
      .filter(onlyUnique);
  }

  /**Vincula kit em categorias. */
  async handleCategories(kitUuid, categories = [], collections) {
    if (this.categoriesAdd.length > 0 || this.collectionsAdd.length > 0) {
      const data = [];
      this.categoriesAdd.map((e) => {
        data.push({
          uuid: e.uuid,
          '@class': 'br.com.stoom.sportbay.model.SportbayCategory'
        })
      })

      this.collectionsAdd.map((e) => {
        data.push({
          uuid: e.uuid,
          '@class': 'br.com.stoom.sportbay.model.SportbayCategory'
        })
      })

      if (categories && categories.length > 0) {
        categories.map((c) => {
          data.push(c);
        })
      }

      if (collections && collections.length > 0) {
        collections.map((c) => {
          data.push(c);
        })
      }

      await KitAPI.addKitCategories(kitUuid, data);
    }
    if (this.categoriesRemove.length > 0 || this.collectionsRemove.length > 0) {
      let promises = [];

      promises = [
        ...promises,
        this.categoriesRemove.map(
          async (category) =>
            await KitAPI.removeKitsCategory(category.uuid, {
              uuid: kitUuid,
            })
        ),
        this.collectionsRemove.map(
          async (category) =>
            await KitAPI.removeKitsCategory(category.uuid, {
              uuid: kitUuid,
            })
        ),
      ];
      promises.length > 0 &&
        (await this.handlePromises(promises, 'Falha ao desvincular categorias'));
    }
  }

  /**Busca categorias do kit */
  async getKitCategories(kitUuid) {
    const response = await KitAPI.getCategories(kitUuid);
    let categories = [];
    if (!response.error)
      categories = response.map((cat) => new SportbayCategoryModel(cat));
    else this.toastHelper.notify(STATUS_HELPER.error, response.error);
    return categories;
  }
}

export default KitStore;
