import React from 'react';
import { Link } from 'react-router-dom';

/**
  * @Name: Logo Padrão
  * @Data: 2019
  * @Desc: Logo aplicado com a logo marca (normalmente usado horizontalmente)
  * @props:
  *
  */

const LogoComponent = (props) => {
  const { large, small, useReload = true } = props;

  const SectionLogo = () => (
    <section className={`LogoComponentSportBay ${large ? 'logo-large' : ''} ${small ? 'logo-small' : ''}`}>
      {/* <div className="brand"></div> */}
      <div className="brand-text" />
    </section>
  );

  //Caso reload true faz o redirect para o path padrao
  return useReload ? <Link to="/"> <SectionLogo />  </Link> : <SectionLogo />;
};

export default LogoComponent;
