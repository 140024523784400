import BaseModel from '../BaseModel';
import EuroOrderModel from './EuroOrderModel';
import LuminiProductModel from '../lumini/LuminiProductModel';

class EuroRecomendedItemModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this['@class'] = 'br.com.stoom.eurobrake.model.EuroRecommendedItem';
    this.product = data.product && new LuminiProductModel(data.product);
    this.order = data.order && new EuroOrderModel(data.order);
    this.observation = data.observation || '';
    this.unitPrice = data.unitPrice;
    this.amount = data.amount || 1;
  }
}

export default EuroRecomendedItemModel;
