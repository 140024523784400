import { convertPrice } from '~/helpers/utils/Functions';
import ProductModel from '../ProductModel';
import EuroApplicationModel from '../euro/EuroApplicationModel';
import LuminiProductPropertyCustomModel from './LuminiProductPropertyCustomModel';

class LuminiProductModel extends ProductModel {
  constructor(data = {}) {
    super(data);
    this['@class'] = 'br.com.stoom.lumini.model.LuminiProduct';
    this.part = data.part || false;
    this.parent = data.parent || false;
    this.models = data.models;
    this.applications = data.applications
      ? data.applications.map((a) => new EuroApplicationModel(a))
      : [];

    this.productVariations = data.productVariations
      ? data.productVariations.map((a) => new LuminiProductModel(a))
      : [];

    this.productPropertyCustom = data.productPropertyCustom
      ? data.productPropertyCustom.map(
          (a) => new LuminiProductPropertyCustomModel(a)
        )
      : [];

    this.model3DLink = data.model3DLink;
    this.dataKeyAR = data.dataKeyAR;
  }

  get price() {
    return this.prices.filter((p) => p.customerType.name === 'A')[0];
  }

  /** Preço formatado em string R$9999,999  */
  get priceNumber() {
    return this.prices.filter((p) => p.customerType.name === 'A')[0]
      ? this.prices.filter((p) => p.customerType.name === 'A')[0].price
      : 0;
  }

  /** Preço formatado em string R$9999,999  */
  get priceString() {
    if (this.priceNumber) return convertPrice(this.priceNumber);
    return '';
  }

  get hasFiles() {
    if (this.files && this.files.length > 0) {
      const hasFile = this.files.filter((f) => f.path)[0];

      if (hasFile) {
        return true;
      }

      return false;
    }

    return false;
  }
}

export default LuminiProductModel;
