import BaseModel from './BaseModel';

class ImpostosEstaduaisModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.uuid = data.uuid;
    this.created = data.created;
    this.modified = data.modified;
    this.active = data.active;
    this.deleted = data.deleted;
    this.estadoOrigem = data.estadoOrigem;
    this.estadoDestino = data.estadoDestino;
    this.aliquotaInterna = data.aliquotaInterna;
    this.aliquotaInterestadual = data.aliquotaInterestadual;
    this.reducaoBaseCalculo = data.reducaoBaseCalculo;
  }
}

export default ImpostosEstaduaisModel;
