import axios from 'axios';
import BaseAPI, { URLS, baseURL } from './BaseAPI';
import StorageUtil, { KEYS } from '~/helpers/utils/StorageUtil';


class ImpostosEstaduaisAPI {
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }
  static async getAllImpostosEstaduais(){
    try {
      const url = `${URLS.IMPOSTOESTADUAIS}`
      const response = await BaseAPI.get(url)
      if(response.status === 200) return response.data;
      return { error: 'Falha ao buscar Impostos Estaduais.'}
    } catch (error) {
      return this._exception(error, 'Falha ao buscar Imposto Estadual.')
    }
  }
  static async getImpostosEstaduaisByUuid(uuid){
    try {
      const url = `${URLS.IMPOSTOESTADUAIS}/${uuid}`
      const response = await BaseAPI.get(url)
      if(response.status === 200) return response.data;
      return { error: 'Falha ao buscar Imposto Estadual.'}
    } catch (error) {
      return this._exception(error, 'Falha ao buscar Imposto Estadual.')
    }
  }
  static async postImpostosEstaduais(data){
    try {
      const url = `${URLS.IMPOSTOESTADUAIS}`
      const response = await BaseAPI.post(url, data);
      if(response.status === 200) return response.data;
      return {error: 'Falha ao cadastrar Imposto Estadual'}
    } catch (error) {
      this._exception(error, 'Falha ao cadastrar Imposto Estadual')
    }
  }
  static async putImpostosEstaduais(data, uuid){
    try {
      const url = `${URLS.IMPOSTOESTADUAIS}/${uuid}`
      const response = await BaseAPI.put(url, data);
      if(response.status === 200) return response.data;
      return {error: 'Falha ao editar Imposto Estadual'}
    } catch (error) {
      this._exception(error, 'Falha ao editar Imposto Estadual')
    }
  }
  static async deleteImpostosEstaduais(uuid){
    try {
      const url = `${URLS.IMPOSTOESTADUAIS}/${uuid}`
      const response = await BaseAPI.delete(url)
      if(response.status === 200) return response.data;
      return { error: 'Falha ao excluir Imposto Estadual'}
    } catch (error) {
      this._exception(error, 'Falha ao excluir Imposto Estadual')
    }
  }
    static async listByType(search) {
    try {
      const url = `${URLS.IMPOSTOESTADUAIS}/res?search=${search}`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar Imposto Estadual' };
    } catch (e) {
      return this._exception(e, 'Falha ao listar Imposto Estadual');
    }
  }

}

const getConfig = (uploadItem) => ({
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${StorageUtil.getItem(KEYS.TOKEN_KEY)}`,
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) =>
      (uploadItem.percentCompleted = parseInt(
        Math.floor((progressEvent.loaded * 100) / progressEvent.total)
      )),
  });

export default ImpostosEstaduaisAPI;