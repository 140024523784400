import BaseAPI, { URLS } from './BaseAPI';
import { cryptoPass } from '~/helpers/utils/Functions';
import StorageUtil, { KEYS } from '~/helpers/utils/StorageUtil';

/**AuthRequests é a camada onde incluímos a lógica que conversa direto com o backend e devolve o dado para  store.. */
class AuthAPI {
  /**Autentica o usuário e grava o token no localstorage */
  static async login(username, password) {
    const finalPass = await cryptoPass(password);
    try {
      const response = await BaseAPI.post(URLS.LOGIN, { username, password: finalPass });
      StorageUtil.setItem(KEYS.USER_KEY, username);
      return response;
    } catch (e) {
      return { error: 'Não autenticado' };
    }
  }

  /**Logout - desloga o usuário no backend e remove do localstorage
     * OBS: Hoje não possuímos nenhum endpoint para invalidar o token. Portanto estamos só removendo do localStorage.
     * Se amanhã ou depois vier a possuir algo do lado do back a estrutura já está pronta e deverá ser trabalhado aqui.
     */
  static async logout() {
    StorageUtil.cleanAll();
    return true;
  }

  /**Faz requisição de redefinição de senha do email informado */
  static async redefinePass(email) {
    //TODO trocar pelo endpoint valido quando existir
    // BaseRequests.post(URLS.BASE_URL, { email });
  }
}

export default AuthAPI;
