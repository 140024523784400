import { extendObservable } from 'mobx';
import BaseModel from '../BaseModel';
import CategoryModel from '../CategoryModel';
import SportbayProductModel from './SportbayProductModel';

class SportbayCategoryDTOModel extends CategoryModel {
  constructor(data = {}) {
    super(data);
    this['@class'] = 'br.com.stoom.sportbay.model.dto.request.SportbayCategoryDTO';
    this.ordering = data.ordering;
    this.metaCanonical = data.metaCanonical;
    this.menuDepartmentDisplay = data.menuDepartmentDisplay;
    this.departmentMenuOrder = data.departmentMenuOrder;
    this.headerHighlightOrder = data.headerHighlightOrder;
    this.seo = data.seo;
    this.features = data.features
      ? data.features.map((prod) => new Feature(prod))
      : [];
  }
}

export default SportbayCategoryDTOModel;

class Feature extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.uuid = data.uuid;
    this.products = data.products
      && data.products.map((mProd) => new SportbayProductModel(mProd));

    extendObservable(this, {
      menuDisplay: data.menuDisplay !== undefined ? data.menuDisplay : false,
      homeDisplay: data.homeDisplay !== undefined ? data.homeDisplay : false,
      productDetailsDisplay:
        data.productDetailsDisplay !== undefined
          ? data.productDetailsDisplay
          : false,
    });
  }

  //Retorna um array com o arquivo do cliente, caso exista.
  get filesCategory() {
    return this.files.filter(
      (file) => file.path && file.metaTags.some((tag) => tag === 'category')
    );
  }

  get fileCategory() {
    return this.filesCategory[0];
  }

  get iconCategory() {
    return this.files.find(
      (file) =>
        file.path && file.metaTags.some((tag) => tag === 'icon-category')
    );
  }

  get iconCategoryMobile() {
    return this.files.find(
      (file) =>
        file.path && file.metaTags.some((tag) => tag === 'icon-category-mobile')
    );
  }

  get createdIn() {
    return this._created;
  }

  get modifiedIn() {
    return this._modified;
  }
}
