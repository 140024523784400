import FileModel from '../FileModel';
import EuroOrderItem from '../euro/EuroOrderItemModel';
import BaseModel from '../BaseModel';

class PreOrderItemModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.name = data.name;
    this.amount = data.amount;
    this.observation = data.observation;
    this.orderItems =
      data.orderItems && data.orderItems.map((item) => new EuroOrderItem(item));
    this.files = data.files
      ? data.files.map((file) => new FileModel(file))
      : [];
  }

  /**Set file */
  setFile(file) {
    this.files = [new FileModel({ file, ...file, metaTags: ['preOrder'] })];
  }

  get file() {
    return this.files.length && this.files.length > 0 ? this.files[0] : '';
  }
}
export default PreOrderItemModel;
