import BaseModel from '../BaseModel';

class SportbayAlternativeProductModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.productUuid = data.productUuid;
    this.productName = data.productName;
  }
}

export default SportbayAlternativeProductModel;
