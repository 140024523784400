import {extendObservable} from 'mobx';

import ToastHelper, {STATUS_HELPER} from '~/helpers/ToastHelper';
import ImpostosFederaisAPI from '../services/ImpostosFederaisAPI';
import ImpostosFederaisModel from '~/models/ImpostosFederaisModel';


const initValues = {
  loading: false,
  impostos: [],
  imposto: '',
}

class ImpostosFederaisStore {
  
  loadingSearchProduct = false;
  loading = false;
  status = false;

  constructor(rootStore){
    this.rootStore = rootStore;
    extendObservable(this, { ...initValues });
    this.toastHelper = new ToastHelper();
    this.imposto = new ImpostosFederaisModel();
    this.impostos = []
  }

  reset(){
    this.regime = new ImpostosFederaisModel();
    this.sorte = 'name';
    this.impostos = [];
    this.imposto = '';
  }
  setFilterSearch(data){
    this.search = data;
  }

  async getAllImpostosFederais(){
    this.loading = true;

    const response = await ImpostosFederaisAPI.getAllImpostosFederais();
    if(!response.error){
      this.impostos = response.map(imposto => new ImpostosFederaisModel(imposto))
    }else{
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
  }
  async getImpostosFederaisByUuid(uuid){
    this.loading = true;
    const response = await ImpostosFederaisAPI.getImpostosFederaisByUuid(uuid);
    if(!response.error){
      this.impostos = new ImpostosFederaisModel(response)
    }else{
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
  }
  async putImpostosFederais(data){
    this.loading = true;
    try {
      const response = await ImpostosFederaisAPI.putImpostosFederais(data, data.uuid);
      if(!response.error){
        this.loading = false;
        this.toastHelper.notify(STATUS_HELPER.INFO, 'Imposto Federal atualizado');
        return response
      } 
      return { error: response.error };
    } catch (error) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, 'Falha ao atualizar Imposto Federal');
    }
  }
  async deleteImpostosFederais(uuid){
    this.loading = true;
    try {
      const response = await ImpostosFederaisAPI.deleteImpostosFederais(uuid);
      if(!response.error){
        this.loading = false
        this.toastHelper.notify(STATUS_HELPER.INFO, 'Imposto Federal inativado');
        return response;
      }
      return { error: response.error };
    } catch (error) {
      this.loading = false
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'Falha ao inativar Imposto Federal');
    }
  }
  async postImpostosFederais(data){
    this.loading = true;
    try {
      const response = await ImpostosFederaisAPI.postImpostosFederais(data);
      if(!response.error){
        this.toastHelper.notify(STATUS_HELPER.INFO, 'Imposto Federal cadastrado');
        this.loading = false;
        return response
      } 
      return { error: response.error };
    } catch (error) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, 'Falha ao cadastrar Imposto Federal');
    }
    this.loading = false;
  }


  async getListByType(inputValue){
    this.loading = true;
    
    const response = await ImpostosFederaisAPI.listByType(inputValue);

    if (!response.error) {
      this.impostos = response.map(
        (imposto) => new ImpostosFederaisModel(imposto)
      );
      this.loading = false;
      return this.impostos;
    }
    this.loading = false;
    this.toastHelper.notify(STATUS_HELPER.ERROR, 'Não foi encontrado  Imposto(s) Federal(is)');
  }

}
export default ImpostosFederaisStore;