import BaseModel from "../BaseModel";
import EuroSeriesModel from "./EuroSerieModel";
import EuroApplicationModel from "./EuroApplicationModel";

export default class EuroModel extends BaseModel {
    constructor( data = {} ) {
        super(data);
        this.name = data.name;
        this.startYear = data.startYear;
        this.endYear = data.endYear;
        this.bodyType = data.bodyType;
        this.application = data.application && new EuroApplicationModel(data.application);
        this.serie = data.serie && new EuroSeriesModel(data.serie) ;
    }

    get getNameSelect(){
        return `${this.name} - Serie: ${this.serie.name} - Montadora: ${this.serie.maker.name}`
    }
}
