import { extendObservable } from "mobx";
import EuroModel from "./EuroModel";
import BaseModel from "../BaseModel";
import EuroAutomakerModel from "./EuroAutomakerModel";

const initValues = {
    models: undefined
}
export default class EuroSeriesModel extends BaseModel {
    constructor( data = {} ) {
        super(data);
        extendObservable(this, { ...initValues })
        this.name = data.name;
        this.models = data.models ?data.models.map(model => new EuroModel(model)) : [];
        this.maker = data.maker && new EuroAutomakerModel(data.maker) ;
    }

    addModel = (model) => {
        this.models.push(new EuroModel(model));
    }

}